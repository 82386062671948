import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClientCommitmentsService } from 'src/app/services/client-commitments.service';
import { environment } from 'src/environments/environment';
import { CommitmentMapType } from '../enums/commitment-map-type.enum';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-unmap-fulfillment-modal',
  templateUrl: './unmap-fulfillment-modal.component.html',
  styleUrls: ['./unmap-fulfillment-modal.component.css']
})
export class UnmapFulfillmentModalComponent {

  public blueThemeMode = false;

  @Input()
  public pageTemplateResponseDetailsId = 0;

  @Input()
  public responseId = 0;

  @Input()
  public projectId = '';

  @Input()
  public commitmentMapType = CommitmentMapType.Opportunity;

  @Input()
  public projectName = '';

  @Input()
  public zIndexMode = false;

  @Output()
  private unmap = new EventEmitter<string>();

  constructor(
    private userService: UserService,
    private clientCommitmentsService: ClientCommitmentsService) {
    this.userService.blueThemeMode.subscribe(r => this.blueThemeMode = r);
  }

  public onUnmapClicked(): void {
    let unmapSubscription = null;
    if (this.commitmentMapType === CommitmentMapType.Project) {
      unmapSubscription = this.clientCommitmentsService.unmapProject(
        environment.clientCommitmentPageTemplateId,
        this.responseId,
        this.projectId,
        this.pageTemplateResponseDetailsId);
    } else {
      unmapSubscription = this.clientCommitmentsService.unmapOpportunity(
        environment.clientCommitmentPageTemplateId,
        this.responseId,
        this.projectId,
        this.pageTemplateResponseDetailsId);
    }

    if (unmapSubscription) {
      unmapSubscription.subscribe(() => {
        this.unmap.emit('');
      },
        error => {
          this.unmap.emit(error);
        });
    }
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  public get(name: string) {
    const cookies: Array<string> = document.cookie.split(';');
    const totalCookies: number = cookies.length;
    const cookieName = `${name}=`;
    let cookie: string;

    for (let i: number = 0; i < totalCookies; i += 1) {
        cookie = cookies[i].replace(/^\s+/g, '');
        if (cookie.indexOf(cookieName) == 0) {
            return cookie.substring(cookieName.length, cookie.length);
        }
    }
    return '';
  }

  public delete(name: string) {
    this.set(name, '', -1);
  }

  public set(name: string, value: string, expireTimes?: number, path: string = '') {
    let expires: string;
    if (expireTimes) {
        expires = `expires=${new Date(expireTimes)}`;
    }
    else {
        expires = ``;
    }
    
    const cookiePath: string = path ? `; path=${path}` : '';
    document.cookie = `${name}=${value}; ${expires}${cookiePath}`;
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router'; 
import { DocumentFileComponent } from './Components/document-file/document-file.component';
import { DocumentDownloadComponent } from './Components/document-download/document-download.component';

const routes: Routes = [
  {
    path: 'document-files/:guid',
    component: DocumentFileComponent,
    data: {
      title: 'Document'
    },
    canActivate: [true],
  },
  {
    path: 'document-folders/:folderId',
    component: DocumentFileComponent,
    data: {
      title: 'Document Folder'
    },
    canActivate: [true],
  },
  {
    path: 'documents/:guid',
    component: DocumentDownloadComponent,
    data: {
      title: 'Download Document'
    },
    canActivate: [true],
  },
  {
    path: 'folders/documents/:folderId',
    component: DocumentDownloadComponent,
    data: {
      title: 'Download Folder'
    },
    canActivate: [true],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DocumentsRoutingModule {}

import { Component, OnInit, OnDestroy, HostListener, Input, AfterViewInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import * as $ from 'jquery';
import { UserService } from 'src/app/services/user.service';
import { IUser } from 'src/app/shared/user';
import { Subscription } from "rxjs";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ["./footer.component.css"],
})
export class FooterComponent implements OnInit, AfterViewInit, OnDestroy {
  quickLinksCondition: boolean = false;
  clientCommitmentForm: boolean = false;
  dashboardId: number = 0;
  eCRMFeedId: number = 0;
  clarityFeedId: number = 0;
  clientCommitmentId: number = 0;
  callUserListSubscription: Subscription;
  @Input() isAnonymous: boolean = false;
  @Input() isCommitmentDashboardPage: boolean = false;
  @Input() loggedIn: boolean = false;
  isShowScrollToTop: boolean;
  topPosToStartShowing = 100;
  public blueThemeMode = false;

  constructor(
    private userService: UserService,
    private router: Router
  ) {
    this.userService.blueThemeMode.subscribe(r => this.blueThemeMode = r);
  }

  currentYear: number = new Date().getFullYear();
  dashboardDisclaimer: string = 'The information on this dashboard is Fiserv’s confidential information and is being provided to facilitate discussions and not to alter or create an agreement between the parties.';

  /****************Scroll section********************/
  private routerSubscription: Subscription;
  public myDocument = document;
  public myWindow = window;
  public scrollElement: HTMLElement | null = null;
  public height = 0;

  public scrollTop(): void {
    if (this.scrollElement.tagName === 'BODY') {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    } else {
      this.scrollElement.scrollTop = 0;
    }
  }

  public scrollBottom(): void {
    if (this.scrollElement.tagName === 'BODY') {
      window.scroll({
        top: this.scrollElement.scrollHeight,
        left: 0,
        behavior: 'smooth'
      });
    } else {
      this.scrollElement.scrollTop = this.scrollElement.scrollHeight - this.scrollElement.offsetHeight;
    }
  }

  public bodyStyle(): any {
    if (this.scrollElement.tagName === 'BODY') {
      this.height = document.getElementById('commitmentdisclaimer')?.offsetHeight + 52;
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        return `bottom: ${this.height + 20}px !important`;
      }
    } else {
      return '';
    }
  }

  ngAfterViewInit(): void {
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes('commitmentdashboard')) {
          this.scrollElement = document.getElementsByTagName('body')[0];

          window.screen.orientation.onchange = () => {
            this.height = document.getElementById('commitmentdisclaimer')?.clientHeight + 52;
          };
        } else {
          this.scrollElement = document.getElementById('containerPage');
        }
      }
    });
  }
  /**************************************************/

  ngOnInit(): void {
    /*
    if(environment.env === 'prod') {
      this.dashboardId = 7;
      this.eCRMFeedId = 21;
      this.clarityFeedId = 38;
      this.clientCommitmentId = 8;
    }
    else if(environment.env === 'app') {
      this.dashboardId = 34;
      this.eCRMFeedId = 237;
      this.clarityFeedId = 297;
      this.clientCommitmentId = 299;
    }    
    else if(environment.env === 'dev') {
      this.dashboardId = 1;
      this.eCRMFeedId = 10;
      this.clarityFeedId = 11;
      this.clientCommitmentId = 1;
    }
    */

    if (this.isAnonymous) {
      return;
    }

    if (!this.loggedIn) {
      return;
    }

    const user = this.userService.getUserListData();
    if (!user) {
      this.callUserListSubscription = this.userService.callUserListData().subscribe((data) => {
        this.setUpUserAndPermissions(data);
      });
      return;
    }
    this.verifyUserRole(user);
  }


  // @HostListener('window:scroll')
  // public checkScroll() {

  // window의 scroll top
  // Both window.pageYOffset and document.documentElement.scrollTop returns the same result in all the cases. window.pageYOffset is not supported below IE 9.

  //   const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

  //   console.log('[scroll]', scrollPosition);

  //   if (scrollPosition >= this.topPosToStartShowing) {
  //     this.isShowScrollToTop = true;
  //   } else {
  //     this.isShowScrollToTop = false;
  //   }
  // }

  // TODO: Cross browsing
  // public gotoTop() {

  //   window.scroll({
  //     top: 0,
  //     left: 0,
  //     behavior: 'smooth'
  //   });
  // }

  @HostListener('document:click', ['$event'])
  public onGlobalLoad(event: any): void {
    if ($("#quickLinkID").hasClass('d-block')) {
      $("#quickLinkID").removeClass('d-block');
      $('#quickLinksIcon').removeClass('expend');
      event.stopPropagation();
    }
  }
  public quickLinks($event) {
    if ($("#quickLinkID").hasClass('d-none')) {
      $('#quickLinksIcon').toggleClass('expend');
      $('#quickLinkID').toggleClass('d-block');
      $event.stopPropagation();
    }
  }

  navigateToPageResponse(id: number) {
    let url2: string = '/self-service/pageTemplateR/' + id + '/referesh';
    this.router.navigate([url2]);
  }

  navigateToDashboard() {
    let url: string = '/dashboard/report/' + this.dashboardId;
    this.router.navigate([url]);
  }

  private setUpUserAndPermissions(user: IUser): void {
    this.userService.setUserListData(user);
    this.verifyUserRole(user);
  }

  private verifyUserRole(user: IUser): void {
    if (
      user.isAdmin ||
      user.isRestrictedAdmin ||
      user.organizationTitle === 'GSO'
    ) {
      this.clientCommitmentForm = true;
    }
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
    this.callUserListSubscription.unsubscribe();
  }

  public dismissDisclaimer(): void {
    $('#commitmentdisclaimer').hide();
    $('#containerPage').removeClass('commitment-dashboard-container-page');
    $('#containerPage').addClass('container-page');
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router'; 
import { UserNotificationComponent } from './user-notification.component';
import { UserNotificationGuard } from './user-notification.guard';

const routes: Routes = [
  {
    path: 'user-notification',
    component: UserNotificationComponent,
    canActivate: [UserNotificationGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserNotificationRoutingModule {

}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClientCommitmentsService } from 'src/app/services/client-commitments.service';
import { PageTemplateService } from 'src/app/services/page-template.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-commitments-intake-delete-modal',
  templateUrl: './commitments-intake-delete-modal.component.html',
  styleUrls: ['./commitments-intake-delete-modal.component.css']
})
export class CommitmentsIntakeDeleteModalComponent {

  public blueThemeMode = false;

  @Input()
  public id = 0;

  @Output()
  private delete = new EventEmitter<void>();
  constructor(
    private userService: UserService,
    private pageTemplateService: PageTemplateService,
    private clientCommitmentsService: ClientCommitmentsService) {
    this.userService.blueThemeMode.subscribe(r => this.blueThemeMode = r);
  }

  public onDeleteClicked(): void {
    this.pageTemplateService.deletePageResponse(this.id)
      .subscribe(
        () => {
          this.delete.emit();          
        },
        error => {          
          this.delete.emit(error);
        }
      );
  }
}

<div class="help-landing-container self-service-container heading-container">
    <h1>{{content['selfServiceHeader']}}</h1>
</div>

<!--  !!!Important: Since we are leveraging HELP module keep the help classes as per the this HTML for the reusability  -->
<div class="help-landing-page self-service-landing-page main-container-sub responsive-container separate-form-container pt-4">
    <section id="self-service-home-options" class="right-section assign-container col-12 pl-2 pr-2" style="max-width: 100%;">
        <form  class="form-container ng-untouched ng-pristine ng-valid">
            <!-- Error Msg Alert -->
            <div class="alert alert-dismissible alert-danger" *ngIf="errorMsg">
                <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="errorMsg=''">×</button> 
                <i class="material-icons">warning</i>
                <p [innerHtml]="errorMsg"></p>
            </div>
             <!-- Information Msg Alert -->
             <div class="alert alert-dismissible alert-info" *ngIf="infoMsg">
                <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="infoMsg=''">×</button> 
                <i class="material-icons">info</i>
                <p [innerHtml]="infoMsg"></p>
            </div>
            <div class="landing-card-list-container">
             <!-- START Self Service Listing Starts   -->
                <ul class="landing-card-list">
                     <li class="list" *ngFor="let feature of selfServiceFeatureList" [ngClass]="feature.featureCount > 0 && feature.name !== 'Other Requests' ? '' : 'd-none'">
                        <div class="nav-link">
                            <h2>{{feature.name}}</h2>
                            <p>{{feature.description}}</p>
                            <ul class="topic">
                                <li *ngFor="let subfeature of feature.features" [ngClass]="subfeature.viewAccess && !(subfeature.isFooterLink) ? '' : 'd-none'">
                                    <a *ngIf="!(subfeature.isPageTemplate) && !(subfeature.isFooterLink) && subfeature.viewAccess" 
                                    [attr.href]="subfeature.selfServiceSubFeatureURL" 
                                    [attr.target]="subfeature.isExternalLink ? '_blank' : ''"
                                    routerLinkActive="active" >{{subfeature.name}}</a>
                                    <a *ngIf="subfeature.isPageTemplate && !(subfeature.isFooterLink) && subfeature.viewAccess" href="javascript:void(0);" routerLinkActive="active" routerLink="/self-service/pageTemplate/{{subfeature.pageTemplateId}}/responses">{{subfeature.pageTemplateTitle}}</a>
                                </li>
                            </ul>
                        </div>

                        <div class="d-flex" *ngFor="let subfeature of feature.features">
                            <a *ngIf="subfeature.isFooterLink && subfeature.viewAccess" 
                            routerLink="{{subfeature.selfServiceSubFeatureURL}}" 
                            routerLinkActive="active" class="view-all-link"> {{subfeature.name}}
                                <span class="material-icons">navigate_next </span> </a>
                        </div>
                    </li> 
                </ul>
                   <!-- ENDS Self Service Listing Starts   -->
            </div>
        </form>
    </section>
    <!-- STARTS Help Topic Content Area -->

    <section class="landing-page-articles-section">
        <div class="landing-page-article-header">
            <div class="help-wrapper">
                <h2>                
                {{content['selfServiceHelpTitle']}} &amp; {{content['selfServiceSupportTitle']}} <span class="material-icons" >help
                </span></h2>  
                <p *ngIf="!isCCT">{{content['selfServiceHelpMessage']}} <a href="mailto:drms-implementation360@fiserv.com">drms-implementation360@fiserv.com</a>
                </p>
                <p *ngIf="isCCT">{{content['raiseQuestionviaCCTMessage']}} <a href="mailto:commitmenttrackersupport@fiserv.com">commitmenttrackersupport@fiserv.com</a>
                </p> 
            </div>    
          <div *ngIf="!isCCT" id="self-service-help-topics" class="article-container">
            <article *ngFor="let row of popularTopic; let i = index;">
              <h3>{{row.helpCategoryName}}</h3>
              <ul class="article-list">
                <li *ngFor="let topics of row.helpTopics; let k = index;">
                  <a href="javascript:void(0);" routerLink="/help/topic/{{topics.id}}" routerLinkActive="active" class="description-image">{{topics.title}}</a>
                </li>
              </ul>
            </article>
          </div>
          <div *ngIf="isCCT" id="self-service-help-topics" class="help-wrapper">
              <ul class="article-list">
                <li>
                    <a href="javascript:void(0);" href="{{clientDashboardFAQs}}" target="_blank" class="description-image"> Client Commitment Tracker Dashboard - FAQ</a>
                </li>
                <li>
                    <a href="javascript:void(0);" href="{{cctUserManual}}" target="_blank" class="description-image"> Client Commitment Tracker Dashboard - User Manual</a>
                </li>
                <li>
                    <a *ngIf="!userData?.isClientUser" href="javascript:void(0);" href="{{cctUserGuide}}" target="_blank" class="description-image"> Client Commitment Tracker Internal User Guide</a>  
                </li>
              </ul>            
          </div>                           
        </div>
      </section>
     <!-- END Help Topic Content Area -->
</div>
import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { ClientCommitmentsService } from 'src/app/services/client-commitments.service';
import { PageTemplateResponseDetailsDTO } from '../models/page-template-response-details-d-t-o';
import { environment } from 'src/environments/environment';
import { AlertInput } from '../models/alert-input';
import { AlertType } from '../enums/alert-type.enum';
import { CommitmentMapType } from '../enums/commitment-map-type.enum';
import { PermissionsService } from 'src/app/services/permissions.service';
import { IPageTemplate } from 'src/app/shared/page-template';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-map-fulfilment',
  templateUrl: './map-fulfilment.component.html',
  styleUrls: ['./map-fulfilment.component.css']
})
export class MapFulfilmentComponent implements AfterViewInit {

  public calledForMap = true;
  public showProjectIdSection = true;
  public errorMsg = '';
  public selectedProjectId = '';
  public selectedResponseId = 0;
  public pageTemplateResponseDetailsDTO: PageTemplateResponseDetailsDTO | null = null;
  public displayUnmapButton = false;
  public displayMapButton = false;
  public blueThemeMode = false;

  @Input() public isReadOnlyMode: boolean;  
  @Input() public title = '';
  @Input() hasDefaultEcrmOpportunityId = true;
  @Input() projectDataSource = '';
  @Input()
  public set isCalledForMap(value: boolean) {
    this.calledForMap = value;
    if (!this.calledForMap) {
      this.showProjectIdSection = false;
    }
  };

  @Input()
  public set responseId(value: number) {
    this.selectedResponseId = value;
    if (value && !this.calledForMap) {
      this.loadMappedProjectDetails();
    }
  }

  @Input() canMapOrUnmap = false;
  @Input() pageTemplate: IPageTemplate;
  @Input() commitmentMapType = CommitmentMapType.Opportunity;

  @Input()
  public set projectId(value: string) {
    this.selectedProjectId = value;
    if (!value && this.calledForMap) {
      this.showProjectIdSection = true;
    }
    else {
      this.showProjectIdSection = false;
    }

    if (value && this.calledForMap) {
      this.loadProjectDetails();
    }
  }

  @Input() currentCommimentDataSource: string;

  // @Input() opportunityId: string;

  @Output()
  private submit = new EventEmitter<AlertInput>();

  @Output()
  private cancel = new EventEmitter<void>();

  constructor(
    private userService: UserService,
    private clientCommitmentsService: ClientCommitmentsService,
    private permission: PermissionsService) {
    this.userService.blueThemeMode.subscribe(r => this.blueThemeMode = r);
  }

  public loadProjectDetails(): void {
    this.errorMsg = '';
    this.clientCommitmentsService
      .getProjectDetailForMapping(environment.clientCommitmentPageTemplateId, this.selectedProjectId, this.projectDataSource)
      .subscribe(data => {
        this.pageTemplateResponseDetailsDTO = data as unknown as PageTemplateResponseDetailsDTO;
        this.checkForUnmapAbility();
        this.checkForMapAbility();
      },
        error => {
          this.errorMsg = error;
        });
  }

  public loadMappedProjectDetails(): void {
    this.errorMsg = '';
    this.clientCommitmentsService
      .getMappedProjectDetails(environment.clientCommitmentPageTemplateId, this.projectDataSource, this.selectedResponseId)
      .subscribe(data => {
        this.pageTemplateResponseDetailsDTO = data as unknown as PageTemplateResponseDetailsDTO;
        this.title = this.pageTemplateResponseDetailsDTO.title;
        if (this.projectDataSource.toLowerCase() === "clarity".toLowerCase()) {
          this.selectedProjectId = this.pageTemplateResponseDetailsDTO.projectDetails.projectNumber;
        }
        else if (this.projectDataSource.toLowerCase() === "ecrm".toLowerCase()) {
          this.selectedProjectId = this.pageTemplateResponseDetailsDTO.projectDetails.opportunityId;
        }

        this.checkForUnmapAbility();
        this.checkForMapAbility();
      },
        error => {
          this.errorMsg = error;
        });
  }

  public closeDrawer(): void {
    this.cancel.emit();
  }

  public onMappingSubmit(): void {
    this.errorMsg = '';
    if (!this.calledForMap) {
      return;
    }

    if (this.commitmentMapType === CommitmentMapType.Project) {
      this.clientCommitmentsService.mapProject(
        this.selectedResponseId,
        this.pageTemplateResponseDetailsDTO.id,
        this.selectedProjectId).subscribe(() => {
          const alertInput: AlertInput = {
            alertType: AlertType.Success,
            message: `Project "${this.selectedProjectId}" is mapped successfully.`,
          };
          this.submit.emit(alertInput);
        },
          error => {
            this.errorMsg = error;
          });

    } else {
      this.clientCommitmentsService.mapOpportunity(
        this.selectedResponseId,
        this.pageTemplateResponseDetailsDTO.id,
        this.selectedProjectId).subscribe(() => {
          const alertInput: AlertInput = {
            alertType: AlertType.Success,
            message: `Opportunity "${this.selectedProjectId}" is mapped successfully.`,
          };
          this.submit.emit(alertInput);
        },
          error => {
            this.errorMsg = error;
          });
    }
  }

  public unmap(errorMessage: string): void {
    if (errorMessage === '') {
      const alertInput: AlertInput = {
        alertType: AlertType.Success,
        message: `Fulfillment "${this.selectedProjectId}" is unmapped successfully.`,
      };
      this.submit.emit(alertInput);
    } else {
      this.errorMsg = errorMessage;
    }
  }

  public checkForMapAbility() {
    this.displayMapButton = this.calledForMap && this.canMapOrUnmap && !this.isReadOnlyMode;
  }

  //Scenario 1 -CCT admin with page template Read only access -Should see mapped project detail but unmap should be hidden 
  //Secenrio 2 - CCT admin with Page template admin access >> 
  //should view mapped project detail and unmap button if data source is not Clarity or Ecrm 
  //hide Unmap if datasoure is Ecrm/Clarity 
  //Scenrio 3 - RM role only - RM should have access of view mapped project detail and Unmap  Project id /Opportunity if data source is not Clarity or Ecrm 
  //If datasoure is Ecrm/Clarity than Unmap should be hidden
  //Scenario 4 - if datasource = 'servicenow' hide unmap button

  public checkForUnmapAbility() {

    if (this.permission.isCCTAdmin() && this.isPageTemplateReadAccess()) {
      this.displayUnmapButton = false;
    } else if (
      (this.permission.isCCTAdmin() || this.permission.isRelationShipManager) &&
      (
        (this.currentCommimentDataSource.toLowerCase() === 'clarity' && this.pageTemplateResponseDetailsDTO.dataSource.toLowerCase() === 'clarity')
        || (this.currentCommimentDataSource.toLowerCase() === 'ecrm' && this.pageTemplateResponseDetailsDTO.dataSource.toLowerCase() === 'ecrm')
      )
    ) {
      this.displayUnmapButton = false;
    } else if (this.currentCommimentDataSource.toLowerCase() === 'servicenow' || (this.currentCommimentDataSource.toLowerCase() === 'cims' && this.hasDefaultEcrmOpportunityId)) {
      this.displayUnmapButton = false;
    } else {
      this.displayUnmapButton = this.pageTemplateResponseDetailsDTO.canUnmap && !this.calledForMap && !this.isReadOnlyMode;
    }
  }

  private isPageTemplateReadAccess() {
    return this.pageTemplate.pageTemplateAdmins.find(item => { item.isActive && item.userId == parseInt(this.permission.userData.id) && item.pageTemplateId == this.pageTemplate.id && item.isReadAccess });
  }

  ngAfterViewInit(): void {
    const focusableElements =
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
    const modal = document.getElementById('mapFulfilmentMainDiv'); // select the modal by it's id

    const firstFocusableElement = modal.querySelectorAll(focusableElements)[1]; // get first element to be focused inside modal

    (firstFocusableElement as HTMLElement).focus();
  }

  public keyDown(keyboardEvent: KeyboardEvent): void {
    const focusableElements =
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
    const modal = document.getElementById('mapFulfilmentMainDiv');
    const firstFocusableElement = modal.querySelectorAll(focusableElements)[0]; // get first element to be focused inside modal
    const focusableContent = modal.querySelectorAll(focusableElements);
    let lastFocusableElement = focusableContent[focusableContent.length - 1]; // get last element to be focused inside modal
    if ((this.displayMapButton || this.displayMapButton) && (this.selectedProjectId === '' || this.pageTemplateResponseDetailsDTO === null)) {
      lastFocusableElement = focusableContent[focusableContent.length - 2];
    }
    if (keyboardEvent.shiftKey) { // if shift key pressed for shift + tab combination
      if (document.activeElement === firstFocusableElement) {
        (lastFocusableElement as HTMLElement).focus(); // add focus for the last focusable element
        keyboardEvent.preventDefault();
      }
    } else { // if tab key is pressed
      if (document.activeElement === lastFocusableElement) { // if focused has reached to last focusable element then focus first focusable element after pressing tab
        (firstFocusableElement as HTMLElement).focus(); // add focus for the first focusable element
        keyboardEvent.preventDefault();
      }
    }
  }
}

<div class="custom-search-input">
  <label *ngIf="label" class="control-label" for="type-ahead-input">{{label}}</label>
  <div class="input-group">

    <input id="type-ahead-input" #search type="text" [(ngModel)]="value" value="{{val}}" autocomplete="off"
      [typeaheadSingleWords]="false" [typeaheadSelectFirstItem]="false" [typeahead]="titleArray"
      [typeaheadMinLength]="0" [typeaheadOptionsLimit]="500" placeholder="{{placeholder}}"
      (typeaheadOnSelect)="onSelect();" (typeaheadOnBlur)="onSelect();" (keydown)="keydown($event)"
      class="form-control search-query form-control fdl-ng-typeahead">

    <span class="input-group-btn">

      <button *ngIf="!val || val==''" type="button" class="btn"><i class="material-icons">search</i> {{val}}</button>
      <!--Use this close icon as soon as searched the data to clear the search -->
      <button *ngIf="val && val!=''" type="button" class="btn" (click)="clearVal()"><i
          class="material-icons">close</i></button>
    </span>
  </div>
</div>

<!-- Add class of "back-action-container" when having back button in place like below -->
<div class="heading-container fdl-2" *ngIf="!isDrawerType">
  <div class="back-action-container">
    <div class="back-button">
      <a href="javascript:void(0);" (click)="goBack()">
        <span class="material-icons">
          arrow_back
        </span>
        Users
      </a>
    </div>
    <h1 *ngIf="!isEdit">Create New User</h1>
    <h1 *ngIf="isEdit">{{userForm.get('name').value}}</h1>
  </div>

</div>
<!-- border-0 border-b-0 -->
<div class="form-container " [ngClass]="{'p-1': isDrawerType}">

  <div class="alert alert-dismissible alert-danger" *ngIf="isError">
    <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isError=false">×</button>
    <i class="material-icons">warning</i>
    <p [innerHtml]="errorMsg"></p>
  </div>

  <p *ngIf="!isEdit">Please complete below required information to create a new user.</p>
  <div class="pt-2">

    <div class="form-row" *ngIf="(!isAdmin && isFiservUser)">
      <div class="col-md-6 mb-3">
        <label for="organizationTitle" class="asterisk">Organization:</label>
        <input type="text" name="text" [(ngModel)]="organizationDetails.organizationTitle" readonly class="form-control"
          id="organizationTitle">
      </div>
    </div>

    <form [formGroup]="userForm" autocomplete="off">
      <div class="form-row" *ngIf="isAdmin && userForm.get('userType').value === 'Fiserv'">
        <div class="col-md-6 mb-3 typeahead-wrapper"
          [ngClass]="{'has-error': !userForm.get('organization').valid && (userForm.get('organization').touched || userForm.get('organization').dirty || isSubmitted)}">
          <label for="organization" class="asterisk">Organization:</label>
          <fdl-type-ahead formControlName="organization" [collection]="organizationSuggestionEdited" optionField="title"
            placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0"
            (input)="onOrganizationChanged(null)" (onSelect)="onOrgSelect($event)"></fdl-type-ahead>
          <div class="has-error">
            <span
              *ngIf="!userForm.get('organization').valid && userForm.get('organization').errors?.required && (userForm.get('organization').touched || isSubmitted)"><i
                class="material-icons">warning</i> Please select Organization. </span>
            <span
              *ngIf="invalidOrganizationName || (!userForm.get('organization').valid && (userForm.get('organization').errors?.pattern) && (userForm.get('organization').dirty ||  isSubmitted))">
              <i class="material-icons">warning</i>Please enter a valid Organization Name</span>
          </div>
        </div>
      </div>
      <div class="form-row">
        <!-- <div class="col-md-6 mb-3" [hidden]="hideUserControl" -->
        <div class="col-md-6 mb-3" [hidden]="drawerData && drawerData['hideUserControl']"
          [ngClass]="{'has-error': !userForm.get('userType').valid && (userForm.get('userType').touched|| isSubmitted) }">
          <label for="validationCustom01" class="asterisk">User Type: <span *ngIf="isDrawerType && !hasClient"
              class="material-icons btn-secondary f-18" data-toggle="tooltip"
              [attr.title]="drawerData['userTypeTooltip']">info</span></label>
          <fieldset [disabled]="isEdit || isClone || isCreateNewUserFromDelegateScreen || 
          (isDrawerType && !hasClient)">
            <div class="radio-group d-flex">
              <div class="radio mr-3">
                <input type="radio" id="userTypeRadioTwo" name="userType" value="Fiserv" formControlName="userType"
                  [disabled]="isEdit" (change)="handleChange($event)">
                <label for="userTypeRadioTwo"><span>Fiserv</span></label>
              </div>
              <div class="radio">
                <input type="radio" id="userTypeRadioOne" name="userType" value="Client" formControlName="userType"
                  [disabled]="isEdit" (change)="handleChange($event)">
                <label for="userTypeRadioOne"><span>Client</span></label>
              </div>
            </div>
          </fieldset>
          <span *ngIf="!userForm.get('userType').valid && (userForm.get('userType').touched || isSubmitted)">
            <i class="material-icons">warning</i> Please select user type.
          </span>
        </div>
      </div>
      <div class="form-row" *ngIf="userForm.get('userType').value === 'Client' && !isEdit">
        <div class="col-md-6 mb-3 typeahead-wrapper"
          [ngClass]="{'has-error': invalidClientName || (!userForm.get('clientId').valid && (userForm.get('clientId').touched || userForm.get('clientId').dirty || isSubmitted)) }">
          <label for="client" class="asterisk">Client Name/DUNS:</label>
          <fdl-type-ahead [(ngModel)]="clientText" *ngIf='!readOnlyClientControl' formControlName="clientId"
            [collection]="clientSuggestion" optionField="titleWithDuns" placeholder="Type to search" [scrollable]="true"
            [optionsLimit]="100" [minLength]="0" (input)="typeaheadLoadingClients()"
            (onSelect)="onClientSelect($event)">
          </fdl-type-ahead>
          <input type="text" name="text" formControlName="clientId" readonly class="form-control" id="client"
            *ngIf='readOnlyClientControl'>
          <span
            *ngIf="!userForm.get('clientId').valid && (userForm.get('clientId').errors?.required || userForm.get('clientId').errors?.validateWhiteSpaces) && (userForm.get('clientId').touched || isSubmitted)">
            <i class="material-icons">warning</i> Please select client name.
          </span>
          <span
            *ngIf="invalidClientName || (!userForm.get('clientId').valid && userForm.get('clientId').errors?.pattern && (userForm.get('clientId').dirty || isSubmitted))">
            <i class="material-icons">warning</i> Please enter a valid Client Name </span>
        </div>
      </div>

      <fieldset [disabled]="isEdit">
        <div class="form-row" *ngIf="userForm.get('userType').value === 'Client' && isEdit">
          <div class="col-md-6 mb-3">
            <label for="ClientTitle" class="asterisk">Client:</label>
            <input type="text" class="form-control" name="clientName" [value]="FormData.clientTitleWithDuns">
          </div>
        </div>
      </fieldset>
      <div class="form-row">
        <div class="col-md-6 mb-3"
          [ngClass]="{'has-error': !userForm.get('name').valid && (userForm.get('name').touched || userForm.get('name').dirty || isSubmitted)}">
          <label for="name" class="asterisk">Name:</label>
          <input type="text" class="form-control" name="name" formControlName="name">
          <span
            *ngIf="!userForm.get('name').valid && (userForm.get('name').errors?.required || userForm.get('name').errors?.validateWhiteSpaces) && (userForm.get('name').touched || isSubmitted)">
            <i class="material-icons">warning</i> Please enter Name.
          </span>
          <span
            *ngIf="!userForm.get('name').valid && userForm.get('name').errors?.pattern && (userForm.get('name').touched || isSubmitted)">
            <i class="material-icons">warning</i> {{regexValidationMessage.USER_NAME_VALIDATION_MESSAGE}}
          </span>
          <span
            *ngIf="!userForm.get('name').valid && userForm.get('name').errors?.maxlength && (userForm.get('name').touched || isSubmitted)">
            <i class="material-icons">warning</i> Maximum input character limit is {{_maxLength.User.Name}}, please
            correct it.
          </span>
        </div>
      </div>

      <div class="form-row">
        <div class="col-md-6 mb-3"
          [ngClass]="{'has-error': !userForm.get('email').valid && (userForm.get('email').touched || userForm.get('email').dirty || isSubmitted)}">
          <label for="email" class="asterisk">Email:</label>
          <input type="text" class="form-control" name="email" formControlName="email" [readonly]="isEdit">
          <span
            *ngIf="!userForm.get('email').valid && (userForm.get('email').errors?.required || userForm.get('email').errors?.validateWhiteSpaces) && (userForm.get('email').touched || isSubmitted)">
            <i class="material-icons">warning</i> Please enter an Email.
          </span>
          <span
            *ngIf="!userForm.get('email').valid && userForm.get('email').errors?.pattern && (userForm.get('email').touched || isSubmitted)">
            <i class="material-icons">warning</i> {{regexValidationMessage.EMAIL_VALIDATION_MESSAGE}}
          </span>
          <span
            *ngIf="!userForm.get('email').valid && userForm.get('email').errors?.maxlength && (userForm.get('email').touched || isSubmitted)">
            <i class="material-icons">warning</i> Maximum input character limit is {{_maxLength.User.Email}}, please
            correct it.
          </span>
        </div>

        <!-- <div class="fdl-2"
          *ngIf="userForm.get('userType').value === 'Fiserv' && !isEdit && userForm.get('email').valid && userForm.get('email').value.includes('fiserv.com')">
          <button type="button" class="btn fdl-btn btn-primary-orange mt-4" (click)="fetchDetailsFromAD()">Fetch Details
            from AD</button>
        </div> -->
      </div>

      <div class="form-row"
        *ngIf="userADDetails && userForm.get('email').valid && userForm.get('userType').value === 'Fiserv' && userForm.get('email').value.includes('fiserv.com')">
        <div class="col-md-2 mb-2">
          <label for="userName" class="">Name:</label>
          <input type="text" class="form-control" name="userName" readonly [value]="userADDetails.userFullName">
        </div>
        <div class="col-md-2 mb-2">
          <label for="department" class="">Department:</label>
          <input type="text" class="form-control" name="department" readonly [value]="userADDetails.userDepartment">
        </div>
        <div class="col-md-2 mb-2">
          <label for="jobTitle" class="">Job Title:</label>
          <input type="text" class="form-control" name="jobTitle" readonly [value]="userADDetails.userJobTitle">
        </div>
        <div class="col-md-2 mb-2">
          <label for="officeLocation" class="">Office Location:</label>
          <input type="text" class="form-control" name="officeLocation" readonly
            [value]="userADDetails.userJobLocation">
        </div>
        <div class="col-md-2 mb-2">
          <label for="managerName" class="">Manager Name:</label>
          <input type="text" class="form-control" name="managerName" readonly [value]="userADDetails.userManagerName">
        </div>
        <div class="col-md-2 mb-2">
          <label for="contactNumber" class="">Contact Number:</label>
          <input type="text" class="form-control" name="contactNumber" readonly [value]="userADDetails.userMobile">
        </div>
      </div>

      <div class="form-row" *ngIf="userForm.get('userType').value === 'Client'">
        <div class="col-md-6 mb-3 typeahead-wrapper">
          <label for="client">User Group:</label>
          <input type="text" class="form-control" name="userGroup" formControlName="restrictedUserGroup">
        </div>
      </div>

      <div class="form-row">
        <div class="col-md-6 mb-1">
          <div class="checkbox">
            <div class="position-relative">
              <input type="checkbox" id="isDistributionUser" name="isDistributionUser"
                formControlName="isDistributionUser" />
              <label class="" for="isDistributionUser"><span class="ml-3">Is this a Distribution List?</span></label>
            </div>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="col-md-6 mb-3"
          [ngClass]="{'has-error': !userForm.get('phone').valid && (userForm.get('phone').touched || isSubmitted) }">
          <label for="phone">Contact Number:</label>
          <input type="text" class="form-control" name="phone" formControlName="phone">
          <span *ngIf="!userForm.get('phone').valid && (userForm.get('phone').touched || isSubmitted)">
            <i class="material-icons">warning</i>{{regexValidationMessage.MOBILE_VALIDATION_MESSAGE}}
          </span>
        </div>
      </div>
    </form>
    <Section *ngIf="isDrawerType">
      <label class="asterisk">Role:</label>
      <span class="hasError" *ngIf="selectedRoleIds.length === 0 && isSubmitted">
        Please select at least one role.
      </span>
      <form [formGroup]="roleForm">
        <fieldset>
          <legend class="border-0"></legend>
          <div class="checkbox" formArrayName="roles"
            *ngFor="let control of roleForm.controls.roles['controls']; let i = index">
            <input type="checkbox" [formControlName]="i" id="{{'chkRole'+ i}}"
              (change)="onRolesChangeForDrawer(roles[i].id)" />
            <label for="{{'chkRole'+ i}}"><span>{{roles[i].title}}</span></label>
          </div>
        </fieldset>
      </form>
    </Section>
    <div class="row mb-2" *ngIf="!isDrawerType">
      <div class="col-md-12 d-flex justify-content-end">
        <button tabindex="0" class="btn fdl-btn btn-primary-dark" (click)="openRolesDrawer()">Map Roles To
          Assign</button>
      </div>
    </div>
    <div class="row" *ngIf="!isDrawerType">
      <div class="intake-request-form intake-request-form-wrapper client-form-wrapper full-view mb-2">
        <div class="box accordion collapse-view org-accordion">
          <div data-toggle="collapse" href="#collapseOne" aria-expanded="true"
            *ngIf="isAdmin && userForm.get('userType').value === 'Fiserv'"
            class="h6 org-heading heading-level-3 mt-0 mb-0 d-flex align-items-center justify-content-between">
            <strong> Organization Type : Base
            </strong>
          </div>
          <div id="collapseOne" class="mt-3 collapse show">
            <label class="w-100" *ngIf="isAdmin && userForm.get('userType').value === 'Fiserv'">Organization
              Name</label>
            <div class="filter-wrapper mb-1 pb-2" *ngIf="isAdmin && userForm.get('userType').value === 'Fiserv'">
              <div class="filter-tag">
                <div class="tag pr-2 pr-2">{{userForm.get('organization').value}}
                </div>
              </div>
            </div>
            <label class="w-100"> Roles</label>
            <div class="filter-wrapper mb-1 pb-2">
              <div class="col-md-6 mb-3" class="has-error"
                *ngIf="isRespondentDeselected && isSubmitted === true && isDrawerType == false">
                <span>
                  <i class="material-icons">warning</i> Respondent Role is required for the User to have an Intake
                  Form Submitter role.
                </span>
              </div>
              <div class="col-md-6 mb-3" class="has-error"
                *ngIf="selectedRoleListForDisplay.length === 0 && isSubmitted === true && isDrawerType === false && nonAdminSelectedRoleListForDisplay.length == 0">
                <span>
                  <i class="material-icons">warning</i> Please assign at least one role.
                </span>
              </div>
              <div class="col-md-6 mb-3" class="has-error" *ngIf="isAdminRoleSelected">
                <span>
                  <i class="material-icons">warning</i> Either 'Admin' or 'Restricted Admin' role can be assigned to
                  user.
                </span>
              </div>
              <div class="col-md-6 mb-3" class="has-error" *ngIf="isDivisionLeaderSelected">
                <span>
                  <i class="material-icons">warning</i> Either 'Division Leader' or 'Restricted Admin' role can be
                  assigned to user.
                </span>
              </div>
              <div class="col-md-6 mb-3" class="has-error" *ngIf="isBuLeaderSelected">
                <span>
                  <i class="material-icons">warning</i> Either 'BU Leader' or 'Restricted Admin' role can be assigned to
                  user.
                </span>
              </div>
              <div class="col-md-6 mb-3" class="has-error" *ngIf="isDeliveryOwnerSelected">
                <span>
                  <i class="material-icons">warning</i> Either 'Delivery Owner' or 'Restricted Admin' role can be
                  assigned to user.
                </span>
              </div>
              <div class="col-md-6 mb-3" class="has-error" *ngIf="isDeliveryLeaderSelected">
                <span>
                  <i class="material-icons">warning</i> Either 'Delivery Leader' or 'Restricted Admin' role can be
                  assigned to user.
                </span>
              </div>
              <div class="col-md-6 mb-3" class="has-error" *ngIf="isRelationshipManagerSelected">
                <span>
                  <i class="material-icons">warning</i> Either 'Relationship Manager' or 'Restricted Admin' role can be
                  assigned to user.
                </span>
              </div>
              <div class="col-md-6 mb-3" class="has-error" *ngIf="isSalesExecutiveSelected">
                <span>
                  <i class="material-icons">warning</i> Either 'Sales Executive' or 'Restricted Admin' role can be
                  assigned to user.
                </span>
              </div>
              <div class="col-md-6 mb-3" class="has-error" *ngIf="isAccountTeamSelected">
                <span>
                  <i class="material-icons">warning</i> Either 'Account Team' or 'Restricted Admin' role can be assigned
                  to user.
                </span>
              </div>
              <div class="col-md-6 mb-3" class="has-error" *ngIf="isRestrictedAdminRoleSelected">
                <span>
                  <i class="material-icons">warning</i> Either 'Restricted Admin' or 'Restricted Admin - Read Only' role
                  can be assigned to user.
                </span>
              </div>
              <div class="col-md-6 mb-3" class="has-error" *ngIf="cctAdminRoleHasError">
                <span>
                  <i class="material-icons">warning</i> Please select Commitments Intake from page template section.
                </span>
              </div>
              <div class="filter-tag" *ngFor="let role of selectedRoleListForDisplay">
                <div class="tag" data-toggle="tooltip" data-placement="top" title="{{role.title}}">
                  {{role.title}} <a href="javascript:void(0)" (click)="removeRole(role.id)" aria-label="Close"
                    class="tag-close">
                    <i tabindex="0" aria-hidden="true" class="material-icons">close</i>
                  </a>
                </div>
              </div>
            </div>
            <!--<label class="w-100"> Assigned Products</label>
            <div class="filter-wrapper">
              <div class="col-md-6 mb-3" class="has-error" *ngIf="(selectedRoleIds.indexOf(2) > -1 || selectedRoleIds.indexOf(3) > -1 || selectedRoleIds.indexOf(4) > -1 || selectedRoleIds.indexOf(6) > -1 || selectedRoleIds.indexOf(7) > -1 || selectedRoleIds.indexOf(8) > -1) 
                && selectedRoleIds.indexOf(1) === -1 && selectedProductIds.length === 0 && isSubmitted === true">
                <span>
                  <i class="material-icons">warning</i> Please assign at least one product.
                </span>
              </div>
              <div class="filter-tag" *ngFor="let product of selectedProductsForDisplay">
                <div class="tag" data-toggle="tooltip" data-placement="top" title="{{product.title}}"
                  *ngIf="!(userData.id === FormData?.id && isEdit && !isAdmin)">
                  {{product.title}} <a href="javascript:void(0)" (click)="removeProduct(product.id)" aria-label="Close"
                    class="tag-close">
                    <i tabindex="0" aria-hidden="true" class="material-icons">close</i>
                  </a>
                </div>
                <div class="tag" data-toggle="tooltip" data-placement="top" title="{{product.title}}"
                  *ngIf="(userData.id === FormData?.id && isEdit && !isAdmin)">
                  {{product.title}} <a href="javascript:void(0)" aria-label="Close" class="tag-close">
                  </a>
                </div>
              </div>
              <div class="filter-tag" *ngFor="let product of nonAdminSelectedProductListForDisplay">
                <div class="tag" data-toggle="tooltip" data-placement="top" title="{{product.productTitle}}">
                  {{product.productTitle}}
                </div>
              </div>
            </div>-->
            <label class="w-100" *ngIf="doesBaseRoleNeedGroups()"> Groups</label>
            <div class="filter-wrapper mt-2" *ngIf="doesBaseRoleNeedGroups()">
              <div class="col-md-6 mb-3" class="has-error" *ngIf="groupHasError">
                <span>
                  <i class="material-icons">warning</i> Please select at least one group.
                </span>
              </div>
              <div class="filter-tag" *ngFor="let group of selectedGroupsForDisplay">
                <div class="tag" data-toggle="tooltip" data-placement="top" title="{{group.name}}">
                  {{group.name}} <a href="javascript:void(0)" (click)="removeGroup(group.id)" aria-label="Close"
                    class="tag-close">
                    <i tabindex="0" aria-hidden="true" class="material-icons">close</i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="isAdmin && userForm.get('userType').value === 'Fiserv'">
        <div class="intake-request-form intake-request-form-wrapper client-form-wrapper full-view mb-2"
          *ngFor="let item of crossOrganizationsForDisplay; let i = index">
          <div class="box accordion collapse-view org-accordion">
            <div data-toggle="collapse" attr.href="#collapse{{i}}" aria-expanded="true"
              class="h6 org-heading heading-level-3 mt-0 mb-0 d-flex align-items-center justify-content-between">
              <strong> Organization Type : Cross
              </strong>
            </div>
            <div attr.id="collapse{{i}}" class="mt-3 collapse show">
              <label class="w-100">Organization Name</label>
              <div class="filter-wrapper mb-1 pb-2 ">
                <div class="filter-tag">
                  <div class="tag pr-2 pr-2">{{item.name}}
                  </div>
                </div>
              </div><label class="w-100"> Roles</label>
              <div class="filter-wrapper mb-1 pb-2">
                <div class="col-md-6 mb-3" class="has-error" *ngIf="item.crossOrganizationRoleHasError">
                  <span>
                    <i class="material-icons">warning</i> Please select at least one role.
                  </span>
                </div>
                <div class="col-md-6 mb-3" class="has-error" *ngIf="item.isRespondentDeselected">
                  <span *ngIf="!isCreateNewUserFromDelegateScreen">
                    <i class="material-icons">warning</i> Respondent Role is required for the User to have an Intake
                    Form Submitter role.
                  </span>
                  <span *ngIf="isCreateNewUserFromDelegateScreen">
                    <i class="material-icons">warning</i> Respondent Role is required for the User to have an Delegate
                    Form role.
                  </span>
                </div>

                <div class="col-md-6 mb-3" class="has-error" *ngIf="item.isDivisionLeaderSelected">
                  <span>
                    <i class="material-icons">warning</i> Either 'Division Leader' or 'Restricted Admin' role can be
                    assigned to user.
                  </span>
                </div>
                <div class="col-md-6 mb-3" class="has-error" *ngIf="item.isBuLeaderSelected">
                  <span>
                    <i class="material-icons">warning</i> Either 'BU Leader' or 'Restricted Admin' role can be assigned
                    to user.
                  </span>
                </div>
                <div class="col-md-6 mb-3" class="has-error" *ngIf="item.isDeliveryOwnerSelected">
                  <span>
                    <i class="material-icons">warning</i> Either 'Delivery Owner' or 'Restricted Admin' role can be
                    assigned to user.
                  </span>
                </div>
                <div class="col-md-6 mb-3" class="has-error" *ngIf="item.isDeliveryLeaderSelected">
                  <span>
                    <i class="material-icons">warning</i> Either 'Delivery Leader' or 'Restricted Admin' role can be
                    assigned to user.
                  </span>
                </div>
                <div class="col-md-6 mb-3" class="has-error" *ngIf="item.isRelationshipManagerSelected">
                  <span>
                    <i class="material-icons">warning</i> Either 'Relationship Manager' or 'Restricted Admin' role can
                    be assigned to user.
                  </span>
                </div>
                <div class="col-md-6 mb-3" class="has-error" *ngIf="item.isSalesExecutiveSelected">
                  <span>
                    <i class="material-icons">warning</i> Either 'Sales Executive' or 'Restricted Admin' role can be
                    assigned to user.
                  </span>
                </div>
                <div class="col-md-6 mb-3" class="has-error" *ngIf="item.isAccountTeamSelected">
                  <span>
                    <i class="material-icons">warning</i> Either 'Account Team' or 'Restricted Admin' role can be
                    assigned to user.
                  </span>
                </div>
                <div class="col-md-6 mb-3" class="has-error" *ngIf="item.isAdminRoleSelected">
                  <span>
                    <i class="material-icons">warning</i> Either 'Admin' or 'Restricted Admin' role can be assigned to
                    user.
                  </span>
                </div>
                <div class="col-md-6 mb-3" class="has-error" *ngIf="item.isRestrictedAdminRoleSelected">
                  <span>
                    <i class="material-icons">warning</i> Either 'Restricted Admin' or 'Restricted Admin - Read Only'
                    role can be assigned to user.
                  </span>
                </div>
                <div class="col-md-6 mb-3" class="has-error" *ngIf="item.cctAdminRoleHasError">
                  <span>
                    <i class="material-icons">warning</i> Please select Commitments Intake from page template section.
                  </span>
                </div>
                <div class="filter-tag" *ngFor="let crossOrganizationRole of item.roles">
                  <div _ngcontent-jpx-c382="" data-toggle="tooltip" data-placement="top" class="tag"
                    title="{{crossOrganizationRole.name}}">
                    {{crossOrganizationRole.name}}
                    <a _ngcontent-jpx-c382="" href="javascript:void(0)"
                      (click)="deleteOrganizationRole(crossOrganizationRole.id, item)" aria-label="Close"
                      class="tag-close">
                      <i _ngcontent-jpx-c382="" tabindex="0" aria-hidden="true" class="material-icons">close</i>
                    </a>
                  </div>
                </div>
              </div>
              <label class="w-100"> Assigned Products</label>
              <div class="col-md-6 mb-3" class="has-error" *ngIf="item.crossOrganizationProductHasError">
                <span>
                  <i class="material-icons">warning</i> Please select at least one product.
                </span>
              </div>
              <div class="filter-wrapper">
                <div class="filter-tag" *ngFor="let crossOrganizationProduct of item.products">
                  <div _ngcontent-jpx-c382="" data-toggle="tooltip" data-placement="top" class="tag"
                    title="{{crossOrganizationProduct.name}}">
                    {{crossOrganizationProduct.name}}
                    <a _ngcontent-jpx-c382="" href="javascript:void(0)"
                      (click)="deleteOrganizationProduct(crossOrganizationProduct.id, item)" aria-label="Close"
                      class="tag-close">
                      <i _ngcontent-jpx-c382="" tabindex="0" aria-hidden="true" class="material-icons">close</i>
                    </a>
                  </div>
                </div>
              </div>
              <label class="w-100" *ngIf="doesRoleNeedGroups(item.roles)"> Assigned Groups</label>
              <div class="col-md-6 mb-3" class="has-error" *ngIf="item.crossOrganizationGroupHasError">
                <span>
                  <i class="material-icons">warning</i> Please select at least one group.
                </span>
              </div>
              <div class="filter-wrapper" *ngIf="doesRoleNeedGroups(item.roles)">
                <div class="filter-tag" *ngFor="let crossOrganizationGroup of item.groups">
                  <div _ngcontent-jpx-c382="" data-toggle="tooltip" data-placement="top" class="tag"
                    title="{{crossOrganizationGroup.name}}">
                    {{crossOrganizationGroup.name}}
                    <a _ngcontent-jpx-c382="" href="javascript:void(0)"
                      (click)="deleteOrganizationGroup(crossOrganizationGroup.id, item)" aria-label="Close"
                      class="tag-close">
                      <i _ngcontent-jpx-c382="" tabindex="0" aria-hidden="true" class="material-icons">close</i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <!-- <div class="col-md-12 box mb-0" *ngIf="!isDrawerType">
      <div class="col-md-12 p-0 fdl-2">
        <strong class="h6 heading-level-3 mt-0 mb-2 d-flex align-items-center justify-content-between">
          <span class="asterisk">Select/De-Select/Map Roles to Assign</span>
          <button tabindex="0" class="btn fdl-btn btn-primary-dark" (click)="openRolesDrawer()"><span
              class="material-icons mr-1"> {{selectedRoleListForDisplay.length === 0 ? 'add' : 'edit'}}
            </span>{{selectedRoleListForDisplay.length === 0 ? 'Add Roles' : 'Edit Roles'}}</button>
        </strong> -->
    <!-- <label aria-describedby="addAccount" class="w-100 asterisk">Select/De-Select Roles to Assign:</label>
        <button class="btn fdl-btn btn-secondary-light " id="addAccount" (click)="openRolesDrawer()">Select
          Roles</button> -->

    <!-- <div class="col-md-12 p-0 fdl-2 d-flex mb-2 align-items-center">
          <div class="filter-wrapper">
            <div class="filter-tag" *ngIf="showHierarchyDateReadOnlyForDisplay">
              <div data-toggle="tooltip" data-placement="top" class="tag" title="CCT Admin" style="max-width: initial;">
                Show Hierarchy Date - Read Only
                <a href="javascript:void(0)" (click)="setShowHierarchyDateReadOnlyToFalse()" aria-label="Close"
                  class="tag-close">
                  <i _ngcontent-jpx-c382="" tabindex="0" aria-hidden="true" class="material-icons">close</i>
                </a>
              </div>
            </div>
            <div class="filter-tag" *ngIf="showHierarchyDateReadWriteForDisplay">
              <div data-toggle="tooltip" data-placement="top" class="tag" title="CCT Admin" style="max-width: initial;">
                Show Hierarchy Date - Read, Write
                <a href="javascript:void(0)" (click)="setShowHierarchyDateReadWriteToFalse()" aria-label="Close"
                  class="tag-close">
                  <i _ngcontent-jpx-c382="" tabindex="0" aria-hidden="true" class="material-icons">close</i>
                </a>
              </div>
            </div>
          </div>
        </div> -->

    <!-- <div class="config-role-table">
          <table aria-describedby="tblDesc" class="f-12 table table-hover table-label">
            <thead>
              <tr _ngcontent-jpx-c309="" role="row">
                <th _ngcontent-jpx-c309="" role="columnheader" scope="col"><span _ngcontent-jpx-c309="">Type</span>
                </th>
                <th _ngcontent-jpx-c309="" role="columnheader" scope="col"><span _ngcontent-jpx-c309="">Org
                    Name</span></th>
                <th _ngcontent-jpx-c309="" role="columnheader" scope="col"><span _ngcontent-jpx-c309="">Roles</span>
                </th>
                <th _ngcontent-jpx-c309="" role="columnheader" scope="col"><span _ngcontent-jpx-c309="">Products</span>
                </th>
                <th _ngcontent-jpx-c309="" role="columnheader" scope="col"><span _ngcontent-jpx-c309="">Groups</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr role="row">
                <td role="cell"><label>Base Org</label></td>
                <td role="cell"><label>Global Services</label></td>
                <td role="cell" style="width: auto;">
                   Selected Roles buttons
                  <div class="filter-wrapper mt-2">
                    <div class="filter-tag" *ngFor="let role of selectedRoleListForDisplay">
                      <div class="tag" data-toggle="tooltip" data-placement="top" title="{{role.title}}">
                        {{role.title}} <a href="javascript:void(0)" (click)="removeRole(role.id)" aria-label="Close"
                          class="tag-close">
                          <i tabindex="0" aria-hidden="true" class="material-icons">close</i>
                        </a>
                      </div>
                    </div>
                    <div class="filter-tag" *ngFor="let role of nonAdminSelectedRoleListForDisplay">
                      <div class="tag" data-toggle="tooltip" data-placement="top" title="{{role.roleTitle}}">
                        {{role.roleTitle}}
                       
                      </div>
                    </div>
                  </div>
                </td>
                <td role="cell" style="width: auto;"></td>
                <td role="cell" style="width: auto;"></td>
              </tr>
              <tr role="row" *ngFor="let item of crossOrganizationsForDisplay"
                [ngClass]="{'has-error' : item.crossOrganizationRoleHasError || item.crossOrganizationProductHasError || item.crossOrganizationGroupsHasError}">
                <td role="cell"><label>Cross Org</label></td>
                <td role="cell"><label>{{item.name}}</label></td>
                <td role="cell">
                  <div class="filter-wrapper mt-2" *ngIf="!item.crossOrganizationRoleHasError">
                    <div _ngcontent-jpx-c382="" class="filter-tag" *ngFor="let crossOrganizationRole of item.roles">
                      <div _ngcontent-jpx-c382="" data-toggle="tooltip" data-placement="top" class="tag"
                        title="{{crossOrganizationRole.name}}">
                        {{crossOrganizationRole.name}}
                        <a _ngcontent-jpx-c382="" href="javascript:void(0)"
                          (click)="deleteOrganizationRole(crossOrganizationRole.id, item)" aria-label="Close"
                          class="tag-close">
                          <i _ngcontent-jpx-c382="" tabindex="0" aria-hidden="true" class="material-icons">close</i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <p *ngIf="item.crossOrganizationRoleHasError">Please select at least one role.</p>
                </td>
                <td role="cell">
                  <div class="filter-wrapper mt-2" *ngIf="!item.crossOrganizationProductHasError">
                    <div _ngcontent-jpx-c382="" class="filter-tag"
                      *ngFor="let crossOrganizationProduct of item.products">
                      <div _ngcontent-jpx-c382="" data-toggle="tooltip" data-placement="top" class="tag"
                        title="{{crossOrganizationProduct.name}}">
                        {{crossOrganizationProduct.name}}
                        <a _ngcontent-jpx-c382="" href="javascript:void(0)"
                          (click)="deleteOrganizationProduct(crossOrganizationProduct.id, item)" aria-label="Close"
                          class="tag-close">
                          <i _ngcontent-jpx-c382="" tabindex="0" aria-hidden="true" class="material-icons">close</i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <p *ngIf="item.crossOrganizationProductHasError">Please select at least one product.</p>
                </td>
                <td role="cell">
                  <div class="filter-wrapper mt-2" *ngIf="!item.crossOrganizationGroupHasError">
                    <div _ngcontent-jpx-c382="" class="filter-tag" *ngFor="let crossOrganizationGroup of item.groups">
                      <div _ngcontent-jpx-c382="" data-toggle="tooltip" data-placement="top" class="tag"
                        title="{{crossOrganizationGroup.name}}">
                        {{crossOrganizationGroup.name}}
                        <a _ngcontent-jpx-c382="" href="javascript:void(0)"
                          (click)="deleteOrganizationGroup(crossOrganizationGroup.id, item)" aria-label="Close"
                          class="tag-close">
                          <i _ngcontent-jpx-c382="" tabindex="0" aria-hidden="true" class="material-icons">close</i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <p *ngIf="item.crossOrganizationGroupHasError">Please select at least one group.</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div> -->
    <!-- <div _ngcontent-uns-c309="" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog"
          aria-labelledby="myLargeModalLabel" aria-hidden="true" class="modal fade bd-example-modal-md"
          style="display: none;" id="deleteModal_clientContact">
          <div _ngcontent-uns-c309="" role="document" class="modal-dialog modal-md mt-120" style="max-width: 700px;">
            <div _ngcontent-uns-c309="" class="modal-content">
              <div _ngcontent-uns-c309="" class="modal-header">
                <h4 _ngcontent-uns-c309="" id="myLargeModalLabel" class="modal-title">Delete Client Contact</h4>
                <button _ngcontent-uns-c309="" type="button" data-dismiss="modal" aria-label="Close popup"
                  class="close">
                  <span _ngcontent-uns-c309="" aria-hidden="true">×</span>
                </button>
              </div>
              <div _ngcontent-uns-c309="" class="modal-body">
                <p _ngcontent-uns-c309="">Are you sure you want to delete client contact <b _ngcontent-uns-c309=""></b>?
                </p>
              </div>
              <div _ngcontent-uns-c309="" class="modal-footer fdl-2">
                <button _ngcontent-uns-c309="" type="button" data-dismiss="modal"
                  class="btn fdl-btn btn-secondary-light">Cancel</button>
                <button _ngcontent-uns-c309="" type="button" data-dismiss="modal"
                  class="btn fdl-btn btn-primary-orange ml-2">Ok</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div class="col-md-12 box mb-0 mt-3" *ngIf="isDrawerType">
      <div class="col-md-12 p-0 fdl-2">
        <label
          *ngIf="(selectedRoleIds.indexOf(2) > -1 || selectedRoleIds.indexOf(3) > -1 || selectedRoleIds.indexOf(4) > -1 || selectedRoleIds.indexOf(6) > -1 || selectedRoleIds.indexOf(7) > -1 || selectedRoleIds.indexOf(8) > -1) && selectedRoleIds.indexOf(1) === -1"
          aria-describedby="addAccount" class="w-100 asterisk">Select/De-Select Products to Assign:</label>
        <label
          *ngIf="!(selectedRoleIds.indexOf(2) > -1 || selectedRoleIds.indexOf(3) > -1 || selectedRoleIds.indexOf(4) > -1 || selectedRoleIds.indexOf(6) > -1 || selectedRoleIds.indexOf(7) > -1 || selectedRoleIds.indexOf(8) > -1) && selectedRoleIds.indexOf(1) === -1"
          aria-describedby="addAccount" class="w-100">Select/De-Select Products to Assign:</label>
        <button class="btn fdl-btn btn-secondary-light " id="addAccount" (click)="openProductsDrawer()"
          [disabled]="(userData.id === FormData?.id && isEdit && !isAdmin)">Select
          Products</button>
        <!-- Selected Products buttons -->
        <div class="filter-wrapper mt-2">
          <div class="filter-tag" *ngFor="let product of selectedProductsForDisplay">
            <div class="tag" data-toggle="tooltip" data-placement="top" title="{{product.title}}"
              *ngIf="!(userData.id === FormData?.id && isEdit && !isAdmin)">
              {{product.title}} <a href="javascript:void(0)" (click)="removeProduct(product.id)" aria-label="Close"
                class="tag-close">
                <i tabindex="0" aria-hidden="true" class="material-icons">close</i>
              </a>
            </div>
            <div class="tag" data-toggle="tooltip" data-placement="top" title="{{product.title}}"
              *ngIf="(userData.id === FormData?.id && isEdit && !isAdmin)">
              {{product.title}} <a href="javascript:void(0)" aria-label="Close" class="tag-close">
              </a>
            </div>
          </div>
          <div class="filter-tag" *ngFor="let product of nonAdminSelectedProductListForDisplay">
            <div class="tag" data-toggle="tooltip" data-placement="top" title="{{product.productTitle}}">
              {{product.productTitle}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 mb-3" class="has-error"
      *ngIf="(selectedRoleIds.indexOf(2) > -1 || selectedRoleIds.indexOf(3) > -1 || selectedRoleIds.indexOf(4) > -1 || selectedRoleIds.indexOf(6) > -1 || selectedRoleIds.indexOf(7) > -1 || selectedRoleIds.indexOf(8) > -1) 
        && selectedRoleIds.indexOf(1) === -1 && selectedProductIds.length === 0 && isSubmitted === true && isDrawerType === true">
      <span>
        <i class="material-icons">warning</i> Please assign at least one product.
      </span>
    </div>

    <div class="col-md-12 box mb-0 mt-3" *ngIf="showChannelPartnerSelectionOptions">
      <div class="col-md-12 p-0 fdl-2">
        <label aria-describedby="addChannelPartner" class="w-100 asterisk">Select/De-Select Clients to Assign:</label>
        <button class="btn fdl-btn btn-secondary-light " id="addChannelPartner"
          (click)="openChannelPartnerDrawer()">Select Clients</button>
        <!-- Selected Channel Partner buttons -->
        <div class="filter-wrapper mt-2">
          <div class="filter-tag" *ngFor="let channelpartner of selectedChannelPartnerForDisplay">
            <div class="tag" data-toggle="tooltip" data-placement="top" title="{{channelpartner.titleWithDuns}}">
              {{channelpartner.title}}
              <a href="javascript:void(0)" (click)="removeChannelPartner(channelpartner.id)" aria-label="Close"
                class="tag-close">
                <i tabindex="0" aria-hidden="true" class="material-icons">close</i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 mb-3" class="has-error" *ngIf="!validChannelPartner() && isSubmitted === true">
      <span>
        <i class="material-icons">warning</i> {{CHANNEL_PARTNER_EMPTY}}
      </span>
    </div>
    <div class="col-md-6 mb-3" class="has-error"
      *ngIf="showChannelPartnerSelectionOptions && channelPartnerIsExists() && isSubmitted === true">
      <span>
        <i class="material-icons">warning</i> Client does not exist. Please select a valid Client Name.
      </span>
    </div>

    <div class="col-md-12 box mb-0 mt-3" *ngIf="showGroupSelectionOptions && isDrawerType">
      <div class="col-md-12 p-0 fdl-2">
        <label aria-describedby="addGroups" class="w-100 asterisk">Select/De-Select Groups to Assign:</label>
        <button class="btn fdl-btn btn-secondary-light " id="addGroups" (click)="openGroupDrawer()">Select
          Groups</button>
        <div class="filter-wrapper mt-2">
          <div class="filter-tag" *ngFor="let group of selectedGroupsForDisplay">
            <div class="tag" data-toggle="tooltip" data-placement="top" title="{{group.name}}">
              {{group.name}} <a href="javascript:void(0)" (click)="removeGroup(group.id)" aria-label="Close"
                class="tag-close">
                <i tabindex="0" aria-hidden="true" class="material-icons">close</i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 mb-3" class="has-error" *ngIf="!ValidGroup() && isSubmitted === true && isDrawerType">
      <span>
        <i class="material-icons">warning</i> Please assign at least one Group.
      </span>
    </div>

    <div class="col-md-12 box mb-0"
      *ngIf="isDrawerType === false && isAdmin === true && userForm.get('userType').value === 'Fiserv' && !doesBaseOrgHaveRestrictedAdminRole">
      <div class="col-md-12 p-0 fdl-2">
        <label aria-describedby="addAccount" class="w-100">Page Template:</label>
        <button class="btn fdl-btn btn-secondary-light " id="addAccount" (click)="openPageTemplatesDrawer()"
          [disabled]="(userData.id === FormData?.id && isEdit && !isAdmin)">Select
          Template</button>
        <!-- Selected Page Templates buttons -->
        <div class="filter-wrapper mt-2">
          <div class="filter-tag" *ngFor="let pageTemplate of pageTemplateDataForDisplay">
            <div class="tag" data-toggle="tooltip" data-placement="top"
              title="{{pageTemplate.title}} - {{translatePageTemplateAccessType(pageTemplate.accessType)}}"
              *ngIf="!(userData.id === FormData?.id && isEdit && !isAdmin)">
              {{pageTemplate.title}} - {{translatePageTemplateAccessType(pageTemplate.accessType)}} <a
                href="javascript:void(0)" (click)="removePageTemplate(pageTemplate.id, pageTemplate.accessType)"
                aria-label="Close" class="tag-close">
                <i tabindex="0" aria-hidden="true" class="material-icons">close</i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="fdl-2 button-container" *ngIf="!isDrawerType">
    <button type="button" class="btn fdl-btn btn-secondary-light " (click)="goBack()"
      aria-label="Cancel">Cancel</button>
    <div>
      <button type="button" class="btn fdl-btn btn-primary-orange" (click)="userFormSubmit()">Submit</button>
    </div>
  </div>

</div>
<!-- Buttons For Drawer -->
<div class="fdl-2 button-container" *ngIf="isDrawerType">
  <button type="button" class="btn fdl-btn btn-secondary-light " (click)="goBack()" aria-label="Cancel">Cancel</button>
  <div>
    <button type="button" class="btn fdl-btn btn-primary-orange" (click)="userFormSubmit()">Submit</button>
  </div>
</div>

<!-- Drawer Start -->
<div class="drawer drawer-right dw-xs-12 dw-sm-8 dw-md-6 fold open mainDrawer" id="addDependentForms" tabindex="-1"
  aria-labelledby="addNewAccount" aria-expanded="true" role="region" *ngIf="showDrawer">
  <div class="drawer-contents col-xs-12 col-sm-12 col-md-12" *ngIf="showRoleDrawer">
    <h3>Configure Roles</h3>
    <p>
      Select/De-select the Role(s) to Assign. Multiple roles can be assigned to a single user.
    </p>

    <hr />
    <form [formGroup]="roleForm">
      <div class="col-md-6 mb-3" class="has-error" *ngIf="isRespondentDeselected">
        <span *ngIf="!isCreateNewUserFromDelegateScreen">
          <i class="material-icons">warning</i> Respondent Role is required for the User to have an Intake Form
          Submitter role.
        </span>
        <span *ngIf="isCreateNewUserFromDelegateScreen">
          <i class="material-icons">warning</i> Respondent Role is required for the User to have an Delegate Form
          role.
        </span>
      </div>
      <div class="col-md-6 mb-3" class="has-error" *ngIf="roleForm.invalid && (roleForm.dirty || isRoleFormSubmitted)">
        <span *ngIf="userForm.get('userType').value === 'Client'">
          <i class="material-icons">warning</i> Please assign at least one role.
        </span>
        <span *ngIf="userForm.get('userType').value !== 'Client'">
          <i class="material-icons">warning</i> Please assign at least one role in base org section.
        </span>
      </div>
      <div class="col-md-6 mb-3" class="has-error" *ngIf="isAdminRoleSelected">
        <span>
          <i class="material-icons">warning</i> Either 'Admin' or 'Restricted Admin' role can be assigned to user.
        </span>
      </div>
      <div class="col-md-6 mb-3" class="has-error" *ngIf="isDivisionLeaderSelected">
        <span>
          <i class="material-icons">warning</i> Either 'Division Leader' or 'Restricted Admin' role can be assigned to
          user.
        </span>
      </div>
      <div class="col-md-6 mb-3" class="has-error" *ngIf="isBuLeaderSelected">
        <span>
          <i class="material-icons">warning</i> Either 'BU Leader' or 'Restricted Admin' role can be assigned to user.
        </span>
      </div>
      <div class="col-md-6 mb-3" class="has-error" *ngIf="isDeliveryOwnerSelected">
        <span>
          <i class="material-icons">warning</i> Either 'Delivery Owner' or 'Restricted Admin' role can be assigned to
          user.
        </span>
      </div>
      <div class="col-md-6 mb-3" class="has-error" *ngIf="isDeliveryLeaderSelected">
        <span>
          <i class="material-icons">warning</i> Either 'Delivery Leader' or 'Restricted Admin' role can be assigned to
          user.
        </span>
      </div>
      <div class="col-md-6 mb-3" class="has-error" *ngIf="isRelationshipManagerSelected">
        <span>
          <i class="material-icons">warning</i> Either 'Relationship Manager' or 'Restricted Admin' role can be assigned
          to user.
        </span>
      </div>
      <div class="col-md-6 mb-3" class="has-error" *ngIf="isSalesExecutiveSelected">
        <span>
          <i class="material-icons">warning</i> Either 'Sales Executive' or 'Restricted Admin' role can be assigned to
          user.
        </span>
      </div>
      <div class="col-md-6 mb-3" class="has-error" *ngIf="isAccountTeamSelected">
        <span>
          <i class="material-icons">warning</i> Either 'Account Team' or 'Restricted Admin' role can be assigned to
          user.
        </span>
      </div>
      <div class="col-md-6 mb-3" class="has-error" *ngIf="isRestrictedAdminRoleSelected">
        <span>
          <i class="material-icons">warning</i> Either 'Restricted Admin' or 'Restricted Admin - Read Only' role can
          be assigned to user.
        </span>
      </div>
      <div class="col-md-6 mb-3" class="has-error" *ngIf="baseProductHasError">
        <span *ngIf="userForm.get('userType').value === 'Client'">
          <i class="material-icons">warning</i> Please assign at least one product.
        </span>
        <span *ngIf="userForm.get('userType').value !== 'Client'">
          <i class="material-icons">warning</i> Please assign at least one product in base org section.
        </span>
      </div>
      <div class="col-md-6 mb-3" class="has-error" *ngIf="groupHasError">
        <span *ngIf="userForm.get('userType').value === 'Client'">
          <i class="material-icons">warning</i> Please select at least one group.
        </span>
        <span *ngIf="userForm.get('userType').value !== 'Client'">
          <i class="material-icons">warning</i> Please select at least one group in base org section.
        </span>
      </div>
      <div class="col-md-6 mb-3" class="has-error" *ngIf="crossOrganizationHasError">
        <span>
          <i class="material-icons">warning</i> Please complete cross organization section.
        </span>
      </div>
      <!-- Nav tabs -->
      <!--<ul class="nav nav-tabs d-flex" *ngIf="isAdmin && userForm.get('userType').value === 'Fiserv'">
        <li>
          <a href="#tab1" data-toggle="tab" class="active show">Base Org</a>
        </li>
        <li>
          <a href="#tab2" data-toggle="tab" class="">Cross Org</a>
        </li>-->
        <!-- <li>
          <a href="#tab3" data-toggle="tab" class="">Hierarchy/Blanket</a>
        </li> -->
      <!--</ul>-->

      <!-- Tab panes -->
      <div class="tab-content" id="roleMainDiv">
        <div class="tab-pane fade active show" id="tab1">
          <!-- <fieldset id="configRole1" class="role-content-area">
            <legend class="border-0"></legend>
            <div class="checkbox" formArrayName="roles"
              *ngFor="let control of roleForm.controls.roles['controls']; let i = index">
              <input type="checkbox" [formControlName]="i" id="{{'chk'+ i}}" (change)="onRolesChange($event, i)" />
              <label for="{{'chk'+ i}}"><span>{{roles[i].title}}</span></label>
            </div>
          </fieldset> -->
          <div class="row">
            <div class="col-md-12 mb-3 typeahead-wrapper">
              <label for="searchTags">Search Multiple Role to Assign</label>
              <div class="custom-add-tag">
                <ng-select [items]="basicDropdownTypeRoleList" [multiple]="true" [closeOnSelect]="false"
                  [appendTo]="'.mainDrawer'" [virtualScroll]="true" [clearOnBackspace]="false"
                  [(ngModel)]="selectedBaseRoles" [ngModelOptions]="{standalone: true}" (change)="onBaseRoleChanged()"
                  placeholder="Type to search" bindLabel="name">
                  <ng-template ng-option-tmp let-itemTemp="item" let-item$="item$" let-index="index">
                    <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" />
                    {{itemTemp.name}}
                  </ng-template>
                  <ng-template ng-multi-label-tmp>
                    <span class="ng-value-label">{{selectedBaseRoles.length}}
                      Item{{selectedBaseRoles.length > 1 ? 's' : ''}}</span>
                  </ng-template>
                </ng-select>
              </div>
            </div>
          </div>
          <div class="form-row mb-3" *ngIf="selectedBaseRoles.length > 0">
            <div class="col-md-12 m">
              <div class="tag-wrapper">
                <div class="filter-wrapper">
                  <div class="filter-tag" *ngFor="let selectedRole of selectedBaseRoles">
                    <div data-toggle="tooltip" data-placement="top" class="tag" title="{{selectedRole.name}}">
                      {{selectedRole.name}}
                      <a href="javascript:void(0)" (click)="onBaseRoleDeleted(selectedRole.id)" aria-label="Close"
                        class="tag-close">
                        <i tabindex="0" aria-hidden="true" class="material-icons">close</i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--<div class="row">
            <div class="col-md-12 mb-3 typeahead-wrapper">
              <label for="searchTags">Search Multiple Product to Assign</label>
              <div class="custom-add-tag">
                <ng-select [items]="productSuggestions" [multiple]="true" [closeOnSelect]="false" [virtualScroll]="true"
                  [appendTo]="'.mainDrawer'" [clearOnBackspace]="false" [(ngModel)]="selectedBaseProducts"
                  [ngModelOptions]="{standalone: true}" (search)="onSearchChange($event.term)"
                  (change)="onBaseProductChanged($event)" placeholder="Type to search" bindLabel="title">
                  <ng-template ng-option-tmp let-itemTemp="item" let-item$="item$" let-index="index">
                    <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" />
                    {{itemTemp.title}}
                  </ng-template>
                  <ng-template ng-multi-label-tmp>
                    <span class="ng-value-label">{{selectedBaseProducts.length}}
                      Item{{selectedBaseProducts.length > 1 ? 's' : ''}}</span>
                  </ng-template>
                </ng-select>
              </div>
            </div>
          </div>-->
          <div class="form-row mb-3" *ngIf="selectedBaseProducts.length > 0">
            <div class="col-md-12 m">
              <div class="tag-wrapper">
                <div class="filter-wrapper">
                  <div class="filter-tag" *ngFor="let productSuggestion of selectedBaseProducts">
                    <div data-toggle="tooltip" data-placement="top" class="tag" title="{{productSuggestion.title}}">
                      {{productSuggestion.title}}
                      <a href="javascript:void(0)" (click)="onBaseProductDeleted(productSuggestion.id)"
                        aria-label="Close" class="tag-close">
                        <i tabindex="0" aria-hidden="true" class="material-icons">close</i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="isRestrictedAdminRoleForBaseGroup()">
            <div class="col-md-12 mb-3 typeahead-wrapper">
              <label for="searchTags">Search Multiple Group to Assign</label>
              <div class="custom-add-tag">
                <ng-select [items]="groupSuggestions" [multiple]="true" [closeOnSelect]="false" [virtualScroll]="true"
                  [appendTo]="'.mainDrawer'" [clearOnBackspace]="false" [(ngModel)]="selectedBaseGroups"
                  [ngModelOptions]="{standalone: true}" (search)="onGroupSearchChange($event.term)"
                  (change)="onBaseGroupChanged($event)" placeholder="Type to search" bindLabel="name">
                  <ng-template ng-option-tmp let-itemTemp="item" let-item$="item$" let-index="index">
                    <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" />
                    {{itemTemp.name}}
                  </ng-template>
                  <ng-template ng-multi-label-tmp>
                    <span class="ng-value-label">{{selectedBaseGroups.length}}
                      Item{{selectedBaseGroups.length > 1 ? 's' : ''}}</span>
                  </ng-template>
                </ng-select>
              </div>
            </div>
          </div>
          <div class="form-row mb-3" *ngIf="isRestrictedAdminRoleForBaseGroup() && selectedBaseGroups.length > 0">
            <div class="col-md-12 m">
              <div class="tag-wrapper">
                <div class="filter-wrapper">
                  <div class="filter-tag" *ngFor="let groupSuggestion of selectedBaseGroups">
                    <div data-toggle="tooltip" data-placement="top" class="tag" title="{{groupSuggestion.name}}">
                      {{groupSuggestion.name}}
                      <a href="javascript:void(0)" (click)="onBaseGroupDeleted(groupSuggestion.id)" aria-label="Close"
                        class="tag-close">
                        <i tabindex="0" aria-hidden="true" class="material-icons">close</i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="tab2">
          <fieldset id="configRole2" class="role-content-area">
            <div class="box crossOrganizationBox" *ngFor="let item of crossOrganizations; let i = index">
              <h5 class="h5 heading-level-3 mb-2 mt-0">Organization Set {{i + 1}}</h5>
              <button type="button" class="close" (click)="deleteCrossOrganization(item)">×</button>

              <div class="col-md-6 mb-3" class="has-error" *ngIf="item.isRespondentDeselected">
                <span *ngIf="!isCreateNewUserFromDelegateScreen">
                  <i class="material-icons">warning</i> Respondent Role is required for the User to have an Intake Form
                  Submitter role.
                </span>
                <span *ngIf="isCreateNewUserFromDelegateScreen">
                  <i class="material-icons">warning</i> Respondent Role is required for the User to have an Delegate
                  Form role.
                </span>
              </div>
              <div class="col-md-6 mb-3" class="has-error" *ngIf="item.isAdminRoleSelected">
                <span>
                  <i class="material-icons">warning</i> Either 'Admin' or 'Restricted Admin' role can be assigned to
                  user.
                </span>
              </div>
              <div class="col-md-6 mb-3" class="has-error" *ngIf="item.isDivisionLeaderSelected">
                <span>
                  <i class="material-icons">warning</i> Either 'Division Leader' or 'Restricted Admin' role can be
                  assigned to user.
                </span>
              </div>
              <div class="col-md-6 mb-3" class="has-error" *ngIf="item.isBuLeaderSelected">
                <span>
                  <i class="material-icons">warning</i> Either 'BU Leader' or 'Restricted Admin' role can be assigned to
                  user.
                </span>
              </div>
              <div class="col-md-6 mb-3" class="has-error" *ngIf="item.isDeliveryOwnerSelected">
                <span>
                  <i class="material-icons">warning</i> Either 'Delivery Owner' or 'Restricted Admin' role can be
                  assigned to user.
                </span>
              </div>
              <div class="col-md-6 mb-3" class="has-error" *ngIf="item.isDeliveryLeaderSelected">
                <span>
                  <i class="material-icons">warning</i> Either 'Delivery Leader' or 'Restricted Admin' role can be
                  assigned to user.
                </span>
              </div>
              <div class="col-md-6 mb-3" class="has-error" *ngIf="item.isRelationshipManagerSelected">
                <span>
                  <i class="material-icons">warning</i> Either 'Relationship Manager' or 'Restricted Admin' role can be
                  assigned to user.
                </span>
              </div>
              <div class="col-md-6 mb-3" class="has-error" *ngIf="item.isSalesExecutiveSelected">
                <span>
                  <i class="material-icons">warning</i> Either 'Sales Executive' or 'Restricted Admin' role can be
                  assigned to user.
                </span>
              </div>
              <div class="col-md-6 mb-3" class="has-error" *ngIf="item.isAccountTeamSelected">
                <span>
                  <i class="material-icons">warning</i> Either 'Account Team' or 'Restricted Admin' role can be assigned
                  to user.
                </span>
              </div>
              <div class="col-md-6 mb-3" class="has-error" *ngIf="item.isRestrictedAdminRoleSelected">
                <span>
                  <i class="material-icons">warning</i> Either 'Restricted Admin' or 'Restricted Admin - Read Only' role
                  can be assigned to user.
                </span>
              </div>

              <div class="row">
                <div class="col-md-12 mb-3 typeahead-wrapper">
                  <label for="organization">Select Organization:</label>
                  <div class="custom-search-input" [ngClass]="{'has-error' : item.crossOrganizationHasError}">
                    <fdl-type-ahead [(ngModel)]="item.name" [ngModelOptions]="{standalone: true}"
                      [collection]="organizationSuggestionEdited" optionField="title" placeholder="Type to search"
                      [scrollable]="true" [optionsLimit]="500" [minLength]="0"
                      (onSelect)="onOrganizationSelect($event, item)"
                      (ngModelChange)="onOrganizationChanged(item)"></fdl-type-ahead>
                  </div>
                  <small class="has-error" *ngIf="item.crossOrganizationHasError">Please select an
                    organization.</small>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 mb-3 typeahead-wrapper">
                  <label for="searchTags">Search Multiple Role to Assign</label>
                  <div class="custom-add-tag">
                    <ng-select [items]="basicDropdownTypeRoleList" [multiple]="true" [closeOnSelect]="false"
                      [appendTo]="'.mainDrawer'" [virtualScroll]="true" [clearOnBackspace]="false"
                      [(ngModel)]="item.roles" [ngModelOptions]="{standalone: true}"
                      (change)="onOrganizationsRoleSelect(item)" placeholder="Type to search" bindLabel="name">
                      <ng-template ng-option-tmp let-itemTemp="item" let-item$="item$" let-index="index">
                        <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" />
                        {{itemTemp.name}}
                      </ng-template>
                      <ng-template ng-multi-label-tmp>
                        <span class="ng-value-label">{{item.roles.length}}
                          Item{{item.roles.length > 1 ? 's' : ''}}</span>
                      </ng-template>
                    </ng-select>
                  </div>
                  <small class="has-error" *ngIf="item.crossOrganizationRoleHasError && item.roles.length === 0">Please
                    select at least one role.</small>
                </div>
              </div>
              <div class="form-row mb-3" *ngIf="item.roles.length > 0">
                <div class="col-md-12 m">
                  <div class="tag-wrapper"
                    [ngClass]="{'has-error' : item.crossOrganizationRoleHasError && item.roles.length === 0}">
                    <div class="filter-wrapper">
                      <div class="filter-tag" *ngFor="let crossOrganizationRole of item.roles">
                        <div data-toggle="tooltip" data-placement="top" class="tag"
                          title="{{crossOrganizationRole.name}}">
                          {{crossOrganizationRole.name}}
                          <a href="javascript:void(0)" (click)="deleteOrganizationRole(crossOrganizationRole.id, item)"
                            aria-label="Close" class="tag-close">
                            <i tabindex="0" aria-hidden="true" class="material-icons">close</i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 mb-3 typeahead-wrapper">
                  <label for="searchTags">Search Multiple Product to Assign</label>
                  <div class="custom-add-tag">
                    <ng-select [items]="item.productsToSelect" [multiple]="true" [closeOnSelect]="false"
                      [appendTo]="'.mainDrawer'" [virtualScroll]="true" [clearOnBackspace]="false"
                      [(ngModel)]="item.products" [ngModelOptions]="{standalone: true}"
                      (search)="onProductChanges(item, $event.term)" placeholder="Type to search" bindLabel="name">
                      <ng-template ng-option-tmp let-itemTemp="item" let-item$="item$" let-index="index">
                        <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" />
                        {{itemTemp.name}}
                      </ng-template>
                      <ng-template ng-multi-label-tmp>
                        <span class="ng-value-label">{{item.products.length}}
                          Item{{item.products.length > 1 ? 's' : ''}}</span>
                      </ng-template>
                    </ng-select>
                  </div>
                  <small class="has-error"
                    *ngIf="item.crossOrganizationProductHasError && item.products.length === 0">Please select at least
                    one product.</small>
                </div>
              </div>
              <div class="form-row mb-3" *ngIf="item.products.length > 0">
                <div class="col-md-12 m">
                  <div class="tag-wrapper"
                    [ngClass]="{'has-error' : item.crossOrganizationProductHasError && item.products.length === 0}">
                    <div class="filter-wrapper">
                      <div class="filter-tag" *ngFor="let crossOrganizationProduct of item.products">
                        <div data-toggle="tooltip" data-placement="top" class="tag"
                          title="{{crossOrganizationProduct.name}}">
                          {{crossOrganizationProduct.name}}
                          <a href="javascript:void(0)"
                            (click)="deleteOrganizationProduct(crossOrganizationProduct.id, item)" aria-label="Close"
                            class="tag-close">
                            <i tabindex="0" aria-hidden="true" class="material-icons">close</i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="doesRoleNeedGroups(item.roles)">
                <div class="col-md-12 mb-3 typeahead-wrapper">
                  <label for="searchTags">Search Multiple Group to Assign</label>
                  <div class="custom-add-tag">
                    <ng-select [items]="item.groupsToSelect" [multiple]="true" [closeOnSelect]="false"
                      [appendTo]="'.mainDrawer'" [virtualScroll]="true" [clearOnBackspace]="false"
                      [(ngModel)]="item.groups" [ngModelOptions]="{standalone: true}"
                      (search)="onGroupChanges(item, $event.term)" placeholder="Type to search" bindLabel="name">
                      <ng-template ng-option-tmp let-itemTemp="item" let-item$="item$" let-index="index">
                        <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" />
                        {{itemTemp.name}}
                      </ng-template>
                      <ng-template ng-multi-label-tmp>
                        <span class="ng-value-label">{{item.groups.length}}
                          Item{{item.groups.length > 1 ? 's' : ''}}</span>
                      </ng-template>
                    </ng-select>
                  </div>
                  <small class="has-error"
                    *ngIf="item.crossOrganizationGroupHasError && item.groups.length === 0">Please select at least one
                    group.</small>
                </div>
              </div>
              <div class="form-row mb-3" *ngIf="doesRoleNeedGroups(item.roles) && item.groups.length > 0">
                <div class="col-md-12 m">
                  <div class="tag-wrapper"
                    [ngClass]="{'has-error' : item.crossOrganizationGroupHasError && item.groups.length === 0}">
                    <div class="filter-wrapper">
                      <div class="filter-tag" *ngFor="let crossOrganizationGroup of item.groups">
                        <div data-toggle="tooltip" data-placement="top" class="tag"
                          title="{{crossOrganizationGroup.name}}">
                          {{crossOrganizationGroup.name}}
                          <a href="javascript:void(0)"
                            (click)="deleteOrganizationGroup(crossOrganizationGroup.id, item)" aria-label="Close"
                            class="tag-close">
                            <i tabindex="0" aria-hidden="true" class="material-icons">close</i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end mt-2 mr-3">
              <button tabindex="0" class="btn fdl-btn btn-primary-dark" (click)="addCrossOrganization()"><span
                  class="material-icons"> add
                </span>Add</button>
            </div>
          </fieldset>
        </div>
        <!-- <div class="tab-pane fade" id="tab3">
          <fieldset id="configRole3" class="role-content-area">
            <div class="checkbox">
              <input type="checkbox" id="showHierarchyDateReadOnly" name="showHierarchyDateReadOnly"
                [(ngModel)]="showHierarchyDateReadOnly" [ngModelOptions]="{standalone: true}" /><label
                for="showHierarchyDateReadOnly"><span _ngcontent-fbv-c382="">Show Hierarchy Date - Read
                  Only</span></label>
            </div>

            <div class="checkbox">
              <input type="checkbox" id="showHierarchyDateReadWrite" name="showHierarchyDateReadWrite"
                [(ngModel)]="showHierarchyDateReadWrite" [ngModelOptions]="{standalone: true}" /><label
                for="showHierarchyDateReadWrite"><span _ngcontent-fbv-c382="">Show Hierarchy Date - Read,
                  Write</span></label>
            </div>
          </fieldset>
        </div> -->
      </div>
      <div class=" pt-3">
        <div class="fdl-2 button-container">
          <button class="btn fdl-btn btn-secondary-light " type="button" (click)="drawerCancel()">Cancel</button>
          <div>
            <button type="button" (click)="drawerSubmit()"
              class="btn fdl-btn btn-primary-orange float-right">Assign</button>
          </div>
        </div>
      </div>
    </form>
    <!-- <h3>Select Roles to Assign</h3>
    <p>Below are the available roles to assign user. You can select multiple roles for a single user.</p>
    <form [formGroup]="roleForm">
      <fieldset>
        <legend class="border-0"></legend>
        <div class="checkbox" formArrayName="roles"
          *ngFor="let control of roleForm.controls.roles['controls']; let i = index">
          <input type="checkbox" [formControlName]="i" id="{{'chk'+ i}}" (change)="onRolesChange($event, i)" />
          <label for="{{'chk'+ i}}"><span>{{roles[i].title}}</span></label>
        </div>
      </fieldset>
      <div class="col-md-6 mb-3" class="has-error" *ngIf="isRespondentDeselected">
        <span *ngIf="!isCreateNewUserFromDelegateScreen">
          <i class="material-icons">warning</i> Respondent Role is required for the User to have an Intake Form
          Submitter role.
        </span>
        <span *ngIf="isCreateNewUserFromDelegateScreen">
          <i class="material-icons">warning</i> Respondent Role is required for the User to have an Delegate Form role.
        </span>
      </div>
      <div class="col-md-6 mb-3" class="has-error" *ngIf="roleForm.invalid && (roleForm.dirty || isRoleFormSubmitted)">
        <span>
          <i class="material-icons">warning</i> Please assign at least one role.
        </span>
      </div>
      <div class="col-md-6 mb-3" class="has-error" *ngIf="isAdminRoleSelected">
        <span>
          <i class="material-icons">warning</i> Either 'Admin' or 'Restricted Admin' role can be assigned to user.
        </span>
      </div>
      <div class="col-md-6 mb-3" class="has-error" *ngIf="isRestrictedAdminRoleSelected">
        <span>
          <i class="material-icons">warning</i> Either 'Restricted Admin' or 'Restricted Admin - Read Only' role can be
          assigned to user.
        </span>
      </div>
      <div class=" pt-3">
        <div class="fdl-2 button-container">

          <button class="btn fdl-btn btn-secondary-light " type="button" (click)="drawerCancel()">Cancel</button>
          <div>
            <button type="button" (click)="drawerSubmit()"
              class="btn fdl-btn btn-primary-orange float-right">Assign</button>
          </div>
        </div>
      </div>
    </form> -->
  </div>


  <div class="drawer-contents col-xs-12 col-sm-12 col-md-12" *ngIf="showChannelPartnerDrawer">
    <h3>Select Clients to Assign</h3>
    <p>Below are the available clients to assign user. You can select multiple clients for a single user.</p>

    <div id="clientMainDiv">
      <div class="row">
        <div class="input-group search-box mb-3 col-md-11">
          <input type="text" class="form-control" placeholder="Search..." id="searchChannelPartnerString"
            name="searchChannelPartnerString" [(ngModel)]="searchChannelPartnerString"
            (input)="onClientSearchChange($event.target.value)">
        </div>
        <!-- <div class="d-flex">
        <div class="refresh-icon-link mt-1">
          <a title="Reset Client List" id="refreshTask" (click)="resetClientList()">
            <i aria-hidden="true" class="fa fa-refresh"></i>
          </a>
        </div>
      </div> -->
      </div>
      <fieldset>
        <legend class="border-0"></legend>
        <div class="checkbox"
          *ngFor="let channelPartner of channelPartnerSuggestions | filter: 'titleWithDuns': searchChannelPartnerString ; let i = index">
          <div class="position-relative">
            <input type="checkbox" [(ngModel)]="channelPartner.selected" id="{{'chk'+ i}}" />
            <label for="{{'chk'+ i}}"><span>{{channelPartner.titleWithDuns}}</span></label>
          </div>
        </div>

      </fieldset>
      <p *ngIf="channelPartnerSuggestions.length == 0">No records available.</p>
      <div class="pt-3" *ngIf="(isChannelPartnerFormInvalid)">
        <div class="col-md-6 mb-3" class="has-error">
          <span>
            <i class="material-icons">warning</i> {{CHANNEL_PARTNER_EMPTY}}
          </span>
        </div>
      </div>
    </div>
    <div class="pt-3">
      <div class="fdl-2 button-container">
        <button class="btn fdl-btn btn-secondary-light " (click)="drawerChannelPartnerCancel()">Cancel</button>
        <button type="button" (click)="drawerChannelPartnerSubmit()"
          class="btn fdl-btn btn-primary-orange float-right">Assign</button>
      </div>
    </div>
  </div>

  <div class="drawer-contents col-xs-12 col-sm-12 col-md-12" *ngIf="showGroupDrawer">
    <h3>Select Groups to Assign</h3>
    <p>Below are the available groups to assign to a user. You can select multiple groups for a single user.</p>
    <div class="row">
      <div class="input-group search-box mb-3 col-md-11">
        <input type="text" class="form-control" placeholder="Search..." id="searchGroupString" name="searchGroupString"
          [(ngModel)]="searchGroupString" (input)="onGroupSearchChange($event.target.value)">
      </div>
    </div>
    <fieldset>
      <legend class="border-0"></legend>
      <div class="checkbox" *ngFor="let group of groupSuggestions | filter: 'name': searchGroupString ; let i = index">
        <div class="position-relative">
          <input type="checkbox" [(ngModel)]="group.selected" id="{{'chk'+ i}}" />
          <label for="{{'chk'+ i}}"><span>{{group.name}}</span></label>
        </div>
      </div>
    </fieldset>
    <p *ngIf="groupSuggestions.length == 0">No records available.</p>
    <div class=" pt-3">
      <div class="fdl-2 button-container">
        <button class="btn fdl-btn btn-secondary-light " (click)="drawerGroupCancel()">Cancel</button>
        <div> <button type="button" (click)="drawerGroupSubmit()"
            class="btn fdl-btn btn-primary-orange float-right">Assign</button></div>
      </div>
    </div>
  </div>

  <div class="drawer-contents col-xs-12 col-sm-12 col-md-12" *ngIf="showPageTemplateDrawer">
    <h3>Select Page Templates</h3>
    <p>Below are the available page template. You can select multiple page template for a single user.</p>
    <div class="row">
      <div class="col-md-10">
        <input type="search" class="form-control" [(ngModel)]="pageTemplateQueryParam.title" id="PageTemplateTitle"
          name="PageTemplateTitle">
      </div>
      <div class="col-md-2">
        <button id="searchButton" type="button" aria-label="Search" class="btn fdl-btn btn-primary-orange float-right"
          (click)="loadPageTemplates()">
          Search
        </button>
      </div>
    </div>
    <hr />
    <form>
      <div id="pageTemplateMainDiv">
        <div class="form-row">
          <div class="col-md-11 mb-3">
            <table class="f-12 config-permission-table table editable-table table-hover table-label">
              <thead>
                <tr role="row">
                  <th role="columnheader" scope="col"><span class="t-heading">Configure Permissions </span></th>
                  <!-- <th role="columnheader" scope="col"></th> -->
                  <th role="columnheader" scope="col"></th>
                  <th role="columnheader" scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr role="row">
                  <td role="cell">
                    <label data-toggle="tooltip" data-placement="top"><strong>Page Template</strong></label>
                  </td>
                  <!-- <td role="cell">
                    <div class="checkbox">
                      <input type="checkbox" id="viewCheckbox" name="viewCheckbox"
                        (change)="onCheckboxStateChanged(viewCheckbox, pageTemplateAccessType.View)"
                        [(ngModel)]="viewCheckbox" />
                      <label for="viewCheckbox" class="inline-checkbox">View</label>
                    </div>
                  </td> -->
                  <td role="cell">
                    <div class="checkbox">
                      <input type="checkbox" id="readAllCheckbox" name="readAllCheckbox"
                        (change)="onCheckboxStateChanged(readAllCheckbox, pageTemplateAccessType.ReadAll)"
                        [(ngModel)]="readAllCheckbox" />
                      <label for="readAllCheckbox" class="inline-checkbox">Read All</label>
                    </div>
                  </td>
                  <td role="cell">
                    <div class="checkbox">
                      <input type="checkbox" id="adminCheckbox" name="adminCheckbox"
                        (change)="onCheckboxStateChanged(adminCheckbox, pageTemplateAccessType.Admin)"
                        [(ngModel)]="adminCheckbox" />
                      <label for="adminCheckbox" class="inline-checkbox">Admin</label>
                    </div>
                  </td>
                </tr>
                <tr role="row" *ngFor="let item of pageTemplateList.pageTemplates">
                  <td role="cell"><label for="myCheckboxZero" data-toggle="tooltip"
                      data-placement="top">{{item.title}}</label></td>
                  <!-- <td role="cell">
                    <div class="fdl-switch fdl-2">
                      <input id="switchView{{item.id}}" name="switchView{{item.id}}" type="checkbox"
                        (change)="onPageTemplateCheckboxChanged(item.id, item.title, pageTemplateAccessType.View)"
                        [checked]="pageTemplateCheckboxCheckedStatus(item.id, pageTemplateAccessType.View)" />
                      <label for="switchView{{item.id}}">
                        <span class="onoffswitch"></span>
                      </label>
                    </div>
                  </td> -->
                  <td role="cell">
                    <div class="fdl-switch fdl-2">
                      <input id="switchReadAll{{item.id}}" name="switchReadAll{{item.id}}" type="checkbox"
                        [disabled]="pageTemplateReadDisable(item.id)"
                        (change)="onPageTemplateCheckboxChanged(item.id, item.title, pageTemplateAccessType.ReadAll)"
                        [checked]="pageTemplateCheckboxCheckedStatus(item.id, pageTemplateAccessType.ReadAll)" />
                      <label for="switchReadAll{{item.id}}">
                        <span class="onoffswitch"></span>
                      </label>
                    </div>
                  </td>
                  <td role="cell">
                    <div class="fdl-switch fdl-2">
                      <input id="switchAdmin{{item.id}}" name="switchAdmin{{item.id}}" type="checkbox"
                        (change)="onPageTemplateCheckboxChanged(item.id, item.title, pageTemplateAccessType.Admin)"
                        [checked]="pageTemplateCheckboxCheckedStatus(item.id, pageTemplateAccessType.Admin)" />
                      <label for="switchAdmin{{item.id}}">
                        <span class="onoffswitch"></span>
                      </label>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <app-pagination *ngIf="pagerObjectForPageTemplate && pagerObjectForPageTemplate.totalPages > 1 && 
        pageTemplateList && pageTemplateList.pageTemplates.length > 0" [pagerObject]="pagerObjectForPageTemplate"
        (pagingChanged)="pageTemplateChangePage($event)">
      </app-pagination>

      <div class="pt-3">
        <div class="fdl-2 button-container">
          <button type="button" class="btn fdl-btn btn-secondary-light"
            (click)="drawerPageTemplateCancel()">Cancel</button>
          <div><button type="button" class="btn fdl-btn btn-primary-orange float-right"
              (click)="drawerPageTemplateSubmit()">Submit</button></div>
        </div>
      </div>
    </form>
  </div>
</div>

<!-- Select Products to Assign Drawer Start -->
<div class="drawer drawer-right dw-xs-12 dw-sm-8 dw-md-6 fold open" id="selectProductsToAssign" tabindex="-1"
  aria-labelledby="addNewAccount" aria-expanded="true" role="region" *ngIf="showProductDrawer && showDrawer">



  <div class="drawer-contents col-xs-12 col-sm-12 col-md-12">
    <h3>Select Products to Assign</h3>
    <p>Below are the available products to assign user. You can select multiple products for a single user.</p>
    <div class="row" [ngClass]="{'has-error': !isSearchProductValid(searchProductString) }">
      <div class="input-group search-box mb-3 col-md-11">
        <input type="text" class="form-control" placeholder="Search..." id="searchProductString"
          name="searchProductString" [(ngModel)]="searchProductString" (input)="onSearchChange($event.target.value)">
      </div>
      <span class="pl-3" *ngIf="!isSearchProductValid(searchProductString)">
        <i class="material-icons">warning</i> {{ regexValidationMessage.NAME_VALIDATION_MESSAGE }} </span>

      <!-- <div class="d-flex">
        <div class="refresh-icon-link mt-1">
          <a title="Reset Product List" id="refreshTask" (click)="resetProductList()">
            <i aria-hidden="true" class="fa fa-refresh"></i>
          </a>
        </div>
      </div> -->
    </div>
    <fieldset>
      <legend class="border-0"></legend>
      <div class="checkbox"
        *ngFor="let product of productSuggestions | filter: 'title': searchProductString ; let i = index">
        <div class="position-relative">
          <input type="checkbox" [(ngModel)]="product.selected" id="{{'chk'+ i}}" />
          <label class="pl-3" for="{{'chk'+ i}}"><span>{{product.title}}</span></label>
        </div>
      </div>
    </fieldset>
    <p *ngIf="productSuggestions.length == 0">No records available.</p>
    <div class=" pt-3">
      <div class="fdl-2 button-container">
        <button class="btn fdl-btn btn-secondary-light " (click)="drawerProductCancel()">Cancel</button>
        <div> <button type="button" (click)="drawerProductSubmit()"
            class="btn fdl-btn btn-primary-orange float-right">Assign</button></div>
      </div>
    </div>
  </div>
</div>
<div class="backdrop-space" *ngIf="showDrawer">
  <div class="backdrop"></div>
</div>
<!-- Drawer End -->

<!-- AD Error Drawer Starts -->
<div *ngIf="ADError">
  <div id="errorADDialog" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog"
    aria-labelledby="myLargeModalLabel" class="modal show fade bd-example-modal-md"
    style="display: block; padding-top: 20px;" aria-hidden="true">
    <div role="document" class="modal-dialog modal-md" style="max-width: 700px; padding-top:200px;">
      <div class="modal-content">
        <div class="modal-header">
          <h4 id="myLargeModalLabel" class="modal-title">
            <span>User doesn't exists in AD</span>
          </h4>
        </div>

        <div class="modal-body">
          <p>
            No User exists in Active Directory with <u>{{userEmail}}</u>, please verify Email ID or continue without
            AD
            Mapping.
          </p>
        </div>

        <div class="modal-footer fdl-2">
          <button type="button" class="btn fdl-btn btn-secondary-light" (click)="closeMessage()">
            <span>Continue</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

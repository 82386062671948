import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { LoggingService } from './logging.service';
import { Injectable } from '@angular/core';
import { ValidationErrorHandler } from 'src/app/shared/validation-error-handler';
import { MasterService } from './master.service';
import { CacheRegistrationService } from '../SharedModule/services/cache-registration.service ';
import { REGEX_PATTERNS } from '../core/constants';
import { Router } from '@angular/router';
import { SurveyService } from './survey.service';
import { AuthenticationService } from '../SharedModule/services/authentication.service';

@Injectable()
export class InterceptorService implements HttpInterceptor {
  constructor(private _loggingService: LoggingService,
    private surveyService: SurveyService,
    private _cacheRegistrationService: CacheRegistrationService,
    private authenticationService: AuthenticationService,
    private masterservice: MasterService,
    private router: Router) { }

  eTag: string = null;
  isrefresh: boolean = false;
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.method === 'GET') {
      this.eTag = this._cacheRegistrationService.get(request.urlWithParams) ? this._cacheRegistrationService.get(request.urlWithParams)['eTag'] : null;
      if(request.headers.get('refresh') != undefined && request.headers.get('refresh') != null  && request.headers.get('refresh') == 'true') {
        this.isrefresh = true;
      } else {
        this.isrefresh = false;
      }
      if (this.eTag && !this.isrefresh) {
        request = request.clone({
          headers: request.headers.set('If-None-Match', this.eTag)
        })
      }
      else {
        request = request.clone({
          headers: request.headers.set('If-None-Match', '0000000000000000')
        })
      }
    }

    return next.handle(request).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
            if (request.method === "GET" && event.status === 200 && this.allowCaching(request.urlWithParams)) {
              this._cacheRegistrationService.put(request.urlWithParams, event.headers.get('ETag'), event.clone());
            }
          }
        }),
        catchError((error: HttpErrorResponse) => {
          let errMsg = '';
          let logException = '';
          if (error.error instanceof ErrorEvent) {
            errMsg = `Error: ${error.error.message}`;
          }
          else {
            errMsg = `Error Code: ${error.status},  Message: ${error.message}`;
          }

          if (error.status === 304) {
            if (this._cacheRegistrationService.get(request.urlWithParams) && this._cacheRegistrationService.get(request.urlWithParams)['response'])
              return of(this._cacheRegistrationService.get(request.urlWithParams)['response']) as Observable<HttpEvent<any>>;
          }
          if (error.status === 401) {
            if (this.masterservice.getGuid()) {
              sessionStorage.removeItem("surevyToken");
              this.router.navigate(['survey', this.masterservice.getGuid(), 'generate-otp']);
            }
          }
          if (error.status === 403) {
            if (this.masterservice.getGuid()) {
              sessionStorage.removeItem("surevyToken");
              this.router.navigate(['survey', this.masterservice.getGuid(), 'generate-otp']);
            }

            this.authenticationService.logoutAccessDeniedUser();
          }
          if (error.status === 404) {
            this.surveyService.setExpiredSurvey("expired");
          }
          if (error.status === 422) {
            errMsg = ValidationErrorHandler.handleValidationErrors(error.error);
          } else {
            errMsg = "Something went wrong. Please contact <a href='mailto:drms-implementation360@fiserv.com'>System Administrator</a>";
          }
          logException = "Error Code- " + error.status + " Error Message- " + errMsg;

          this._loggingService.logException(new Error(logException));
          return throwError(errMsg);
      })
    )
  }

  private allowCaching(urlWithParams) {
    let result = true;
    if (urlWithParams.match(REGEX_PATTERNS.SkipCachingUrls))
      result = false;
    else if (urlWithParams.indexOf("survey") > -1)
      result = false;
    return result;
  }
}   

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
declare var $: any;

@Component({
  selector: 'delete-warning',
  templateUrl: './delete-warning.component.html',
  styleUrls: ['./delete-warning.component.css']
})
export class DeleteWarningComponent implements OnInit {
  @Output() okBtnClicked = new EventEmitter<{ action: string }>();
  @Input() type: string = '';
  @Input() componentId: string = '';
  @Input() clientDashboard: boolean = false;

  targetDeletableRow: any = {};
  constructor() { }

  ngOnInit(): void {
  }

  ok() {
    this.okBtnClicked.emit({ action: "continue" });
    this.cancel();
  }

  cancel() {
    $("#".concat(this.componentId)).hide();
    $('.modal-backdrop').remove();
    $('body').addClass('modal-open');
    $("#".concat(this.componentId)).removeClass('show');
  }

  public keyDown(keyboardEvent: KeyboardEvent): void {
    const focusableElements =
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
    const modal = document.getElementById(this.componentId);
    const firstFocusableElement = modal.querySelectorAll(focusableElements)[0]; // get first element to be focused inside modal
    const focusableContent = modal.querySelectorAll(focusableElements);
    const lastFocusableElement = focusableContent[focusableContent.length - 1]; // get last element to be focused inside modal
    if (keyboardEvent.shiftKey) { // if shift key pressed for shift + tab combination
      if (document.activeElement === firstFocusableElement) {
        (lastFocusableElement as HTMLElement).focus(); // add focus for the last focusable element
        keyboardEvent.preventDefault();
      }
    } else { // if tab key is pressed
      if (document.activeElement === lastFocusableElement) { // if focused has reached to last focusable element then focus first focusable element after pressing tab
        (firstFocusableElement as HTMLElement).focus(); // add focus for the first focusable element
        keyboardEvent.preventDefault();
      }
    }
  }
}

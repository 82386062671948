import { Component } from '@angular/core';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-theme-switcher',
  templateUrl: './theme-switcher.component.html',
  styleUrls: ['./theme-switcher.component.css']
})
export class ThemeSwitcherComponent {

  switch = false;

  constructor(private userService: UserService) { }

  themeSwitcherChanged(): void {
    this.userService.setBlueThemeMode(this.switch);
  }

  keyDown(): void {
    this.switch = !this.switch;
    this.themeSwitcherChanged();
  }
}

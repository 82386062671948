import { Component, OnInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl } from "@angular/forms";
import { take } from "rxjs/operators";

import { ProjectsService } from "../../services/projects.service";
import { ISuggestions } from "../../shared/suggestion";
import { IPager } from "../../shared/pagination";
import { DashboardCommonLogic } from "../shared/dashboard-common-logic";
import { PermissionsService } from "../../services/permissions.service";
import { UserService } from "src/app/services/user.service";
import { ContentService } from "src/app/services/content.service";
import { IResponseStatusResloved, IStatus } from '../../shared/status';
import * as $ from 'jquery';
import { FavoritesService } from "src/app/services/favorites.service";
import { IFavorites } from "src/app/shared/favorite";
import { FavoriteCategory, FavoriteRespondentCategory, REGEX_VALIDATION_MESSAGE } from 'src/app/core/constants';
import { IQuery } from "src/app/shared/query";
import { FormsService } from "src/app/services/forms.service";
import { IForms } from "src/app/shared/form";
import moment from 'moment';
import { environment } from "src/environments/environment";
import { IUser, IUserLoginDetails } from "src/app/shared/user";
import { UserFilterService } from "src/app/services/user.filter.service";
import { EditFormDetailsComponent } from "src/app/SharedModule/Components/edit-form-details/edit-form-details.component";
import { PreferenceService } from "src/app/services/preference.service";
import { REGEX_PATTERNS } from 'src/app/core/constants';
import { Validators } from '@angular/forms';
import { FdlFilterUIHepler } from "src/app/shared/fdl-filter-ui-helper";
import { AuthenticationService } from "src/app/SharedModule/services/authentication.service";
declare var $: any;

@Component({
  selector: "app-dashboard-mydashboard",
  templateUrl: "./dashboard-mydashboard.component.html"
})
export class DashboardMyDashboardComponent extends DashboardCommonLogic
  implements OnInit {

  accessToken: any;
  userDetails: IUser;
  userloginDetails: IUserLoginDetails;
  favoriteProjectList: IFavorites = { favorites: [] };
  favoriteResponseList: IFavorites = { favorites: [] };
  projectSuggestion: ISuggestions;
  responsesSuggestion: ISuggestions;
  showProjectFilter: boolean = false;
  formStatus: IStatus[] = [];
  filterForm: FormGroup;
  filterExists: boolean = false;
  isAdmin: boolean = false;
  favCategory: any = 'Responses';
  myAssignCategory: any = 'All';
  queryParam: IQuery;
  currentPageNumber = 1;
  pagerObject: IPager;
  sortingObject;
  isError: boolean;
  errorMsg: string = "";
  successMsg: string = "";
  UserId: string;
  favcategories: any;
  today: any;
  isCreateFormAllowed: boolean = false;
  isEditFormAllowed: boolean = false;
  isDeleteFormAllowed: boolean = false;
  isViewHistoryAllowed: boolean = false;
  LastLoginTimeStamp: any;
  formList: IForms = { forms: [] };
  myAssignQueryParam: IQuery;
  myAssignPagerObject: IPager;
  myAssignSortingObject;
  myAssignCurrentPageNumber: number = 1;
  myAssignDisplayColumns = ['ID', 'Form Name', 'Project Name', 'Client Name', 'Status', 'Due Date'];
  myAssignKeys = ['id', 'title', 'projectTitle', 'clientTitle', 'responseStatusTitle', 'dueDate'];
  isViewTimelineAllowed: boolean = false;
  content: any = [];

  favResponseQueryParam: IQuery;
  favResponsePagerObject: IPager;
  favResponseSortingObject;
  favResponseCurrentPageNumber: number = 1;
  favResponseDisplayColumns = ['ID', 'Form Name', 'Project Name', 'Client Name', 'Status'];
  favResponseKeys = ['id', 'responseTitle', 'responseProjectTitle', 'responseClientTitle', 'responseStatusTitle']

  favProjectQueryParam: IQuery;
  favProjectPagerObject: IPager;
  favProjectSortingObject;
  favProjectCurrentPageNumber: number = 1;
  favProjectDisplayColumns = ['Project Name', 'Client Name', 'Project Manager', 'Status'];
  favProjectKeys = ['projectTitle', 'clientTitle', 'fiservImplementationManagerName', 'statusTitle'];

  roleToAccessReports = ["Division Leader", "BU Leader", "Delivery Owner", "Delivery Leader", "Relationship Manager", "CCT Admin", "Restricted Admin", "Sales Executive", "Account Team"];

  isRespondent: boolean = false;
  canAccessReports: boolean = false;
  @ViewChild('editformdetails') editformdetails: EditFormDetailsComponent
  isEditFormDetailsAllowed: boolean = false;
  responseData: any;
  updateApproverMsg: string;
  pendinglistname: any;
  favoritelistname: any;
  favoriteresponselistname: any;
  favoriteprojectlistname: any;
  filtered: boolean = false;
  isSubmitted = false;
  regexValidationMessage: any;

  constructor(
    private favoritesService: FavoritesService,
    private formService: FormsService,
    private _router: Router,
    private permissionService: PermissionsService,
    private userService: UserService,
    private formsService: FormsService,
    private projectService: ProjectsService,
    private route: ActivatedRoute,
    private userFilterService: UserFilterService,
    private contentService: ContentService,
    private fdlFilterHelper: FdlFilterUIHepler,
    private authenticationService: AuthenticationService
  ) {
    super(_router);
  }

  initFilterForm() {
    //this.favcategory: new FormControl('');
    this.filterForm = new FormGroup({
      category: new FormControl(''),
      title: new FormControl('', [Validators.pattern(REGEX_PATTERNS.form_response_survey_name_validator),]),
      projectTitle: new FormControl('', [Validators.pattern(REGEX_PATTERNS.project_name_validator),]),
      responseStatusTitle: new FormControl('')
    });
  }

  ngOnInit(): void {
    this.regexValidationMessage = REGEX_VALIDATION_MESSAGE;
    const user = this.userService.getUserListData();
    this.isAdmin = user.isAdmin;
    const { accessToken } = this.authenticationService.getLogin(); 
    this.accessToken = accessToken?.substring(-20);
    if (!user) {
      this.userService.callUserListData().subscribe((data) => {
        this.setUpUserAndPermissions(data);
        this.populateCategories();
        //this.setLoginTimestamp(data, this.accessToken);
        this.getLoginTimestamp(data);
      });
    }
    else {
      this.setUpUserAndPermissions(user);
      this.getLoginTimestamp(user);
    }

    this.content = this.contentService.getcontent();
    if (!this.content) {
      this.contentService.setContent().then(data => {
        this.content = this.contentService.getcontent();
      });
    }

    this.myAssignDisplayColumns = ['ID', this.content['formNameColumnDashboard'], this.content['projectColumnDashboard'],
      this.content['clientColumnDashboard'], this.content['statusColumnDashboard'], this.content['dueDateColumnDashboard']];
    this.favResponseDisplayColumns = ['ID', this.content['formNameColumnDashboard'], this.content['projectColumnDashboard'],
      this.content['clientColumnDashboard'], this.content['statusColumnDashboard']];
    this.favProjectDisplayColumns = [this.content['formNameColumnDashboard'], this.content['clientColumnDashboard'],
    this.content['projectColumnDashboard'], this.content['statusColumnDashboard']];


    // this.userDetails = this.userService.getUserListData();
    // this.userService.callUserListData().subscribe(data => {
    //   this.userDetails = data;
    //   console.log(this.userDetails);
    // });
    this.initQueryParams();
    this.initFilterForm();
    this.pendinglistname = PreferenceService.dashboardPendingTaskList;
    this.favoriteresponselistname = PreferenceService.dashboardFavoritesResponseList;
    this.favoriteprojectlistname = PreferenceService.dashboardFavoritesProjectList;

    this.filterExists = this.userFilterService.loadAndApplyFilters(this.route, this.filterForm, this.myAssignQueryParam);
    if (this.filterExists) this.myAssignQueryParam.formType = this.filterForm.value['category'];
    const resolvedData: IResponseStatusResloved = this.route.snapshot.data['resolveData'];
    this.formStatus = resolvedData.responseStatus;
    this.showProjectFilter = this.permissionService.showProjectFilter();
    this.isViewTimelineAllowed = !this.userService.isRespondent; // && !this.userService.isClient;
    this.isEditFormDetailsAllowed = !this.userService.isClient && !this.userService.isRespondent;

    this.getSuggestions();


    this.today = moment().format('LLLL');
    this.isCreateFormAllowed = this.permissionService.isCreateFormAllowed();
    this.isEditFormAllowed = this.permissionService.isEditFormAllowed();
    this.isDeleteFormAllowed = this.permissionService.isDeleteFormAllowed();
    this.isViewHistoryAllowed = !this.userService.isClient && !this.userService.isRespondent;

    this.onFavCategoryChange();
    this.getMyAssignedTasks();
    this.populateCategories();
    this.filtered = this.filterExists
  }

  refreshMyFavs(): void {
    this.onFavCategoryChange();
  }

  populateCategories(): void {
    const isNonrespondent = this.userDetails.isAdmin === true || this.userDetails.permissionsForUI.some(group => group.groupName === "Projects");
    if (isNonrespondent) {
      this.favcategories = Object.values(FavoriteCategory).filter(value => typeof value !== 'number');
    }
    else {
      this.favcategories = Object.values(FavoriteRespondentCategory).filter(value => typeof value !== 'number');
    }
    // this.favcategories = Object.values(FavoriteCategory).filter(value => typeof value !== 'number');
    // this.category = 1;
  }

  getLoginTimestamp(user: IUser) {
    this.userService.getLoginData(user.userId).subscribe((data) => {
      moment.locale(this.content['locale']);
      this.LastLoginTimeStamp = moment.utc(data.body).local().format("dddd D MMMM YYYY HH:mm");
    },
      (error) => console.log("Error"));
  }

  // setLoginTimestamp(user: IUser, accessToken) {
  //   this.userService.setLoginData(user, accessToken).subscribe((data) => {
  //     this.getLoginTimestamp(user);
  //     console.log("userdetails entered in DB");
  //   },
  //     (error) => console.log("Error:" + error));
  // }

  onMyAssignCategoryChange(): void {
    this.myAssignQueryParam.formType = this.myAssignCategory;
    this.getMyAssignedTasks();
  }

  onFavCategoryChange(): void {

    if (this.favCategory == 'Responses') {
      this.getFavorieResponses();
    } else {
      this.getFavorieProjects();
    }
  }

  getFavorieResponses() {
    $('#myFavs').addClass('fa-rotation');
    this.favoritesService.getAllFavorieResponses(this.favResponseQueryParam)
      .pipe(take(1))
      .subscribe(
        responses => {
          this.isError = false;

          //this.favoriteResponseList= data  as IFavorite;
          this.favoriteResponseList = responses.body as IFavorites;
          if (responses.headers.get('X-pagination')) {
            this.favResponsePagerObject = Object.assign({}, JSON.parse(responses.headers.get('X-pagination')) as IPager);
          }
          $('#myFavs').removeClass('fa-rotation');
        },
        (error) => { this.isError = true; this.errorMsg = error; });
  }


  getFavorieProjects() {
    $('#myFavs').addClass('fa-rotation');
    this.favoritesService.getAllFavorieProjects(this.favProjectQueryParam)
      .pipe(take(1))
      .subscribe(
        responses => {
          this.isError = false;

          //this.favoriteProjectList= data  as IFavorite;
          this.favoriteProjectList = responses.body as IFavorites;
          if (responses.headers.get('X-pagination')) {
            this.favProjectPagerObject = Object.assign({}, JSON.parse(responses.headers.get('X-pagination')) as IPager);
          }
          $('#myFavs').removeClass('fa-rotation');
        },
        (error) => { this.isError = true; this.errorMsg = error; });
  }

  getMyAssignedTasks() {

    // $('.refresh-icon-link').addClass('fa-rotation');
    $('#myAssignedRefresh').addClass('fa-rotation');
    this.formService.getMyAssigned(this.myAssignQueryParam)
      .pipe(take(1))
      .subscribe(
        responses => {
          // below is handled in the showFormSuccessMsg method
          //this.isError = false;

          this.formList = responses.body as IForms;
          if (responses.headers.get('X-pagination')) {
            this.myAssignPagerObject = Object.assign({}, JSON.parse(responses.headers.get('X-pagination')) as IPager);
          }
          // this.masterService.resetFiltersCancelledFlag();
          // $('.refresh-icon-link').removeClass('fa-rotation');
          $('#myAssignedRefresh').removeClass('fa-rotation');
        },
        (error) => { this.isError = true; this.errorMsg = error; });
  }

  refreshMyAssignedTasks() {
    // $('.refresh-icon-link').addClass('fa-rotation');
    $('#myAssignedRefresh').addClass('fa-rotation');
    this.formService.refreshMyAssigned(this.myAssignQueryParam)
      .pipe(take(1))
      .subscribe(
        responses => {
          // below is handled in the showFormSuccessMsg method
          //this.isError = false;

          this.formList = responses.body as IForms;
          if (responses.headers.get('X-pagination')) {
            this.myAssignPagerObject = Object.assign({}, JSON.parse(responses.headers.get('X-pagination')) as IPager);
          }
          // this.masterService.resetFiltersCancelledFlag();
          // $('.refresh-icon-link').removeClass('fa-rotation');
          $('#myAssignedRefresh').removeClass('fa-rotation');
        },
        (error) => { this.isError = true; this.errorMsg = error; });
  }


  initQueryParams() {
    this.myAssignQueryParam = Object.assign({}, {
      pageNumber: this.myAssignCurrentPageNumber,
      pageSize: environment.pageSize,
      formType: this.myAssignCategory
    });

    this.favResponseQueryParam = Object.assign({}, {
      pageNumber: this.favResponseCurrentPageNumber,
      pageSize: environment.pageSize
    });

    this.favProjectQueryParam = Object.assign({}, {
      pageNumber: this.favProjectCurrentPageNumber,
      pageSize: environment.pageSize
    });
  }

  myAssignChangePage(page) {
    this.myAssignQueryParam.pageNumber = page;
    this.getMyAssignedTasks();
  }

  myAssignSetOrderBy(myAssignSortingObject) {
    if (myAssignSortingObject) {
      this.myAssignQueryParam.orderBy = (myAssignSortingObject.isAsc) ? myAssignSortingObject.key : myAssignSortingObject.key + ' desc';
    }
  }

  myAssignApplySorting(event) {
    if (event.key !== 'formsCount') {
      this.myAssignSortingObject = event;
      this.myAssignSetOrderBy(this.myAssignSortingObject);
      this.getMyAssignedTasks();
    }
  }

  myAssignNavigate(event) {
    if (event.action === "view") {
      this._router.navigate(['/response/view/responses', event.data]);
    }
    else if (event.action === "delete") {
      this.successMsg = '';
      this.isError = false;
      this.formsService.deleteForm(event.data).subscribe(
        data => {
          this.getMyAssignedTasks();
          this.successMsg = `The form '${event.title}' has been deleted.`
        },
        error => {
          this.isError = true;
          this.errorMsg = error;
        }
      )
    }
  }

  favResponseChangePage(page) {
    this.favResponseQueryParam.pageNumber = page;
    this.getFavorieResponses();
  }

  favResponseSetOrderBy(favResponseSortingObject) {
    if (favResponseSortingObject) {
      this.favResponseQueryParam.orderBy = (favResponseSortingObject.isAsc) ? favResponseSortingObject.key : favResponseSortingObject.key + ' desc';
    }
  }

  favResponseApplySorting(event) {
    if (event.key !== 'formsCount') {
      this.favResponseSortingObject = event;
      this.favResponseSetOrderBy(this.favResponseSortingObject);
      this.getFavorieResponses();
    }
  }

  favResponseNavigate(event) {

    if (event.action === "view") {
      this._router.navigate(['/response/view/responses', event.data]);
    }
    else if (event.action === "viewProjectTimeline") {
      this._router.navigate(['/projects/viewTimeline', event.data]);
    }
    else if (event.action === "viewTimeline") {
      this.formsService.historyData = undefined;
      this._router.navigate(['/response/timeline/list', event.data]);
    }
    else if (event.action === "editFormDetails") {
      $('#editFormDetailforms').modal('show');
      this.editformdetails.initiateForm();

      this.formsService.getResponseJson(event.data).subscribe(
        data => {

          this.responseData = {
            id: data.id,
            title: data.title,
            dueDate: data.dueDate,
            rowVersion: data.rowVersion
          }
        });
    }
    else if (event.action === "delete") {
      this.successMsg = '';
      this.isError = false;
      this.formsService.deleteForm(event.data).subscribe(
        data => {
          this.getFavorieResponses();
          this.successMsg = `The form '${event.title}' has been deleted.`
        },
        error => {
          this.isError = true;
          this.errorMsg = error;
        }
      )
    }
  }

  favProjectChangePage(page) {
    this.favProjectQueryParam.pageNumber = page;
    this.getFavorieProjects();
  }

  favProjectSetOrderBy(favProjectSortingObject) {
    if (favProjectSortingObject) {
      this.favProjectQueryParam.orderBy = (favProjectSortingObject.isAsc) ? favProjectSortingObject.key : favProjectSortingObject.key + ' desc';
    }
  }

  favProjectApplySorting(event) {
    if (event.key !== 'formsCount') {
      this.favProjectSortingObject = event;
      this.favProjectSetOrderBy(this.favProjectSortingObject);
      this.getFavorieProjects();
    }
  }

  favProjectNavigate(event) {
    if (event.action === "view") {

      this._router.navigate(['/response/view/responses', event.data]);
    }
    else if (event.action === "viewProjectTimeline") {
      this._router.navigate(['/projects/viewTimeline', event.data]);
    }
    else if (event.action === "delete") {
      this.successMsg = '';
      this.isError = false;
      this.formsService.deleteForm(event.data).subscribe(
        data => {
          this.getFavorieProjects();
          this.successMsg = `The form '${event.title}' has been deleted.`
        },
        error => {
          this.isError = true;
          this.errorMsg = error;
        }
      )
    }
  }

  removeFavoriteProject(data) {

    this.favoritesService.removeFavoriteProject(data.data).subscribe(x => {
      this.getFavorieProjects();
    });
  }
  removeFavoriteResponse(data) {
    this.favoritesService.removeFavoriteResponse(data.data).subscribe(x => {
      this.getFavorieResponses();
    });
  }

  private setUpUserAndPermissions(user: IUser): void {
    this.userService.setUserListData(user);
    this.userDetails = this.userService.getUserListData();
    if (!this.userDetails) {
      return;
    }

    let isNonrespondent = this.userDetails.permissionsForUI.some(group => group.groupName === "Projects");
    if ((!this.userDetails.isAdmin && !isNonrespondent) || (this.userDetails.type == "Client")) {
      this.isRespondent = true;
    }
    this.canAccessReports = !this.isRespondent || this.userDetails.roles.some(r => this.roleToAccessReports.indexOf(r.roleTitle) >= 0);
  }

  getResponseSuggestions() {
    this.projectService.getSuggestions("responses", "").subscribe(
      data => this.responsesSuggestion = data,
      (error) => { this.isError = true; this.errorMsg = error; }
    );

  }

  getSuggestions() {
    this.getResponseSuggestions();
    if (this.showProjectFilter) {
      this.projectService.getSuggestions("projects", "").subscribe(
        data => this.projectSuggestion = data,
        (error) => { this.isError = true; this.errorMsg = error; }
      );
    }
  }

  getFormStatus() {
    const resolvedData: IResponseStatusResloved = this.route.snapshot.data['resolveData'];
    this.formStatus = resolvedData.responseStatus;
  }

  filterList() {
    if (this.filterForm.valid) {
      this.userFilterService.saveFilter(this.route, this.filterForm);
      this.initQueryParams();
      let filterValues = this.filterForm.value;
      if (filterValues['projectTitle'])
        this.myAssignQueryParam.projectTitle = filterValues['projectTitle']
      if (filterValues['responseStatusTitle'])
        this.myAssignQueryParam.responseStatusTitle = filterValues['responseStatusTitle'];
      if (filterValues['category'])
        this.myAssignQueryParam.formType = filterValues['category'];
      if (filterValues['title'])
        this.myAssignQueryParam.title = filterValues['title']

      this.myAssignSetOrderBy(this.myAssignSortingObject);

      console.log("Filter to be applied: ", this.queryParam);
      this.getMyAssignedTasks();

      this.filtered = true
    }

  }

  clearFilterList() {
    this.filtered = false
    this.initQueryParams()
    this.initFilterForm()
    this.userFilterService.clearFilter()
    this.refreshMyAssignedTasks()
    this.fdlFilterHelper.showSearchIconWhenResetFilter();
  }

  successUpdateApproverClick(evt) {
    this.getFavorieResponses();
    this.updateApproverMsg = '';
    this.updateApproverMsg = evt.updateApproverSuccessMsg;
  }


}

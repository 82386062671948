import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommitmentsIntakeSearchInput } from '../models/commitments-intake-search-input';
import { CommitmentsIntakeSearchType } from '../enums/commitments-intake-search-type.enum';
import { CommitmentsIntakeSearchInputForLocalStorage } from '../models/commitments-intake-search-input-for-local-storage';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-commitments-intake-search',
  templateUrl: './commitments-intake-search.component.html',
  styleUrls: ['./commitments-intake-search.component.css']
})
export class CommitmentsIntakeSearchComponent {
  showResetButton = false;
  public blueThemeMode = false;
  public commitmentsIntakeSearchInputs: CommitmentsIntakeSearchInput[] = [];
  public searchFieldsValue: any;

  @Input()
  public set searchFields(value: any) {
    if (value) {
      this.searchFieldsValue = value;
      const userFilterString = localStorage.getItem('commitments-intake-search');
      if (userFilterString) {
        this.commitmentsIntakeSearchInputs = (<CommitmentsIntakeSearchInputForLocalStorage>JSON.parse(userFilterString)).commitmentsIntakeSearchInput
          .filter(r => r.type !== 'Data Source');
        if (this.commitmentsIntakeSearchInputs.length > 0) {
          this.showResetButton = true;

          this.commitmentsIntakeSearchInputs.forEach(item => {
            item.title = item.title.replace("''", "'");
            this.searchFieldsValue.find(r => r.formfieldlabel === item.type).data.push(item.title);
          });
        }
      }
    }
  }

  @Output()
  private search = new EventEmitter<{ searchTags: CommitmentsIntakeSearchInput[], shouldReset?: boolean }>();

  constructor(private userService: UserService) {
    this.userService.blueThemeMode.subscribe(r => this.blueThemeMode = r);
  }

  public onSearchFilterChanged(data: string[], searchType: string, formFieldApiKey: string): void {
    const commitmentsIntakeSearchTags = this.commitmentsIntakeSearchInputs.filter(item => item.type !== searchType);
    const typeEnumForSorts = {
      'Client Name': CommitmentsIntakeSearchType.ClientName,
      'Clarity Project Number': CommitmentsIntakeSearchType.ClarityProjectNumber,
      'Commitment Title': CommitmentsIntakeSearchType.CommitmentTitle,
      'Clarity Project Status': CommitmentsIntakeSearchType.ClarityProjectStatus,
      'Commitment Type': CommitmentsIntakeSearchType.CommitmentType,
      'Commitment Status': CommitmentsIntakeSearchType.CommitmentStatus,
      'Opportunity ID': CommitmentsIntakeSearchType.OpportunityID,
      'Opportunity TCV': CommitmentsIntakeSearchType.OpportunityTCV,
      'DUNS': CommitmentsIntakeSearchType.DUNS,
      'MC': CommitmentsIntakeSearchType.MC,
      'Relationship Manager': CommitmentsIntakeSearchType.RelationshipManager,
      'Committed Delivery Date': CommitmentsIntakeSearchType.CommittedDeliveryDate,
      'Product Name': CommitmentsIntakeSearchType.Product
    };

    const typeEnumForSort = typeEnumForSorts[searchType] >= 0 ? typeEnumForSorts[searchType] : CommitmentsIntakeSearchType.DataSource;
    data.forEach(item => {
      const temp: CommitmentsIntakeSearchInput = {
        title: item,
        type: searchType,
        formFieldApiKey: formFieldApiKey,
        typeEnumForSort: typeEnumForSort
      };
      commitmentsIntakeSearchTags.push(temp);
    });
    this.commitmentsIntakeSearchInputs = commitmentsIntakeSearchTags.sort((prev, current) => {
      const prevTagIndex = this.searchFieldsValue.findIndex(x => x.formfieldapikey === prev.formFieldApiKey);
      const currentTagIndex = this.searchFieldsValue.findIndex(x => x.formfieldapikey === current.formFieldApiKey);
      if (prevTagIndex === currentTagIndex) {
        return prev.typeEnumForSort < current.typeEnumForSort ? -1 : 1;
      }
      return prevTagIndex - currentTagIndex;
    });
  }

  public onDeleteSearchFilter(data: CommitmentsIntakeSearchInput): void {
    this.commitmentsIntakeSearchInputs = this.commitmentsIntakeSearchInputs.filter(r => r !== data);

    for (let i = 0; i < this.searchFieldsValue.length; i++) {
      if (this.searchFieldsValue[i].formfieldlabel === data.type) {
        this.searchFieldsValue[i].data = this.searchFieldsValue[i].data.filter(r => r !== data.title);
        break;
      }
    }
  }

  public onSearchClicked(shouldReset: boolean = false): void {
    const commitmentsIntakeSearchTags: CommitmentsIntakeSearchInput[] = [];
    if (this.commitmentsIntakeSearchInputs.length > 0) {
      this.showResetButton = true;
      this.commitmentsIntakeSearchInputs.forEach(item => {
        commitmentsIntakeSearchTags.push({
          type: item.type,
          title: item.title.replace("'", "''"),
          formFieldApiKey: item.formFieldApiKey,
          typeEnumForSort: item.typeEnumForSort
        });
      });
    } else {
      this.showResetButton = false;
    }
    this.search.emit({ searchTags: commitmentsIntakeSearchTags, shouldReset });
  }

  public onResetClicked(): void {
    this.commitmentsIntakeSearchInputs = [];
    this.searchFieldsValue.forEach(item => {
      item.data = [];
    });
    this.onSearchClicked(true);
  }
}

import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, OnChanges, SimpleChanges, Renderer2 } from '@angular/core';
import { FormArray, FormGroup, FormBuilder } from '@angular/forms';
import { ProjectsService } from '../../../services/projects.service';
import { ROLES } from 'src/app/core/constants';
declare var $: any;
@Component({
  selector: 'app-commitment-delegate-details',
  templateUrl: './commitment-delegate-details.component.html',
  styleUrls: ['./commitment-delegate-details.component.css']
})
export class CommitmentDelegateDetailsComponent implements OnInit, OnChanges {
  @Input() isFdl: boolean = false;
  @Input() fdlData: any;
  @Input() formControlData: FormGroup;
  @Input() isSubmitted: boolean = false;
  @Input() buttonText: string = "Add RM/AM/SE/AE";
  @Input() fieldJson: any;
  @Input() fieldDetails: string[];
  @Input() elementName: any;
  @Input() preFilledData: any;
  @Input() typeofElements: any;
  @Input() cssWidth: string = "320px";
  @Input() isDisable: boolean = false;
  @Input() duplicateTestField: string = "";
  @Input() allowDuplicates = true;
  @Input() extraButtons: any[];
  @Input() overrideRightMargin: boolean = false;
  @Input() isReadOnlyMode = false;
  @Output() extraButtonClick = new EventEmitter<{ action: string, index: number }>();
  @Output() duplicateFound = new EventEmitter<{ indexes: number[], data: any[], hasDuplicates: boolean }>();
  @Output() formReady = new EventEmitter<FormGroup>();
  @Output() respondentSelected = new EventEmitter<any>();
  @Output() onClear = new EventEmitter<{ data: any, fieldName: string, selectRespondent: string }>();
  @Input() isCreate = false;
  removedQueue = [];


  private _hasDuplicates: boolean = false;
  duplicated = [];
  filedArray: any[];

  get hasDuplicates() {
    return this._hasDuplicates
  }

  // END | PBI-168948
  noOfElements: number = 1;
  elementForm: FormGroup;
  constructor(private fb: FormBuilder, private _projectService: ProjectsService,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fieldJson && changes.fieldJson.currentValue && changes.fieldJson.currentValue.dataToEdit) {
      this.initForm();
    }
  }

  onRespondentChange(data: any, fieldName: any, selectRespondent: string = '0') {
    if (data === "") {
      if (!this.removedQueue.some(index => index === selectRespondent)) {
        this.removedQueue.push(selectRespondent);
        var dataArray = this.formControlData.value[this.elementName];
        dataArray[this.elementName][selectRespondent]['name'] = '';
        dataArray[this.elementName][selectRespondent]['emails'] = '';
        dataArray[this.elementName][selectRespondent]['id'] = '';
        dataArray[this.elementName][selectRespondent]['role'] = '';
        dataArray[this.elementName][selectRespondent]['dueDate'] = null;

        let formArray: any = {};
        formArray[this.elementName] = dataArray;
        this.formControlData.patchValue(formArray, { emitEvent: false });
      }

      this.onClear.emit({ data, fieldName, selectRespondent });
    } else {
      if (this.removedQueue.some(index => index === selectRespondent)) {
        this.removedQueue = this.removedQueue.filter(index => index !== selectRespondent);
      }
    }
    this.stylingForValidControl(fieldName);

  }

  isFormEmpty(selectRespondent: number): boolean {
    const formValue = this.formControlData.value[this.elementName][this.elementName][selectRespondent];
    const result = !formValue['name'] && !formValue['emails'] && !formValue['role'];
    return result;
  }

  isErrorOn(value: boolean = true) {
    this.isSubmitted = value;
    this.stylingForValidControl('name');
    this.stylingForValidControl('emails');
  }

  ngOnInit(): void {
    this.todaysdate = this._projectService.getTodaysDate();

    /**************************************************************/
    this.initForm();
  }

  initForm(resetFrom = false) {
    let tempFormArray: any = {};
    this.noOfElements = 1;
    tempFormArray[this.elementName] = this.fb.array([]);

    this.elementForm = this.fb.group(tempFormArray);

    if (this.fieldJson && this.fieldJson.dataToEdit && this.fieldJson.dataToEdit.length > 0 && !resetFrom) {

      var dataArray = [];
      dataArray[this.elementName] = []
      for (let i in this.fieldJson.dataToEdit) {
        this.addElements();
        dataArray[this.elementName].push(this.fieldJson.dataToEdit[i]);
      }
      let formArray: any = {};
      formArray[this.elementName] = dataArray;
      this.formControlData.patchValue(formArray);
    } else {
      this.addElements();
      this.removedQueue = [];
    }
    this.formReady.emit(this.elementForm);
  }

  updateFormJsonDetails() {
  }

  errorMsg = '';
  isInputValid() {
    var validFlag: boolean = true;
    if (!this.formControlData.valid) return false;

    if (this.formControlData.value[this.elementName]) {
      var dataArray = [];
      dataArray[this.elementName] = [];
      const formValue = this.formControlData.value[this.elementName][this.elementName];
      if (formValue.length < 2) return false;

      for (let index = 0; index < formValue.length; index++) {
        if (!this.isCreate && +index === 0) continue;

        const element = formValue[index];
        if (!this.validateToUser(element, this.fdlData.data)) {
          validFlag = false;
          this.errorMsg = ' User name and Email does not match.';
          break;
        }
      }
    }

    return validFlag;
  }

  validateToUser(currentUser: any, userList: any[]): boolean {
    const matchUser = userList.find(item => item.email === currentUser.emails || item.name === currentUser.name);
    if (!matchUser) return false;

    if (matchUser.name !== currentUser.name || currentUser.emails !== matchUser.email) return false;

    return true;
  }

  mapFieldData() {
    let temparray: any = []
    for (let k in this.fieldDetails) {
      let emailField = '';
      let nameField = '';
      nameField = this.fieldDetails[k]['nameField'];
      emailField = this.fieldDetails[k]['emailField'];
      temparray[this.fieldDetails[k]['name']] = { 'type': this.fieldDetails[k]['type'], 'fdlEmail': emailField ? emailField : 'emails', 'fdlName': nameField ? nameField : 'name' };
    }
    this.filedArray = temparray;
  }

  selectRespondent(data: any, selectedFieldName: any, selectRespondent: string = '0') {
    this.mapFieldData();
    if (data && data.id) {
      var dataArray = this.formControlData.value[this.elementName];
      dataArray[this.elementName][selectRespondent][this.filedArray[selectedFieldName]['fdlName']] = data.name;
      dataArray[this.elementName][selectRespondent][this.filedArray[selectedFieldName]['fdlEmail']] = data.email;

      dataArray[this.elementName][selectRespondent]['id'] = data.id;
      dataArray[this.elementName][selectRespondent]['role'] = this.getValidRoleForDelegate(data.userRoles);
      dataArray[this.elementName][selectRespondent]['isAdmin'] = data.isAdmin;
      dataArray[this.elementName][selectRespondent]['isRestrictedAdmin'] = data.isRestrictedAdmin;

      let formArray: any = {};
      formArray[this.elementName] = dataArray;
      this.formControlData.patchValue(formArray);
      if (this.respondentSelected) {
        this.respondentSelected.emit({
          data,
          fieldName: selectedFieldName,
          selectRespondent
        });
      }
    }
    this.validateDuplicatedElements();
  }

  getValidRoleForDelegate(userRoles: any[]): any {
    const userRole = userRoles.find(el => el.roleTitle === ROLES.Account_Manager
      || el.roleTitle === ROLES.Relationship_Manager
      || el.roleTitle === ROLES.Sales_Executive || el.roleTitle === ROLES.Account_Executive);

    return userRole ? userRole.roleTitle : '';
  }

  // START | PBI-168948
  onExtraButtonClick(action: string, index: number) {
    this.extraButtonClick.emit({ action, index });
  }

  validateDuplicatedElements() {
    this.clearDuplicateError();
    if (!this.allowDuplicates && this.duplicateTestField !== "") {
      const source = this.elements().value.slice();
      for (let i = 0; i < source.length; i++) {
        for (let j = 0; j < source.length; j++) {
          if (j != i) {
            if (source[i][this.duplicateTestField] === source[j][this.duplicateTestField]) {
              if (!this.duplicated.includes(i)) {
                this.duplicated.push(i)
              }
              if (!this.duplicated.includes(j)) {
                this.duplicated.push(j)
              }
            }
          }
        }
      }
      if (this.duplicated.length > 0) {
        this.markWithDuplicateError(this.duplicated);
        this._hasDuplicates = true;
      }
      this.duplicateFound.emit({
        indexes: this.duplicated,
        data: source,
        hasDuplicates: this.duplicated.length > 0
      });
    }
  }

  markWithDuplicateError(indexes) {
    const values = this.elements();
    for (const index of indexes) {
      values.at(index).setErrors({ duplicate: true });
    }
  }

  clearDuplicateError() {
    this.duplicated = [];
    this._hasDuplicates = false;
  }

  // END | PBI-168948
  /***********************Temp Code*****************************/
  elements(): FormArray {
    this.formReady.emit(this.elementForm);
    return this.elementForm.get(this.elementName) as FormArray
  }

  newSkill(dataToPopulate: any = ''): FormGroup {
    if (dataToPopulate != '')
      return this.fb.group(dataToPopulate)
    else
      return this.fb.group(this.fieldJson.json)
  }

  addElements(dataToPopulate: any = '') {
    this.formReady.emit(this.elementForm);
    if (dataToPopulate != '') {
      if (this.elements().length === 1) {
        this.elements().removeAt(0, {
          emitEvent: false
        });
      }
      this.noOfElements = 0;
    }
    if (dataToPopulate != '')
      this.elements().push(this.newSkill(dataToPopulate));
    else
      this.elements().push(this.newSkill(dataToPopulate));
    this.noOfElements = this.noOfElements + 1;
    this.stylingForValidControl('name');
    this.stylingForValidControl('emails');
  }

  removeSkill(i: number) {
    this.elements().removeAt(i);
    this.noOfElements = this.noOfElements - 1;
    if (this.removedQueue.some(index => index === i)) {
      this.removedQueue = this.removedQueue.filter(index => index !== i);
    }
    this.cdr.detectChanges();
    this.validateDuplicatedElements();
  }

  todaysdate: string;
  checkDate(dueDate) {
    return dueDate.value >= this.todaysdate;
  }
  /**************************************************************/

  private stylingForValidControl(fieldName = ''): any {
    for (let index = 0; index < this.formControlData.value[this.elementName][this.elementName].length; index++) {
      const value = this.formControlData.value[this.elementName][this.elementName][index][fieldName];
      //var control = document.getElementById(fieldName + index)?.children[0]?.children[1]?.children[0];
      //if (!control) continue;
      const element = document.getElementById(fieldName + index);
      if (!element) continue;

      //const element = document.getElementById(control.id);
      if (value) {
        this.renderer.setStyle(element, 'border', '1px solid #9e9e9e');
      } else {
        this.renderer.setStyle(element, 'border', '1px solid #dd3435');
      }
    }
  }

  public ariaLabelledby(name: string): string {
    switch (name) {
      case 'name':
        return 'Dropdown for name'
        break;
      case 'emails':
        return 'Dropdown for email'
        break;
      case 'role':
        return 'Role field'
        break;
      case 'dueDate':
        return 'Expire date field'
        break;
      default:
        return '';
        break;
    }
  }
}

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpXhrBackend } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { LocationStrategy } from '@angular/common';
import { CookieService } from './cookie.service';

export declare class AuthSettings {
  authorizationUrl: string;
  clientId: string;
  clientSecret: string;
  redirectUri: string;
  grantType: string;
  tokenUri: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private readonly _environment = environment;
  public static readonly accessTokenKeyName = 'drmsToken';
  private readonly _refreshTokenKey: string = 'drmsRefreshToken';
  private readonly _accessTokenKey: string;
  private _http: HttpClient;

  constructor(private location: LocationStrategy, private cookieService: CookieService) {
    this._http = new HttpClient(new HttpXhrBackend({ 
      build: () => new XMLHttpRequest() 
    }));

    this._accessTokenKey = AuthenticationService.accessTokenKeyName;
  }

  login(): Observable<object> {
    return this.getTokenFromCode();
  }
  
  private getTokenFromCode(): Observable<Object> {
    const params = (new URL(window.document.location.href)).searchParams;
    const code = params.get("code");
    if (!code)
    {
      return of<Object[]>();
    }

    return this.getAccessToken(code);
  }
 
  private getAccessToken(code: string): Observable<Object> {
    const authSettings = this.getAuthSettings(); 
    const formBody = this.convertObjectToForm({
      grant_type: authSettings.grantType,
      code: code,
      client_id: authSettings.clientId,
      redirect_uri: authSettings.redirectUri
    });
    const base64Encoded = btoa(`${authSettings.clientId}:${authSettings.clientSecret}`);
    return this._http.post(authSettings.tokenUri, formBody, {
      headers: new HttpHeaders({
        'Authorization': `Basic ${base64Encoded}`,
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
       })
    });
  }

  getLogin() {
    return {
      accessToken: AuthenticationService.getStoragedAccessToken()
    }
  }

  removeLogin() {
    localStorage.removeItem(this._accessTokenKey);
    this.cookieService.delete(this._accessTokenKey);
    this.cookieService.delete(this._refreshTokenKey);
  }

  public setLogin(accessTokenResponse) {
    const accessToken = accessTokenResponse['access_token'];
    if (accessToken) {
      AuthenticationService.setStoragedAccessToken(accessToken);
    }

    const refreshToken = accessTokenResponse['refresh_token'];
    if (refreshToken) {
      this.setRefreshToken(refreshToken);
    }
    
    sessionStorage.removeItem('surveyToken');
  }

  logoutRedirect(logoutRedirectUrl: string = null) {
    this.removeLogin();
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
    if(!logoutRedirectUrl){
      this.redirectToPingIdLogin();
    }
    else {
      location.replace(logoutRedirectUrl);
    }
  }

  logoutAccessDeniedUser() {
    sessionStorage.removeItem("surveyToken");
    this.logoutRedirect('/access-denied.html');
  }

  isLoggedIn(): boolean {
    var accessToken = AuthenticationService.getStoragedAccessToken();
    if (!accessToken) {
      return false;
    }
  
    const decoded: JwtPayload = jwtDecode(accessToken);
    if (!decoded || !decoded.exp) {
      return false;
    }

    let isLoggedIn = Date.now() < decoded.exp * 1000;
    return isLoggedIn;
  }

  isTokenExpired(): boolean {
    var accessToken = AuthenticationService.getStoragedAccessToken();
    if (!accessToken) {
      return false;
    }
  
    const tokenDecoded: JwtPayload = jwtDecode(accessToken);
    if (!tokenDecoded || !tokenDecoded.exp) {
      return false;
    }

    let isExpired = Date.now() >= tokenDecoded.exp * 1000;
    return isExpired;
  }

  async refreshToken(): Promise<boolean> {
    const refreshToken = this.getRefreshToken();
    if (!refreshToken) {
      throw throwError("No refresh token has been found");
    }
    
    const accessTokenResponse = await this.getAccessTokenByRefresh(refreshToken).toPromise();
    if (!accessTokenResponse) {
      return false;
    }

    this.setLogin(accessTokenResponse);
    return true;
  }

  private getAccessTokenByRefresh(refreshToken: string): Observable<object> {
    const authSettings = this.getAuthSettings();
    const formBody = this.convertObjectToForm({
      grant_type: 'refresh_token',
      refresh_token: refreshToken
    });
    
    const base64Encoded = this.getClientAuthorization();
    return this._http.post(authSettings.tokenUri, formBody, {
      headers: new HttpHeaders({
        'Authorization': `Basic ${base64Encoded}`,
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      })
    });
  }

  private convertObjectToForm(body: any){
    const formData = [];
    for (const property in body) {
      const encodedKey = encodeURIComponent(property);
      const encodedValue = encodeURIComponent(body[property]);
      formData.push(`${encodedKey}=${encodedValue}`);
    }
    return formData.join("&");
  }

  private getRefreshToken(){
    return this.cookieService.get(this._refreshTokenKey);
  }
  
  private setRefreshToken(refreshToken: string){
    const expiry = new Date().setDate(1) * 1000;
    this.cookieService.set(this._refreshTokenKey, refreshToken, expiry);
  }

  static getStoragedAccessToken(): string {
    const cookieService = new CookieService();
    return cookieService.get(AuthenticationService.accessTokenKeyName);
  }

  static setStoragedAccessToken(accessToken: string) {
    const cookieService = new CookieService();
    const accessTokenDecoded: JwtPayload = jwtDecode(accessToken);
    if (accessTokenDecoded && accessTokenDecoded.exp) {
      localStorage.setItem(AuthenticationService.accessTokenKeyName, accessToken);
      cookieService.set(AuthenticationService.accessTokenKeyName, accessToken);
    }
  }
  
  getClientAuthorization(): string {
    const authSettings = this.getAuthSettings();
    const base64Encoded = btoa(`${authSettings.clientId}:${authSettings.clientSecret}`);
    return base64Encoded;
  }

  public getAuthSettings(): AuthSettings {
    return this._environment.pingIdOAuthSettings;
  }
  
  private createNonXhrRequest(authorizationUrl: string, authSettings: AuthSettings): void {
    const form = window.document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", authorizationUrl);
    this.addHiddenFormField(form, "client_secret", authSettings.clientSecret);
    this.addHiddenFormField(form, "client_id", authSettings.clientId);
    window.document.body.toString(),
        {            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
        }
    window.document.body.appendChild(form);
    form.submit();
  }

  private addHiddenFormField(form: HTMLFormElement, fieldName: string, value: string, ) {
    const clientIdField = window.document.createElement("input");
    clientIdField.setAttribute("type", "hidden");
    clientIdField.setAttribute("id", fieldName);
    clientIdField.setAttribute("name", fieldName);
    clientIdField.setAttribute("value", value);
    form.appendChild(clientIdField);
  }

  private redirectToPingIdLogin(): void {
    const authSettings = this.getAuthSettings();
    const authorizationUrl = 
      `${authSettings.authorizationUrl}?redirect_uri=${authSettings.redirectUri}&grant_type=authorization_code&response_type=code&client_id=${authSettings.clientId}`;
    this.createNonXhrRequest(authorizationUrl, authSettings);
  }
}
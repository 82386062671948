import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate, CanActivateChild {

  constructor(private router: Router, 
    private authenticationService: AuthenticationService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.validateLogin();
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.validateLogin();
  }

  private getSearchParamsCode(): string {
    const params = (new URL(window.document.location.href)).searchParams;
    return params.get("code");
  }

  private async validateLogin(): Promise<boolean> {
    const code = this.getSearchParamsCode();
    const isLoggedIn = this.authenticationService.isLoggedIn();
    if (!code && !isLoggedIn) {
      this.authenticationService.logoutRedirect();
      return false;
    }

    if (isLoggedIn) {
      return true;
    }

    let hasResponse: boolean;
    await this.authenticationService.login().toPromise()
      .then((response) => {
        hasResponse = !!response;
        if (hasResponse) {
          this.authenticationService.setLogin(response);
        }
        else {
          console.log("No authentication response");
          this.authenticationService.logoutRedirect();
        }
      })    
      .catch((error)=>{
        console.log(error);
        this.authenticationService.logoutRedirect();
      });

    return hasResponse;
  }
}
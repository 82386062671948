import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject, Subscription, forkJoin, of } from 'rxjs';
import { FormsService } from 'src/app/services/forms.service';
import { PageTemplateService } from 'src/app/services/page-template.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { UserService } from 'src/app/services/user.service';
import { IPageTemplateResponse } from 'src/app/shared/page-template';
import { environment } from 'src/environments/environment';
import { ClientCommitmentsService } from 'src/app/services/client-commitments.service';
import { debounceTime, switchMap, map, takeUntil, catchError } from 'rxjs/operators';
import { PermissionsService } from 'src/app/services/permissions.service';

@Component({
  selector: 'app-new-commitments-intake-drawer',
  templateUrl: './new-commitments-intake-drawer.component.html',
  styleUrls: ['./new-commitments-intake-drawer.component.css']
})
export class NewCommitmentsIntakeDrawerComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input()
  public pageTemplateNameTitle = '';
  public clientSuggestion: any;
  public clientName = '';
  public isError = false;
  public errorMsg = ''
  approverFormGroup: FormGroup;
  selectedClientId: number = 0;
  selectedClientName: string;
  @Input() selectedOrganizationId: number = 0;
  @Input() canRequest = false;
  selectedOrganizationName: string;
  selectedProductId: number = 0;
  selectedProductName: string;
  selectedRMName: string;
  selectedRMEmail: string;
  invalidClientDuns: boolean = false;
  @Input()
  isEntityLink: boolean = false;
  @Input()
  isDynamicApprover: boolean = false;
  invalidOrganizationName: boolean = false;
  invalidProductName: boolean = false;
  missingOrganizationName: boolean = false;
  invalidClientName: boolean = false;
  selectedClientValue: string = '';
  selectedClientDunsId: string = '';
  selectedClientGudId: string = '';
  @Input() organizationDetails: any;
  filedDetails: any;
  filedJson: any;
  usersData: any;
  userData: any;
  pageResponse: IPageTemplateResponse;
  pageTemplateResponseId = 0;
  @Output()
  private cancel = new EventEmitter<void>();

  blueThemeMode = false;
  private destroy$ = new Subject<boolean>();
  private getClientSuggestion$ = new Subject<any>();
  getClientSuggestionSub = this.getClientSuggestion$.pipe(
    debounceTime(500),
    switchMap((cmd: any) => {
      return this.getClientSuggestions(cmd);
    })
  ).subscribe();

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private userService: UserService,
    private formService: FormsService,
    private projectService: ProjectsService,
    private pageTemplateService: PageTemplateService,
    private clientCommitmentService: ClientCommitmentsService,
    private permission: PermissionsService,
    private elementRef: ElementRef) {
    this.userService.blueThemeMode.subscribe(r => this.blueThemeMode = r);
  }

  ngOnInit(): void {
    this.userData = this.userService.getUserListData();
    if ((this.isDynamicApprover || this.isEntityLink)
      && (!this.pageResponse // || (this.pageResponse?.accessLevel === 'Write' && (this.pageResponse.pageTemplateResponseStatusId === 1 || this.pageResponse.pageTemplateResponseStatusId === 2))
      )) {
      this.apiFormData();
    }
    else {
      this.resetApproverData();
      if (this.pageResponse)
        this.navigateToResponse(this.pageResponse.id);
      else
        this.createResponse();
    }
  }

  typeaheadLoadingClients(searchValue: string, isDrawerField: boolean = false) {
    const cmd = {
      searchValue,
      isDrawerField,
      entity: 'clients',
      take: 100
    }
    this.getClientSuggestion$.next(cmd);
  }

  getClientSuggestions(cmd: any): any {
    this.selectedClientValue = '';
    if (this.approverFormGroup.valid) {
      cmd.params = [{ paramName: "isGetForUpdate", value: true }];
      if (cmd.searchValue && cmd.searchValue.length >= 3) {
        cmd.params.push({ paramName: "title", value: cmd.searchValue });
      }
      return this.projectService.getClientSuggestionsWithMDM(cmd.entity, cmd.params, cmd.take, true).pipe(
        map((data: any) => {
          if (cmd.isDrawerField) {
            this.clientSuggestion = data;
            // var inputSearch = document.getElementById('searchClientInput').children[0].children[1].children[0];
            // const element = document.getElementById(inputSearch.id);
            // element.click();
          }
        }),
        catchError(error => {
          this.isError = true; this.errorMsg = error;
          return of(false);
        }),
        takeUntil(this.destroy$)
      );
    }
  }


  apiFormData() {
    this.resetApproverChecks();
    let apiCall = [];
    apiCall.push(this.formService.getUsersForSelectedOrg(0));
    const params: any[] = [{ paramName: "isGetForUpdate", value: true }];
    apiCall.push(this.projectService.getClientSuggestionsWithMDM("clients", params, 100, true));

    //if (this.canRequest) {
    // if (this.userGroupOrgIds !== "" && this.userData.isRestrictedAdmin)
    //   apiCall.push(this.projectService.getSuggestions("organizations", this.userGroupOrgIds, "orgIds"));
    // else
    //   apiCall.push(this.projectService.getSuggestions("organizations", ""));
    // apiCall.push(this.projectService.getSuggestions("products", ""));
    // }
    // else {
    //   apiCall.push(this.projectService.getSuggestions("organizations", this.selectedOrganizationId, "orgId"));
    //   apiCall.push(this.projectService.getSuggestions("products", this.selectedOrganizationId, "orgId"));
    // }

    if (this.pageTemplateResponseId > 0)
      apiCall.push(this.clientCommitmentService.getPageTemplateResponseById(this.pageTemplateResponseId));
    forkJoin(apiCall).subscribe(data => {
      this.usersData = { 'data': data[0] };
      this.clientSuggestion = data[1];

      //this.organizationSuggestion = data[2];
      //this.productSuggestion = data[3];
      if (this.pageTemplateResponseId > 0) {
        this.pageResponse = data[2] as IPageTemplateResponse; // data[4] as IPageTemplateResponse;

        if (this.pageResponse && this.pageResponse.organizationId > 0) {
          //this.getProductSuggestionbyOrgId(this.pageResponse.organizationId);
          this.selectedOrganizationId = this.pageResponse.organizationId;
          this.selectedOrganizationName = this.pageResponse.organizationTitle;
        }
      }

      let approver = [];
      if (this.pageResponse && this.pageResponse.pageTemplateApprovers.length > 0) {
        for (let k in this.pageResponse.pageTemplateApprovers) {
          let userObj = this.pageResponse.pageTemplateApprovers[k];
          approver.push({ 'name': userObj.user.name, 'emails': userObj.user.email, id: this.pageResponse.pageTemplateApprovers[k].approverId, level: this.pageResponse.pageTemplateApprovers[k].level })
        }
      }

      this.filedJson = {
        "dataToEdit": approver,
        'json': {
          name: [''],
          emails: '',
          id: '',
          level: ['1']
        }
      }

      this.filedDetails = [
        { 'name': 'emails', 'type': 'emailshow' },
        { 'name': 'id', 'type': 'fdlid' },
        { 'name': 'name', 'type': 'fdl', 'label': 'Name', "className": "col-md-12", "erroeMessage": { 'valid': 'Please select an Approver.' }, 'isRequired': false, 'fdldata': this.userData, 'emailField': 'emails' },
        { 'name': 'level', "className": "col-md-3", 'label': "Level", "erroeMessage": { 'valid': 'Please enter Approver level.' }, 'type': 'text', 'isRequired': false }
      ];

      this.initiateApproverForm();
    },
      (error) => { this.isError = true; this.errorMsg = error; });
  }

  initiateApproverForm() {
    if (this.pageResponse?.organizationId > 0)
      this.selectedOrganizationId = this.pageResponse?.organizationId;

    this.approverFormGroup = this.fb.group({
      'approvalSchema': new FormControl(this.pageResponse ? this.pageResponse?.approvalSchema : "OR"),
      'approver': [this.fb.array([])],
      'organizationName': new FormControl(this.pageResponse ? this.pageResponse?.organizationTitle : ""),
      'productName': new FormControl(this.pageResponse ? this.pageResponse?.productTitle : ""),
      'clientName': new FormControl(this.pageResponse ? this.pageResponse?.clientTitle : null) // PBI-266617 Changes
    });
  }

  assignApprover() {
    if (this.invalidClientDuns) return;
    this.resetApproverChecks();

    let approver: any = this.approverFormGroup.get('approver').value;
    let approverIdArray = []
    for (let k in approver['approver']) {
      if (approver['approver'][k].name != '') {
        approverIdArray.push({
          "approverId": approver['approver'][k].id,
          "level": approver['approver'][k].level
        })
      }
    }
    /********** Commented due to PBI-266617 Changes
    this.selectedOrganizationName = this.approverFormGroup.get('organizationName').value;
    this.selectedProductName = this.approverFormGroup.get('productName').value;
    this.selectedClientName=  this.approverFormGroup.get('clientName').value;
    ******** Commented due to PBI-266617 Changes */

    if (this.isEntityLink) {
      // PBI-266617 Changes Starts
      const selectedOrgName = this.approverFormGroup.get('organizationName').value;
      const selectedProdName = this.approverFormGroup.get('productName').value;
      const selectedClntName = this.approverFormGroup.get('clientName').value;

      if (selectedOrgName && this.selectedOrganizationId < 1) {
        this.invalidOrganizationName = true;
        return;
      }

      if (selectedProdName && this.selectedProductId < 1) {
        this.invalidProductName = true;
        return;
      }

      if (selectedOrgName === "" && selectedProdName && this.selectedProductId > 0) {
        this.missingOrganizationName = true;
        return;
      }

      if (selectedClntName === "") {
        this.invalidClientName = true;
        this.resetClientData();
        return;
      }

      if (selectedProdName === "") {
        this.selectedProductId = 0;
        this.selectedProductName = "";
      }

      if (selectedOrgName === "") {
        this.selectedProductId = 0;
        this.selectedProductName = "";
        this.selectedOrganizationId = 0;
        this.selectedOrganizationName = "";
      }

      if (selectedClntName && this.selectedClientId < 1) {
        this.invalidClientName = true;
        return;
      }
      // PBI-266617 Changes Ends

      /********** Commented due to PBI-266617 Changes
      if (this.selectedOrganizationName) {
        let orgId = this.emailTemplateService.fetchIdForRequestByTitle(this.orgSuggestion, this.selectedOrganizationName);
        if (orgId > 0) { this.selectedOrganizationId = orgId; }
        else {this.selectedOrganizationId = 0;this.invalidOrganizationName = true; return;}
      }
      else {this.selectedOrganizationId = 0; }
  
      if (this.selectedProductName) {
        let productId = this.projectService.fetchIdForRequest(this.lkpProdSuggestion, this.selectedProductName);
        if (productId > 0) { this.selectedProductId = productId; }
        else {this.selectedProductId = 0; this.invalidProductName = true; return;}
  
        if(this.selectedOrganizationName === "") {this.missingOrganizationName = true; return;}
      } else {this.selectedProductId = 0; }
   
      if (this.selectedClientName) {
        let clientId = this.projectService.fetchIdForRequest(this.clientSuggestion, this.selectedClientName);
        if (clientId > 0) { this.selectedClientId = clientId; }
        else {this.selectedClientId = 0; this.invalidClientName = true; return;}
      } else {this.selectedClientId = 0; this.invalidClientName = true; return;}
      ******** Commented due to PBI-266617 Changes */
    }

    if (!this.approverFormGroup.valid) return;

    let responseDetails: any = {
      clientId: this.selectedClientId,
      clientTitle: this.selectedClientName,
      organizationId: this.selectedOrganizationId,
      organizationTitle: this.selectedOrganizationName,
      productId: this.selectedProductId,
      productTitle: this.selectedProductName,
      clientDunsId: this.selectedClientDunsId,
      clientGudId: this.selectedClientGudId,
      relationshipManagerName: this.selectedRMName,
      relationshipManagerEmail: this.selectedRMEmail
    }

    if (this.selectedClientId) {
      let client: any = this.clientSuggestion.filter(
        item => item.id == this.selectedClientId
      )[0];

      if (!client) {
        this.invalidClientName = true;
        this.resetClientData();
        return;
      }
      responseDetails['mdmClientMasterId'] = client.mdmClientMasterId > 0 ? client.mdmClientMasterId : 0;
    }

    this.pageTemplateService.formVal = responseDetails;
    if (this.isDynamicApprover) {
      responseDetails['approvalSchema'] = this.approverFormGroup.get('approvalSchema').value,
        this.pageTemplateService.formVal.approvers = approverIdArray;
    }

    this.resetApproverData();
    this.approverFormGroup.reset();

    this.cancel.emit();
    if (this.pageResponse) {
      this.navigateToResponse(this.pageResponse.id);
    } else {
      this.getNewRMInfoToCreate();
    }
  }

  private getNewRMInfoToCreate(): any {
    this.clientCommitmentService.getNewRMByClientDuns(this.selectedClientDunsId)
    .pipe().subscribe((res: any) => {
      this.pageTemplateService.formVal.newRMName = res?.name;
      this.pageTemplateService.formVal.newRMEmail = res?.email;
    },
      (err) => {
        console.log(err)
      },
      () => {
        this.createResponse();
      });
  }

  resetApproverChecks() {
    this.missingOrganizationName = false;
    this.invalidOrganizationName = false;
    this.invalidProductName = false;
    this.invalidClientName = false;
    this.invalidClientDuns = false;
  }

  resetClientData() {
    this.selectedClientId = 0;
    this.selectedClientName = "";
    this.selectedClientValue = "";
    this.selectedClientDunsId = "";
    this.selectedClientGudId = "";
    this.selectedRMName = "";
    this.selectedRMEmail = "";
  }

  resetApproverData() {
    this.selectedClientId = 0;
    this.selectedClientName = ""
    this.selectedOrganizationName = "";
    this.selectedClientValue = "";
    this.selectedProductId = 0;
    this.selectedProductName = "";
    this.selectedRMName = "";
    this.selectedRMEmail = "";

    if (!this.canRequest)
      this.selectedOrganizationId = this.organizationDetails.organization;
    else
      this.selectedOrganizationId = 0;
  }

  createResponse() {
    this.router.navigate(['/self-service/pageTemplate', environment.clientCommitmentPageTemplateId]);
  }

  navigateToResponse(pageResponseId: number) {
    let url: string = '/self-service/pageTemplate/' + environment.clientCommitmentPageTemplateId + "/response"
    this.router.navigate([url, pageResponseId]);
  }

  cancelApproverForm() {
    this.resetApproverChecks();
    this.resetApproverData();
    //  this.pageResponse = undefined;
    // this.pageTemplateResponseId = 0;
    this.pageTemplateService.formVal = undefined;
    this.approverFormGroup.reset();
    this.typeaheadLoadingClients("", true);
    this.cancel.emit();
  }

  formInitialized(name: string, form: any) {
    this.approverFormGroup.setControl(name, form);
    this.formValidationDynamic();
  }

  formValidationDynamic() {
    this.reEvaluateValidators('approver', 'approver');
  }

  reEvaluateValidators(fieldName, isNested: any = 'no') {
    let fieldCtrl: any;
    fieldCtrl = this.approverFormGroup.controls[fieldName]
    if (fieldCtrl && fieldCtrl.controls && fieldCtrl.controls[fieldName].length > 0) {
      fieldCtrl.setValidators(this.validation(fieldName, this.approverFormGroup ? this.approverFormGroup.get(fieldName).value : []));
      fieldCtrl.updateValueAndValidity();
    }
  }

  onClientSelect = (event: any) => {
    this.selectedClientValue = '';
    this.selectedClientId = 0;
    if (this.approverFormGroup.valid) {
      this.selectedClientId = event.id;
      this.selectedClientName = event.title;
      this.selectedRMName = event.relationshipManagerName;
      this.selectedRMEmail = event.relationshipManagerEmail;
      this.invalidClientName = false;
      this.selectedClientValue = event.titleWithDuns;
      this.selectedClientDunsId = event.dunsId;
      this.selectedClientGudId = event.gud;
      this.checkValidDuns();
    }
  }

  checkValidDuns() {
    if (this.selectedClientDunsId === null || this.selectedClientDunsId === undefined || isNaN(+this.selectedClientDunsId)) {
      this.invalidClientDuns = true;
      return;
    }
    else {
      this.invalidClientDuns = false;
    }
  }

  validation(controlName: string, values: FormArray): ValidationErrors {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      let array: any = [];
      for (let k in values[controlName]) {
        let keyName = '';
        let fieldValue = values[controlName][k];
        if (controlName == 'approver') {
          if (fieldValue.level > 0)
            keyName = fieldValue.id + '##' + fieldValue.level;
          else
            return { 'invalid': true };
        }
        else {
          keyName = fieldValue.id
        }

        // if(!REGEX_PATTERNS.name_title_validator.test(fieldValue.name)) {
        //   return { 'notpermitted': true };
        // }

        if (fieldValue.name != '') {
          if (array[keyName])
            array[keyName].push({ "index": k });
          else {
            array[keyName] = [];
            array[keyName].push({ "index": k });
          }
        }
      }
      for (let i in array) {
        if (array[i].length > 1) {
          return { 'duplicate': true };
        }
      }
      return null;
    }
  }

  ngAfterViewInit(): void {
    const focusableElements =
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
    const modal = document.getElementById('newCommitment'); // select the modal by it's id

    const firstFocusableElement = modal.querySelectorAll(focusableElements)[0]; // get first element to be focused inside modal

    (firstFocusableElement as HTMLElement).focus();
  }

  public keyDown(keyboardEvent: KeyboardEvent): void {
    const focusableElements =
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
    const modal = document.getElementById('newCommitment');
    const firstFocusableElement = modal.querySelectorAll(focusableElements)[0]; // get first element to be focused inside modal
    const focusableContent = modal.querySelectorAll(focusableElements);
    let lastFocusableElement = focusableContent[focusableContent.length - 1]; // get last element to be focused inside modal
    if (this.selectedClientId < 1) {
      lastFocusableElement = focusableContent[focusableContent.length - 2];
    }
    if (keyboardEvent.shiftKey) { // if shift key pressed for shift + tab combination
      if (document.activeElement === firstFocusableElement) {
        (lastFocusableElement as HTMLElement).focus(); // add focus for the last focusable element
        keyboardEvent.preventDefault();
      }
    } else { // if tab key is pressed
      if (document.activeElement === lastFocusableElement) { // if focused has reached to last focusable element then focus first focusable element after pressing tab
        (firstFocusableElement as HTMLElement).focus(); // add focus for the first focusable element
        keyboardEvent.preventDefault();
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}

import { Component, Input, OnInit, EventEmitter, ViewChild, AfterViewInit, OnDestroy, ElementRef } from "@angular/core";
import { FormsService } from 'src/app/services/forms.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';
import { Location, DatePipe } from '@angular/common';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { ManageFormsService } from 'src/app/admin/manageForms/services/manageForms.service';
import { IRespondent } from 'src/app/shared/user';
import { FileValidators } from 'src/app/self-service/components/manageFileUploads/validators/file-validators';
declare var $: any;
import { MasterService } from 'src/app/services/master.service';
import { UserService } from 'src/app/services/user.service';
import { MAX_LENGTHS, REGEX_PATTERNS, REGEX_VALIDATION_MESSAGE } from 'src/app/core/constants';
import { isNullOrUndefined } from 'util';
import { Utils } from 'formiojs';
import { PermissionsService } from 'src/app/services/permissions.service';
import { DrmsFileService } from '../../services/file.service';
import { SurveyService } from 'src/app/services/survey.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { isUndefined } from 'util';
import { IResponseStatusResloved } from '../../shared/status';
import { EMPTY, forkJoin, interval, of, Subject } from 'rxjs';
import { PdfGenerateService } from 'src/app/services/pdf-generate.service';
import { PdfGenrateComponent } from '../../pdf-genrate/pdf-genrate.component';
import { FavoritesService } from "src/app/services/favorites.service";
import { ContentService } from "src/app/services/content.service";
import { CustomComponentsService } from 'src/app/services/custom-components.service'
import { catchError, map, switchMap, takeUntil } from "rxjs/operators";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'form-container',
  templateUrl: './form-container.component.html'
})
export class FormContainerComponent implements OnInit, AfterViewInit, OnDestroy {
  commentFile: any;
  commentFileName: string;
  commentId: number;
  commentResponseId: number;
  isCommentError: boolean = false;
  commentErrorMsg: string = "";
  isCommentSubmit: boolean = false;
  commentLength: number;
  commentCnt: number = 0;
  currentFieldSet2 = { components: [] };
  loadDrawer: boolean;
  isIntake: boolean = false;
  isRejected: boolean;
  pageStatus: any = {};
  initialLoad: boolean;
  showConditionalPages: boolean = false;
  elementToDisplay: any;
  responseEncryptedFields: any;
  responseEncryptedFieldsData: any = {}
  responseEncryptedFieldsDataInSession: string[] = [];
    eventDataCopy: any;

  ngAfterViewInit(): void {
    // this.updateKeysInTable();
  }
pdfAllowedToDownload:boolean=true;
  @Input('form') formJson;
  formJsonCopy: any;
  formChange: boolean = false;
  uploadedAnswerJson: boolean = false;
  filename: any = '';
  deligateForm: FormGroup;
  uploadAnswer: FormGroup;
  commentAnswer: FormGroup;
  jsonAnswer: any;
  @Input() readonly: boolean;
  isshowDelegateMessage: boolean = false;
  showDelegateMessage: any = '';
  @Input() formdata: any;
  showSuccessDraft: boolean = false;;
  isShowProjectDetail: boolean = true;
  @ViewChild('formio') formioForm;
  customeKeyArray: any = [];
  refreshForm: any;
  refreshForm2: any;
  fieldSets = [];
  currentFieldSet: any = {};
  sourceFormParams: any = {};
  allFormData: any = { components: [] };
  formwizard: any;
  exportHtmlPdf: boolean = false;
  formPdfLoaded: boolean = false;
  currentFieldSetIndex: number = 0;
  assignMe: boolean = false;
  answerJson: any = [];
  serviceAnswerJson: any = {};
  keyInformation: any = {};
  answer: any = {};
  pdfanswer: any = {};
  pdfAnswerDownload: any = {};
  allowedExtension = ['json'];
  answer2: any = {};
  statusCompleted: any = {};
  completedForm: any;
  assignedToId: number;
  isClicked: boolean = false;
  isShowPage: boolean = true;
  isError: boolean;
  errorMsg: string = '';
  delegatedSuccessMsg: string = '';
  usersByClient: any = [];
  usersByOrganization: any = [];
  tables: any = [];
  currentSlider: any = 0;
  currentColumns: any = [];
  respondentUsers: IRespondent;
  dependantIdMap: any = [];
  dependantRowIdMap: any = [];
  tableJson: any = {}
  keysInTable: any = [];
  tableFieldsJson: any = [];
  labelForKey: any = [];
  keyInformationCollection: any = [];
  gridId: any = [];
  keysToSubmitTable: any = {};
  // Approval Changes
  showApproverButtons: boolean;
  approvalComments: string;
  approvalForm: FormGroup;
  isSubmitted: boolean;
  isApproved: boolean;
  isSubmitClicked: boolean;
  _maxLengths: any;
  showSuccess: boolean = false;
  formIncomplete: boolean = false;
  showComment: boolean = true;
  parentJson: any = {};
  preFilledJson: any = {};
  formedit: number = 0;
  KeysInPageComponents = [];
  nextstepOnDiscard: any = 0;
  isResponseModalShow: boolean;
  pageCount: number;
  formbuilder = {
    fileService: new DrmsFileService()
  };
  callBackEvent: any = {};
  changedData: any = {};
  changeNewData: any;
  isPreview: boolean = false;
  isUnauthenticated: boolean = false;
  draftData: any;
  externalJson: any = [];
  isResponse: boolean = false;
  draftModeMessage: any = '';
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('inputCommentFile') commentFileInput: ElementRef;
  /* Comment History Changes - Variables Start */
  showCommentBadge: boolean = false;
  commentCatHistory: string = '';
  commentBadgeCount: number = 0;
  commentValue: string;
  commentHistoryList;
  commentTableHeaders = ["Page Name", "Category", "Comment", "Added On", "Added By"];
  commentTableKeys = ["pageName", "category", "comment", "createdOn", "createdByName"];
  commentHistFilterOptions: string[] = [];
  commentHistSelectedPgName: string = "";
  /* Comment History Changes - Variables End */
  selectApproverComponentKeys = [];
  selectClientComponentKeys = [];
  isHistory: boolean = false;
  missingMessage: any = '';
  redirectToPage: any = '';
  isPdfDownloaded: boolean = true;
  redirectionNeeded: boolean = false;
  isApproverFlow: boolean = false;
  isRejectedError: boolean = false;
  commentCat: string = 'Comment';
  completeApproverList: any = [];
  dataChange: boolean = false;
  displayColumns = ["Level", "Sent Back To User"];
  keys = ['level', 'name'];
  selectedRadioValue: any = { "value": '' };
  sentBackApprover: any;
  commentHistCatName: any = '';
  catSelection: any = {};
  isFavorite: boolean = false;
  eventData: any;

  validFile: boolean = false;
  validFileType: boolean = true;
  validFileName: boolean = true;

  validCommentFileExtension: boolean = true;
  validCommentFileType: boolean = true;
  validCommentFileName: boolean = true;

  content: string[] = [];
  submitLabel: string = '';
  nextLabel: string = '';
  cancelLabel: string = '';
  confirmLabel: string = '';
  continueLabel: string = '';
  submitModalTitle: string = '';
  submitModalSubitle: string = '';
  submitModalMessage: string = '';
  backIntakeList: string = '';
  _maxLength: any;
  sensitiveInformtion: any;
  dynamicParentJson: any = {};

  validators = REGEX_PATTERNS;
  regexValidationMessage: any;

  public changeApprover$ = new Subject();
  public submitAnswer$ = new Subject();
  private destroy$ = new Subject<boolean>();
  changeApproverSub = this.changeApprover$.pipe(
    switchMap((command: any) => {
      return this.changeResponseApprover(command.body, command.approverList, command.draftMode);
    })
  ).subscribe();

  submitAnswerSub = this.submitAnswer$.pipe(
    switchMap((command: any) => {
      return this.submitAnswer(command.body, command.draftMode);
    })
  ).subscribe();

  @ViewChild('pdfGenerated') addPdfGenerated: PdfGenrateComponent;
  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    private formService: FormsService,
    private location: Location,
    private _manageForm: ManageFormsService,
    private masterService: MasterService,
    private userService: UserService,
    private surveyService: SurveyService,
    private fb: FormBuilder,
    private permissionService: PermissionsService,
    public datepipe: DatePipe,
    public projectsService: ProjectsService,
    private _pdfGenerateService: PdfGenerateService,
    private favoritesService: FavoritesService,
    private contentService: ContentService,
    private customComponentsService: CustomComponentsService,
  ) { this._maxLength = MAX_LENGTHS; }
  selectedRadioValueUpdate(val: any) {
    this.selectedRadioValue = { "value": val };

  }
  checkDefaultPreFilledData(k: any) {
    const fieldSetKey = this.fieldSets[k].key;
    if (!(this.serviceAnswerJson[fieldSetKey])) {
      this.serviceAnswerJson[fieldSetKey] = {}
    }

    const keysInPageComponents = this.KeysInPageComponents[fieldSetKey];
    for (let l in keysInPageComponents) {
      if (this.serviceAnswerJson[fieldSetKey][keysInPageComponents[l].key] !== undefined) {
        continue;
      }

      const componentType = keysInPageComponents[l].type;
      const componentKey = keysInPageComponents[l].key;
      const defaultValue = keysInPageComponents[l].defaultValue;
      const hasDefaultValue = (defaultValue && defaultValue != null && defaultValue != '') || keysInPageComponents[l].isDefaultValue;
      const hasDefaultDayValue = (defaultValue == null || defaultValue == '' || defaultValue == '00/00/0000') && componentType === 'day';
      if ((hasDefaultValue && !hasDefaultDayValue) && componentType != 'selectboxes' && componentType != 'datagrid') {
        if (this.preFilledJson[componentKey] !== undefined){
          this.serviceAnswerJson[fieldSetKey][componentKey] = this.preFilledJson[componentKey];
        }
        else {
          this.serviceAnswerJson[fieldSetKey][componentKey] = defaultValue;
        }
        continue;
      }

      if (componentType == 'selectboxes') {
        if (defaultValue == null || (defaultValue[''] && !defaultValue[''])) {
          this.serviceAnswerJson[fieldSetKey][componentKey] = {};
          for (let jj in keysInPageComponents[l].values) {
            this.serviceAnswerJson[fieldSetKey][componentKey][keysInPageComponents[l].values[jj].value] = false;
          }
        }
        else {
          this.serviceAnswerJson[fieldSetKey][componentKey] = defaultValue;
        }
        if (this.preFilledJson && this.preFilledJson[componentKey]) {
          this.serviceAnswerJson[fieldSetKey][componentKey] = this.preFilledJson[componentKey];
        }
        if (this.parentJson && this.parentJson[componentKey]) {
          this.serviceAnswerJson[fieldSetKey][componentKey] = this.parentJson[componentKey];
        }
      }
    }
  }

  checkCalculatedData() {
    return;
  }

  updateKeysInTable() {
    this.selectApproverComponentKeys = [];
    this.isPreview = this._manageForm.isPreview;
    for (let k in this.fieldSets) {
      let source: any = {};
      source = this.fieldSets[k].components;
      let components = [];

      Utils.eachComponent(source, (component) => {
        var isDefaultValue = false;
        if (component.defaultValue && component.defaultValue != null && component.defaultValue != '' && component.input || (this.preFilledJson && !isUndefined(this.preFilledJson[component.key]))) {
          isDefaultValue = true;
        }
        if (component.type == 'sensitiveinformation') {
          if (!this.sensitiveInformtion) {
            this.sensitiveInformtion = [];
          }
          this.sensitiveInformtion[component.key] = '';
        }
        if (component.type == 'selectboxes') {
          isDefaultValue = true;
        }

        if (component.properties && component.properties.hasOwnProperty('type') && component.properties.type == "approver") {
          this.selectApproverComponentKeys.push(component.key);
          // return false;
        }
        if (component.properties && component.properties.hasOwnProperty('type') && component.properties.type == "client") {
          this.selectClientComponentKeys.push(component.key);
          // return false;
        }
        if (component.type == 'datagrid') {
          //   isDefaultValue = false;
          if (!this.gridId[this.fieldSets[k].key]) {
            this.gridId[this.fieldSets[k].key] = [];
          }
          this.gridId[this.fieldSets[k].key][component.key] = true;
        }
        this.labelForKey[component.key] = component.label;
        let widget = ''
        if (component.widget)
          widget = component.widget
        let disabled = false;
        if (component.disabled)
          disabled = component.disabled;
        let properties: any = '';
        if (component.properties)
          properties = component.properties
        let conditional: any = 'No';
        if (component.conditional) {
          conditional = component.conditional
        }
        let typeOfSensitiveInformation = 'No';
        if (component.typeOfSensitiveInformation) {
          typeOfSensitiveInformation = component.typeOfSensitiveInformation
        }
        let customConditional: any = 'No';
        if (component.customConditional) {
          customConditional = component.customConditional
        }
        let calculateValue: any = 'No';
        if (component.calculateValue && component.allowCalculateOverride) {
          calculateValue = component.calculateValue
          isDefaultValue = true;
        }
        this.keyInformationCollection[component.key] = { key: component.key, label: component.label, id: component.id, type: component.type, input: component.input, defaultValue: component.defaultValue, values: component.values, hidden: component.hidden, isDefaultValue: isDefaultValue, widget: widget, disabled: disabled, 'properties': properties, 'conditional': conditional, 'customConditional': customConditional, "calculateValue": calculateValue, "typeOfSensitiveInformation": typeOfSensitiveInformation };

        components = [...components, { key: component.key, label: component.label, id: component.id, type: component.type, input: component.input, defaultValue: component.defaultValue, values: component.values, hidden: component.hidden, isDefaultValue: isDefaultValue, disabled: disabled, calculateValue: calculateValue, 'conditional': conditional, 'customConditional': customConditional }];
      }, true);

      this.KeysInPageComponents[this.fieldSets[k].key] = components;
      //
      this.checkDefaultPreFilledData(k);
    }

  }

  nextTable(counter: any) {
    this.currentSlider = counter;
    this.slideDynamic(0)
  }

  isNextPage() {
    this.isShowPage = false;
    for (let k: any = this.currentFieldSetIndex + 1; k < this.fieldSets.length; k++) {
      if (this.fieldSets[k].tempshow != false || this.showConditionalPages == true) {
        this.isShowPage = true;
      }
    }
  }

  evaluatePageFieldValidatio(data: any, element) {
    if (element.conditional && element.conditional.show != null) {
      element.tempshow = !element.conditional.show;
      if ((data && data[element.conditional.when] && data[element.conditional.when].toString() == element.conditional.eq.toString()) || ((isUndefined(this.keyInformationCollection[element.conditional.when]) || (this.keyInformationCollection[element.conditional.when] && (this.keyInformationCollection[element.conditional.when].type == 'checkbox' || this.keyInformationCollection[element.conditional.when].type == 'selectboxes'))) && data && data[element.conditional.when] && data[element.conditional.when][element.conditional.eq]) || (data && !data[element.conditional.when] && !element.conditional.eq)) {
        return element.conditional.show;
      }
      return element.tempshow;
    } else if (element.customConditional) {
      var a = '';
      if (data) {
        a = element.customConditional;
      } else {
        a = `var data= {};${element.customConditional}`;
      }
      var show = true;
      var b = eval(a);
      return show;
    }
    else {
      return true;
    }
  }

  pageConditional(data: any, finalUpdate: boolean = false) {
    for (let k in this.fieldSets) {
      this.fieldSets[k].tempshow = this.evaluatePageFieldValidatio(data, this.fieldSets[k]);
    }
    for (let kk in this.fieldSets) {
      if (data && this.fieldSets[kk].nextPage) {
        var next = '';
        var nextPage = eval(this.fieldSets[kk].nextPage);
        let k: number = 0;
        for (k = parseInt(kk) + 1; k < this.fieldSets.length; k++) {
          if (nextPage == 'end' || nextPage != this.fieldSets[k].key) {
            this.fieldSets[k].tempshow = false;
          } else if (nextPage == this.fieldSets[k].key) {
            break;
          }
        }
      }
    }

    /*-------------------*/
    let dataUpadted: boolean = false;
    for (let k in this.fieldSets) {
      if (this.fieldSets[k].tempshow == false && !(this.fieldSets[k].properties && this.fieldSets[k].properties.clearDataOnHidden && this.fieldSets[k].properties.clearDataOnHidden == 'false')) {
        this.fieldSets[k].status = "";
        for (let i in this.KeysInPageComponents[this.fieldSets[k].key]) {
          let key = this.KeysInPageComponents[this.fieldSets[k].key][i].key;
          if (data && (data[key])) {
            delete data[key];
            dataUpadted = true;
          }
          if (this.answer && (this.answer[key]))
            delete this.answer[key];
        }
      }
    }
    /*------------------------*/
    if (!dataUpadted) {
      let pageCount = this.formJson.components.filter((data) => {
        if (data.tempshow) {
          return true;
        }
      });
      let completedPage = this.formJson.components.filter((data) => {
        if (data.status == 'Completed') {

          return true;
        }
      });
      this.completedForm = completedPage.length;
      this.pageCount = pageCount.length;
      this.isNextPage();
      this.updateData(finalUpdate);
    } else {
      this.pageConditional(data, finalUpdate)
    }
  }

  updateData(finalUpdate: boolean) {
    for (let k in this.fieldSets) {
      if (this.fieldSets[k].tempshow == false && !(this.fieldSets[k].properties && this.fieldSets[k].properties.clearDataOnHidden && this.fieldSets[k].properties.clearDataOnHidden == 'false')) {
        if (finalUpdate) {
          this.fieldSets[k].show = this.fieldSets[k].tempshow;
          if (this.serviceAnswerJson[this.fieldSets[k].key]) {
            delete this.serviceAnswerJson[this.fieldSets[k].key];
          }
        } else {
          for (let i in this.KeysInPageComponents[this.fieldSets[k].key]) {
            let key = this.KeysInPageComponents[this.fieldSets[k].key][i].key;
            if (this.answer.data && this.answer.data[key]) {
              delete this.answer.data[key];
            }
          }
        }
      } else {
        if (finalUpdate) {
          this.fieldSets[k].show = this.fieldSets[k].tempshow;
        }
      }
    }
  }
  preDynamicTable(counter: any) {
    this.currentSlider = counter;
    this.slideDynamic(1)
  }
  nextDynamicTable(counter: any) {
    this.currentSlider = counter;
    this.slideDynamic(0)
  }
  preTable(counter: any) {
    this.currentSlider = counter;
    this.slideDynamic(1)
  }

  slide(number: any) {
  }
  closePopUp() {
    $('#popup').modal('hide');
  }
  comparePopup() {
    // alert($('#input').val())
    this.elementToDisplay = this.keyInformationCollection[$('#inputmockClick').val()]
    $('#popup').modal('show')
  }
  slideDynamic(number: any) {
    for (let l = 0; l < this.currentColumns.length; l++) {
      if (l == this.currentSlider) {
        if (this.currentColumns[this.currentSlider].columnsCount < 5) { }
        else {
          if (number == 0) {
            this.currentColumns[this.currentSlider].sliderStart = this.currentColumns[this.currentSlider].sliderStart - 1;
            this.currentColumns[this.currentSlider].sliderEnd = this.currentColumns[this.currentSlider].sliderEnd - 1;
            if (this.currentColumns[this.currentSlider].sliderStart <= 2) {
              this.currentColumns[this.currentSlider].sliderStart = 1;
              this.currentColumns[this.currentSlider].sliderEnd = 5;
            }
          } else {
            this.currentColumns[this.currentSlider].sliderStart = this.currentColumns[this.currentSlider].sliderStart + 1;
            this.currentColumns[this.currentSlider].sliderEnd = this.currentColumns[this.currentSlider].sliderEnd + 1;
            if (this.currentColumns[this.currentSlider].sliderEnd >= this.currentColumns[this.currentSlider].columnsCount) {
              this.currentColumns[this.currentSlider].sliderStart = this.currentColumns[this.currentSlider].columnsCount - 2;
              this.currentColumns[this.currentSlider].sliderEnd = this.currentColumns[this.currentSlider].columnsCount;
            }
          }
        }
      }

      for (let k = 3; k <= this.currentColumns[l].columnsCount; k++) {
        if (this.currentColumns[l].isSlider == 'yes') {
          if (this.currentColumns[l].typeOfTheTable == 'dynamic') {
            if (k >= this.currentColumns[l].sliderStart && k <= this.currentColumns[l].sliderEnd) {
              $('.dynamictable > table:eq(' + (l) + ')  td:nth-child(' + k + ')').show();
            } else {
              $('.dynamictable > table:eq(' + (l) + ') td:nth-child(' + k + ')').hide();
            }
          }
          else {
            if (k >= this.currentColumns[l].sliderStart && k <= this.currentColumns[l].sliderEnd) {
              $('table:eq(' + (l - 1) + ')  td:nth-child(' + k + ')').show();
            } else {
              $('table:eq(' + (l - 1) + ') td:nth-child(' + k + ')').hide();
            }
          }
        }
      }
      if (this.formedit == 1) {
        if (this.currentColumns[l].typeOfTheTable == 'dynamic') {
          $('.dynamictable > table:eq(' + this.currentSlider + ')  td:nth-child(' + this.currentColumns[this.currentSlider].columnsCount + ') input:eq(0)').focus();
        } else {
          $('table:eq(' + (l - 1) + ')  td:nth-child(' + this.currentColumns[this.currentSlider].columnsCount + ') input:eq(0)').focus();
        }
      }
      if (this.currentColumns[l].hideRow && this.currentColumns[l].hideRow != 'no') {
        let rows = this.currentColumns[l].hideRow;
        let rowArray = rows.split(',');
        for (let m = 0; m < rowArray.length; m++) {
          // //// //
          if (this.currentColumns[l].typeOfTheTable == 'dynamic') {
            $('.dynamictable > table:eq(' + (l) + ')  tr:nth-child(' + rowArray[m] + ')').hide();
          } else {
            $('table:eq(' + (l - 1) + ')  tr:nth-child(' + rowArray[m] + ')').hide();
          }
        }
      }
    }
  }
  imageValueForPdf(answer: any, component: any) {
    let imageUrl = ''
    if (answer) {
      if (component.image)
        imageUrl = '<a href = "' + answer[0].url + '" target="_blank" width="120px" height = "120px" />' + component.label + '</a>';
      else {
        imageUrl = '<a href = "' + answer[0].url + '" target="_blank">Download</a>';
      }
    }
    return imageUrl;
  }
  radioButtonValueForPdf(answer: any, options: any) {
    let returnAnswer = '';
    for (let k in options.values) {
      if (answer.toString() == options.values[k].value) {
        returnAnswer = options.values[k].label;
      }

    }
    return returnAnswer;
  }

  dateValue(value, format) {
    if (value && value != '') {
      return (this.datepipe.transform(new Date(value), format))
    } else {
      return '';
    }
  }
  dateValueForPdf(answer: any, format) {
    return this.dateValue(answer, format)
  }
  selectBoxValueForPdf(answer: any, options: any) {
    let returnanswer = '';
    for (let k in options.values) {
      if (answer && answer[options.values[k].value.trim()]) {
        if (returnanswer == '') {
          returnanswer = options.values[k].label
        } else {
          returnanswer = returnanswer + ',' + options.values[k].label;
        }
      }
    }
    return returnanswer;
  }
  exportJson() {
    this.resetErrorAndSuccessMsg();

    this.formService.getFormJson(this.formdata.id).subscribe(
      data => {
        if (data && data.formJSON) {

          let newBlob = new Blob([data.formJSON], { type: "application/json" });

          const nav = (window.navigator as any);
          if (nav && nav.msSaveOrOpenBlob) {
            nav.msSaveOrOpenBlob(newBlob);
            //alert('done');
          } else {
            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob);
            const filename = this.formdata.title + '.json';
            const link = document.createElement('a');
            link.href = data;
            link.download = filename;
            // this is necessary as link.click() does not work on the latest firefox
            link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

            setTimeout(function () {
              // For Firefox it is necessary to delay revoking the ObjectURL
              window.URL.revokeObjectURL(data);
            }, 100);
          }
        }
      }
    );
  }

  downloadAllAttacments() {
    this.formService.getAttachedFiles(this.formdata.id).subscribe(
      data => {
        console.log(data.body);
        let newBlob = new Blob([data.body], { type: "application/x-zip-compressed" });

        const nav = (window.navigator as any);
        if (nav && nav.msSaveOrOpenBlob) {
          nav.msSaveOrOpenBlob(newBlob);
        } else {
          const data = window.URL.createObjectURL(newBlob);
          const filename = 'attachedFiles.zip';
          const link = document.createElement('a');
          link.href = data;
          link.download = filename;
          link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
          setTimeout(function () {
            window.URL.revokeObjectURL(data);
          }, 100);
        }
      },
      error => {
        this.isError = true;
        this.errorMsg = "Files not found for this form";
      }
    );
  }

  serviceAnswerPopulate(update: boolean = false) {
    for (let l in this.KeysInPageComponents) {
      this.serviceAnswerJson[l] = {};
      for (let k in this.KeysInPageComponents[l]) {
        let key = this.KeysInPageComponents[l][k].key;
        this.serviceAnswerJson[l][key] = this.answer2.data[key]
        this.formJson.components.filter(component => component.type !== "button").forEach(element => {
          if (element.key == l) {
            //    element.fieldData.data = submittedData;
            element.status = 'skipped';
          }
        });
        this.statusCompleted[l] = 'skipped';
      }
    }
    if (update) {
      this.answerDataPopulation();
      this.uploadedAnswerJson = true;
      this.draftData = this.answer2;
      this.saveDraft();
    }
  }
  initUploadAnswer() {
    // this.uploadAnswer.reset();
    this.uploadAnswer = this.fb.group({
      fileCtrl: [null, [Validators.required, FileValidators.ValidateExtension, FileValidators.ValidateFileSize, FileValidators.ValidateContentType()]],
    });
  }

  initComment() {
    this.commentAnswer = this.fb.group({
      commentCtrl: [null, [Validators.required, FileValidators.ValidateExtension, FileValidators.ValidateFileSize, FileValidators.ValidateContentType()]],
    });
  }
  importAnswer() {
    this.filename = ''
    this.initUploadAnswer();
    $('#uploadAnswer').modal('show')
  }
  exportAnswer() {
    let newBlob = new Blob(["{}"], { type: "application/json" });
    //Added Try..Catch block for bug_214325
    try {
      if (this.answer2 && this.answer2.data) {
        newBlob = new Blob([JSON.stringify(this.answer2.data)], { type: "application/json" });
      } else if (this.answer) {
        let DataForExport: any = {}
        for (let i in this.answer) {
          for (let k in this.answer[i]) {
            DataForExport[k] = this.answer[i][k];
          }
        }
        newBlob = new Blob([JSON.stringify(DataForExport)], { type: "application/json" });
      }
    }
    //Added code for bug_214325
    catch (e) {
      //console.log(e);
      newBlob = new Blob([JSON.stringify(this.answer.data)], { type: "application/json" });
    }
    const nav = (window.navigator as any);
    if (nav && nav.msSaveOrOpenBlob) {
      nav.msSaveOrOpenBlob(newBlob);
      //alert('done');
    } else {
      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);
      const filename = this.formdata.title + '_answers.json';
      const link = document.createElement('a');
      link.href = data;
      link.download = filename;
      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
      }, 100);
    }

  }
  downLoadType(event: any) {
    if (event.type == 'json') {
      this.exportJson();
    }
    else if (event.type == 'pdf') {
      this.exportPDF2()
    }
    else if (event.type == 'exportAnswer') {
      this.exportAnswer()
    }
    else if (event.type == 'importAnswer') {
      this.importAnswer();
    }
    else if (event.type == 'attachments') {
      this.downloadAllAttacments();
    }
  }
  exportPDF2(isSkip: boolean = false) {
    this.exportHtmlPdf = true;
    this.exportPDF(isSkip);
  }
  exportPDF(isSkip: boolean) {
    let allFormData = { components: [] };
    if (this.formJsonCopy && this.formJsonCopy.components) {
      this.formJsonCopy.components.filter((data) => {
        if (data.show == false) {
          return false;
        }
        if (data.type == 'panel' || data.type == 'fieldset') {
          data.type = "fieldset"
          data.legend = data.title

          allFormData.components.push(data);
          return true;
        }
      });
      let allFormDataString = JSON.stringify(allFormData);

      //   allFormDataString = allFormDataString.split('choicesjs').join('html5');
      this.allFormData = JSON.parse(allFormDataString);
      // // //console.log(allFormDataString);
      //  this.answerDataPopulation();
      this._manageForm.setResponseData({
        form: this.allFormData, 
        submission: JSON.parse(JSON.stringify(this.pdfAnswerDownload)), 
        title: this.formdata.title, 
        id: this.formdata.id, 
        isSkip: isSkip.toString() 
      });
      if (!isSkip) {
        this.addPdfGenerated.triggerPDF(this._manageForm.getResponseData());
      }

      let string = '';
      // this.exportHtmlPdf = true;
    }
    else {
      let string = '';
      // this.exportHtmlPdf = true;
    }
  }
  saveDraft() {
    if (this.draftData && this.draftData.data) {
      let a = interval(500).subscribe(x => {
      this.onSubmit(this.draftData, true)
        this.isSubmitClicked = false;
        a.unsubscribe();
      });
    }
  }

  change(evt: any) {
    if (evt.data && evt.changed != undefined) {
      // this.answer = evt.data;
      this.draftData = evt;
      this.customComponentsService.setData(this.draftData)
      this.customComponentsService.setDataReadOnly(false)
      if (this.formdata && this.formdata.hasOwnProperty('isReadOnly')) {
        this.customComponentsService.setDataReadOnly((this.formdata.isReadonly && this.showComment))
      }
      // //
      if (!evt.changed.component.hidden) {
        // // //console.log(this.serviceAnswerJson);
        this.changeNewData = evt.data;
        this.pageConditional(evt.data);
        //  this.changedData = { 'key': evt.changed.component.key, 'value': evt.data[evt.changed.component.key] };
      }
    }

    this.formIncomplete = false;
    this.showSuccess = false;
  }

  removeRowTable(num: number) {
    let tableIndex = 0;
    for (let j in this.currentFieldSet.components[0].components) {
      if ((this.currentFieldSet.components[0].components[j].type == 'table' && ((this.currentFieldSet.components[0].components[j].properties && !this.currentFieldSet.components[0].components[j].properties.type) || !this.currentFieldSet.components[0].components[j].properties)) || (this.currentFieldSet.components[0].components[j].type == 'table' && (this.currentFieldSet.components[0].components[j].properties && (this.currentFieldSet.components[0].components[j].properties.type) && this.currentFieldSet.components[0].components[j].properties.type == 'dynamic'))) {
        if (num == tableIndex) {
          this.currentFieldSet.components[0].components[j].numRows = parseInt(this.currentFieldSet.components[0].components[j].numRows) - 1;
          let rowIndex = (this.currentFieldSet.components[0].components[j].properties.initialRow + parseInt($('#rowno').val()) - 1)
          this.currentFieldSet.components[0].components[j].rows.splice(rowIndex, 1)
          this.dynamicTableFields(this.currentFieldSet.components[0].key, this.currentFieldSet.components[0].components[j]);
          this.refreshForm.emit({
            form: this.currentFieldSet
          });
          this.updateKeysInTable();
          this.formChange = true;
        }

        tableIndex++;
      }
    }
  }
  removeColumTable(num: number) {

    // // // //console.log($('#columnno').val() + num);
    let tableIndex = 0;
    for (let j in this.currentFieldSet.components[0].components) {
      if ((this.currentFieldSet.components[0].components[j].type == 'table' && ((this.currentFieldSet.components[0].components[j].properties && !this.currentFieldSet.components[0].components[j].properties.type) || !this.currentFieldSet.components[0].components[j].properties)) || (this.currentFieldSet.components[0].components[j].type == 'table' && (this.currentFieldSet.components[0].components[j].properties && (this.currentFieldSet.components[0].components[j].properties.type) && this.currentFieldSet.components[0].components[j].properties.type == 'dynamic'))) {
        if (num == tableIndex) {
          if (!(this.currentFieldSet.components[0].components[j].properties.initialColumn)) {
            this.currentFieldSet.components[0].components[j].properties.initialColumn = this.currentFieldSet.components[0].components[j].numCols;
          }

          this.currentFieldSet.components[0].components[j].numCols = parseInt(this.currentFieldSet.components[0].components[j].numCols) - 1;
          for (let k in this.currentFieldSet.components[0].components[j].rows) {
            let columnIndex = (this.currentFieldSet.components[0].components[j].properties.initialColumn + parseInt($('#columnno').val()) - 1)
            this.currentFieldSet.components[0].components[j].rows[k].splice(columnIndex, 1);
          }
          this.dynamicTableFields(this.currentFieldSet.components[0].key, this.currentFieldSet.components[0].components[j]);
        }
        tableIndex++;
      }

    }

    this.currentColumns[num].columnsCount = this.currentColumns[num].columnsCount - 1;
    this.currentSlider = num;
    this.refreshForm.emit({
      form: this.currentFieldSet
    });
    this.formChange = true;
    this.updateKeysInTable();
  }
  isDelegateDisabled() {
    if (this.formdata.responseStatusTitle.trim().toLowerCase() === 'completed' ||
      this.formdata.responseStatusTitle.trim().toLowerCase() === 'under review') {
      return true;
    }
    else {
      return false;
    }
  }
  isTakeDelegateAction(): any {
    return (this.showComment && !this.isUnauthenticated && this.formdata.responseStatusTitle !== 0 && this.formdata.responseStatusTitle != 'Rejected' && this.formdata.responseStatusTitle != 'On Hold' && !this.isHistory)
  }
  render2(evt: any) {
    if (evt.data && evt.changed != undefined) {
      // this.answer = evt.data;
      this.pageConditional(evt.data);
    }
    if (this.formdata && this.formdata.hasOwnProperty('isReadOnly')) {
      this.customComponentsService.setDataReadOnly((this.formdata.isReadonly && this.showComment))
    }
  }
  apiCall() {
    let id = $('#inputapiCall').val().split('[');
    let buttonKey = id[1].split(']')
    let componentInfo = (this.keyInformationCollection[buttonKey[0]]);
    if (componentInfo && componentInfo.properties && componentInfo.properties.apiURL && componentInfo.properties.destinationKey) {
      if (this.answer && this.answer.data) {
        this.answer.data['api-call-key-class'] = 'done';
      } else {
        this.answer = {}
        this.answer.data = {};
        this.answer.data['api-call-key-class'] = 'done';
      }
      let data = this.answer.data;
      let url = '';
      let tempUrl = 'url ="'+ componentInfo.properties.apiURL.split('{{').join('"+').split('}}').join('+"')+'"';
      let urlToCall = eval(tempUrl);
      this.formService.getCall(urlToCall).subscribe(data => {
        this.answer.data[componentInfo.properties.destinationKey] = data
        this.refreshForm.emit({
          submission: { data: this.answer.data },
        });
      });
    }
  }
  changeSDetected(val: boolean) {
    this.dataChange = true;
  }
  showPopUPForConditional(customCondition, condition) {
    // alert('hello')
  }
  render(evt: any) {
    // //console.log(evt)
    this.slide(1)
    this.slideDynamic(1);
    if (this.showConditionalPages) {
      for (let k in this.KeysInPageComponents[this.currentFieldSet.components[0].key]) {
        let component = this.KeysInPageComponents[this.currentFieldSet.components[0].key][k];
        if (component.conditional != 'No' || component.customConditional != 'No') {
          $('#dvCurrent').find("div.formio-component-" + component.key).each(function (index) {

            $(this).addClass('hightlight-element-yellow');
            $(this).bind('click', function (e) {
              //  this.showPopUPForConditional(component.calculateValue, component.customConditional)
              e.stopPropagation()
              e.preventDefault();

              $('#inputmockClick').val(component.key);
              $('#mockClick').trigger('click');
            })
          })
        }
      }
    }
    if (this.formdata && this.formdata.hasOwnProperty('isReadOnly')) {
      this.customComponentsService.setDataReadOnly((this.formdata.isReadonly && this.showComment))
    }
    $('div').on('click', 'input.form-control', function () {

      $('#changeDetect').trigger('click');
    });
    $('div').on('click', 'div.dropdown', function () {

      $('#changeDetect').trigger('click');
    });
    $('div').on('click', 'div.fileSelector', function () {
      $('#changeDetect').trigger('click');
    });


    setTimeout(function (e) {
      $('button.buttonclick').on('click', function () {

        $('#inputapiCall').val($(this).attr('name'));
        $('#apiCall').trigger('click');

        //// // //console.log($('.linkaddcolumn').index($(this)));
      })
    }, 500);

    $('.linkaddcolumn').click(function () {
      $('#table_' + ($('.linkaddcolumn').index($(this)) + 1)).trigger('click');
      //// // //console.log($('.linkaddcolumn').index($(this)));

    })
    $('.addlinkcolumn').click(function () {
      $('#table_' + ($('.addlinkcolumn').index($(this)))).trigger('click');
      //// // //console.log($('.linkaddcolumn').index($(this)));

    })
    $('.removecolumn').click(function () {
      $('.removetablecolumn').css('display', 'none');
      $('.tableremove' + ($('.removecolumn').index($(this)) + 1)).css('display', 'block');

      var id = ($('.removecolumn').index($(this)) + 1);
      var classs = ('.tableremove' + ($('.removecolumn').index($(this)) + 1))
      $(classs).unbind();
      $(classs).click(function () {
        $('#columnno').val($(classs).index($(this)) + 1);
        $('#tablerremove_column_' + id).trigger('click');
      })
    })
    $('.columnremove').click(function () {
      $('.removetablecolumn').css('display', 'none');
      // // //console.log($('.columnremove').index($(this)));
      $('.tableremove' + ($('.columnremove').index($(this)))).css('display', 'block');

      var id = ($('.columnremove').index($(this)));
      var classs = ('.tableremove' + ($('.columnremove').index($(this))))
      $(classs).unbind();
      $(classs).click(function () {
        $('#columnno').val($(classs).index($(this)) + 1);
        $('#tablerremove_column_' + id).trigger('click');
      })
    })

    $('.removerow').click(function () {
      $('.removetablerow').css('display', 'none');
      $('.tableremoverow' + ($('.removerow').index($(this)) + 1)).css('display', 'block');
      var id = ($('.removerow').index($(this)) + 1);
      var classs = ('.tableremoverow' + ($('.removerow').index($(this)) + 1))
      $(classs).unbind();
      $(classs).click(function () {
        $('#rowno').val($(classs).index($(this)) + 1);
        $('#tablerremove_row_' + id).trigger('click');
      })
    })
    $('.rowremove').click(function () {
      $('.removetablerow').css('display', 'none');
      $('.tableremoverow' + ($('.rowremove').index($(this)))).css('display', 'block');
      var id = ($('.rowremove').index($(this)));
      var classs = ('.tableremoverow' + ($('.rowremove').index($(this))))
      $(classs).unbind();
      $(classs).click(function () {
        $('#rowno').val($(classs).index($(this)) + 1);
        $('#tablerremove_row_' + id).trigger('click');
      })
    })

    $('.nextcolum').click(function () {
      $('#tablen_' + ($('.nextcolum').index($(this)) + 1)).trigger('click');
      //// // //console.log($('.linkaddcolumn').index($(this)));

    })
    $('.columnnext').click(function () {
      $('#tableDynamicn_' + ($('.columnnext').index($(this)))).trigger('click');
      //// // //console.log($('.linkaddcolumn').index($(this)));

    })
    $('.previouscolum').click(function () {
      $('#tablep_' + ($('.previouscolum').index($(this)) + 1)).trigger('click');
      // // //console.log($('.linkaddcolumn').index($(this)));

    })
    $('.columnprevious').click(function () {
      $('#tableDynamicp_' + ($('.columnprevious').index($(this)))).trigger('click');
      // // //console.log($('.linkaddcolumn').index($(this)));

    })
    // this.slide(1)
    $('.linkaddrow').click(function () {
      $('#tablerow_' + ($('.linkaddrow').index($(this)) + 1)).trigger('click');
      // // //console.log($('.linkaddrow').index($(this)));
    })
    $('.addlinkrow').click(function () {
      $('#tablerow_' + ($('.addlinkrow').index($(this)))).trigger('click');
      // // //console.log($('.linkaddrow').index($(this)));
    })
  }

  ngOnInit() {
    this.regexValidationMessage = REGEX_VALIDATION_MESSAGE;
    this.content = this.contentService.getcontent();
    if (!this.content) {
      this.contentService.setContent().then(data => {
        this.content = this.contentService.getcontent();
        this.assignContent();
      });
    }

    this.submitLabel = this.content['formContainerSubmitButton'];
    this.cancelLabel = this.content['dataTableCancelAction'];
    this.nextLabel = this.content['formContainerNextButton'];
    this.continueLabel = this.content['formContainerContinueButton'];
    this.confirmLabel = this.content['formContainerConfirmButton'];
    this.submitModalTitle = this.content['formContainerSubmitFormResponseTitle'];
    this.submitModalSubitle = this.content['formContainerSubmitFormResponseSubtitle'];
    this.submitModalMessage = this.content['formContainerSubmitFormResponseMessage'];
    this.backIntakeList = this.content['formContainerBackIntakeList'];

    this.pageStatus = {};

    this.initData();
    this.customComponentsService.saveData$.subscribe(
      saveDatas =>
      {
        if (saveDatas != '-1') {
          // this.triggerPDF();
          this.responseEncryptedFieldsDataInSession.push(saveDatas.key);
          this.customComponentsService.responseEncryptedFieldsDataInSession.push(saveDatas.key);
        }
        else {
          for (let k in this.customComponentsService.responseEncryptedFieldsDataInSession) {
            delete this.customComponentsService.responseEncryptedFieldsDataInSession[k];
          }
        }
      });

  }
  refereshClick() {
    this.answer = this.customComponentsService.getData();
    this.refreshForm.emit({
      submission: { data: this.answer.data },


    });
  }
  initData() {
    if (this._router.url.indexOf('intake') !== -1) {
      this.isIntake = true;
    }

    this.initialLoad = true;
    this.resetErrorAndSuccessMsg();
    this.isHistory = this.route.snapshot.params['edit'] === 'history';
    this.isUnauthenticated = this.route.snapshot.data["isUnauthenticated"];
    this.isResponse = this.isUnauthenticated || Object.values(this.route.snapshot.params).includes("responses");
    this.showDelegateMessage = this._manageForm.getDelegateSuccessMsg();
    const surveyId = this.masterService.getGuid();
    if (surveyId) {
      this.customComponentsService.setRespondentEmailAddress(this.surveyService.respondentEmail);
    }
    this.customComponentsService.setResponseId(this.formdata.id);
    if (this.showDelegateMessage != '') {
      this.isshowDelegateMessage = true;
      this._manageForm.setDelegateSuccessMsg('');
    }

    if (environment.pdfSkipFormId && environment.pdfSkipFormId != "0" && environment.pdfSkipFormId != "")
    {
      let formIds = environment.pdfSkipFormId.split(",");
      for(let i=0; i < formIds.length ; i++) {
        if(formIds[i] === this.formdata.formId)
          this.pdfAllowedToDownload=false;
      }
    }
    
    this.refreshForm = new EventEmitter();
    this.refreshForm2 = new EventEmitter();
    this.initUploadAnswer();
    this.initComment();
    this.commentHistoryList = (this.formdata?.responseComments || []);
    if (this.commentHistoryList.length > 0) {
      this.setBadgeCount();
    }

    if (this.formdata.parentJson) {
      this.formdata.parentJson = this.formdata.parentJson.filter(json => json !== null);
      for (let i in this.formdata.parentJson) {
        let parentJson = JSON.parse(this.formdata.parentJson[i]);
        for (let j in parentJson.answer) {
          for (let k in parentJson.answer[j]) {
            this.parentJson[k] = parentJson.answer[j][k];
          }
        }
      }
    }

    if (this.formdata.responseEncryptedFields) {
      this.responseEncryptedFields = this.formdata.responseEncryptedFields;
    }
    let guestUser: boolean = false;
    if (this.isUnauthenticated)
      guestUser = true;
    let approver: boolean = false;
    let approverLevel = 0
    if (!this.isHistory) {
      approver = this.isApprover();
    }
    if (approver) {
      approverLevel = this.formdata.currentLevel;
    }
    this.parentJson['drmsReservedIsClientUser'] = this.userService.isClient && !this.isUnauthenticated;
    this.parentJson['drmsReservedIsFiservUser'] = this.userService.isFiserv && !this.isUnauthenticated;
    this.parentJson['drmsReservedIsGuestUser'] = guestUser;
    this.parentJson['drmsReservedIsApprover'] = approver ?? false;
    this.parentJson['drmsReservedApproverLevel'] = approverLevel;
    if (this.formdata && this.formdata.respondents) {
      this.parentJson['drmsReservedRespondentEmail'] = this.formdata?.respondents[0]?.assignedTo?.email;
      this.parentJson['drmsReservedRespondentName'] = this.formdata?.respondents[0]?.assignedTo?.name;
    }
    else {
      this.parentJson['drmsReservedRespondentEmail'] = this.formdata.assignedToEmail;
      this.parentJson['drmsReservedRespondentName'] = this.formdata.assignedToName;
    }
    if (this.formdata && this.formdata.responseStatusId)
      this.parentJson['drmsReservedresponseStatusId'] = this.formdata.responseStatusId;
    if (this.formdata && this.formdata.currentLevel)
      this.parentJson['drmsReservedFormLevel'] = this.formdata.currentLevel;
    if (this.formdata && this.formdata.assignedToEmail)
      this.parentJson['drmsReservedAssignedToEmail'] = this.formdata.assignedToEmail;
    this.parentJson['drmsReservedFormOrganizationId'] = this.formdata.organizationId;
    if (!this.isUnauthenticated) {
      this.parentJson['drmsReservedUserOrganizationId'] = this.userService.getUserListData()?.type === 'Fiserv' ? this.formdata.organizationId : 0;
      if (this.showFav())
        this.isResponseFavorites();
    }
    else{
      this.parentJson['drmsReservedUserOrganizationId'] = 0;
    }
    this.dynamicParentJson['drmsReservedUserOrganizationId'] = this.parentJson['drmsReservedUserOrganizationId']
    this.dynamicParentJson['drmsReservedApproverLevel'] = this.parentJson['drmsReservedApproverLevel'];
    this.dynamicParentJson['drmsReservedIsApprover'] = this.parentJson['drmsReservedIsApprover']
    this.dynamicParentJson['drmsReservedApprovers'] = this.formdata && this.formdata.approvers ? this.formdata.approvers:[];
    this.parentJson = { ...this.parentJson, ...this.formdata.reservedKeys };
    

    if (!this.isUnauthenticated)
      this.getAllUsers();
    if (this.formdata.preFilledData) {
      this.formdata.preFilledData = JSON.parse(JSON.parse(JSON.stringify(this.formdata.preFilledData)));
      for (let i in this.formdata.preFilledData) {
        this.preFilledJson[i] = this.formdata.preFilledData[i];
      }
      this.populateFormInfo();
    }
    this.formwizard = this.formJson;
    if (!!this.formJson.components) {
      // //
      this.fieldSets = this.formJson.components.filter((data) => {
        if (data.type == 'panel' || data.type == 'fieldset') {
          data.type = "fieldset"
          data.legend = data.title
          let flowName = 'response';
          if (this._router.url.indexOf('admin') !== -1) {
            flowName = 'Admin';
          }
          return true;
        }
      });
      this.formJsonCopy = JSON.parse(JSON.stringify(this.formJson));
      this.updateKeysInTable();
      if (this.formdata.answerJSON) {
        let json = JSON.parse(this.formdata.answerJSON);
        //
        if (json.answer2) {
          this.answer2.data = {};
          this.answer2.data = json.answer2

          this.serviceAnswerPopulate();
          this.keyInformation = json.keyinformation;
          this.statusCompleted = json.status;
          if (json.pageStatus)
            this.pageStatus = json.pageStatus
        }
        else if (json.answer) {
          this.serviceAnswerJson = json.answer;
          this.keyInformation = json.keyinformation;
          this.statusCompleted = json.status;
          if (json.pageStatus)
            this.pageStatus = json.pageStatus
        }

        this.completedForm = 0;
        //
        for (let counter in this.statusCompleted) {
          //this.completedForm = this.statusCompleted.length;
          this.formJson.components.filter((data) => {
            if (data.key == counter && (this.statusCompleted[counter] == 'completed' || this.statusCompleted[counter] == 'completed')) {
              data.status = "Completed"
              return true;
            }
          });
          this.completedForm = this.completedForm + 1;
        }
      }
      this.checkCalculatedData();

      this.setFieldSet(this.currentFieldSetIndex)
      this.customKeyCalculation();
      //   this.completedForm = this.answerJson.length;
    }
    $(window).scroll(function () {
      if ($(window).scrollTop() + $(window).height() > $(document).height() - 100) {
        $('#button_view').css('bottom', '70px')
      } else {
        $('#button_view').css('bottom', '0px')
      }
    });
    if (this.formdata && this.formdata.hasOwnProperty('isReadOnly')) {
      this.readonly = this.formdata.isReadOnly;
    }
    this.showApproverButtons = this.isApproverAndUnderReview();
    this._maxLengths = MAX_LENGTHS;
    const isSameOrg = true;
    //const isSameOrg = this.formdata.organizationId === this.userService.getUserListData().organization;

    //  this.isRevertAllowed = !this.userService.isClient && !this.userService.isRespondent && isSameOrg;
    if (this._router.url.indexOf('admin') !== -1) this.showComment = false;
    if (this._router.url.indexOf('intake') !== -1) this.showComment = false;
    if (this.permissionService.userData.id != this.formdata.assignedToId && ((this.userService.isClient && !this.userService.isRespondent && isSameOrg))) {
      this.assignMe = true;
    } else if (this.isMultiResponder() && this.permissionService.userData.id != this.formdata.assignedToId) {
      for (let k in this.formdata.respondents) {
        if (this.formdata.respondents[k].assignedToId == this.permissionService.userData.id) {

          this.assignMe = true;
        }
      }
    }
    this.formService.triggerSave$.subscribe(triggerSave$ => {
      if (triggerSave$ != '-1') {
        //    this.triggerPDF();
        if (!this.readonly)
          this.saveDraft();
      }
    });
  }

  private populateFormInfo(): void {
    if (this.preFilledJson) {
      let additionalInfo = {
        projectTitle: this.formdata.projectTitle,
        clientTitle: this.formdata.clientTitle,
        assignedToName: this.formdata.assignedToName,
        fimName: this.formdata.fiservImplementationManagerName,
        currentApproverName: this.formdata.currentApproverName,
        dueDate: this.formdata.dueDate?.split('T')[0],
        roles: this.permissionService.userData?.roles,
      };
      Object.keys(additionalInfo).forEach(element => {
        if (additionalInfo[element] === '' || additionalInfo[element] === null)
          delete additionalInfo[element];
      });
      this.preFilledJson = { ...this.preFilledJson, ...additionalInfo };
    }
  }
  isMultiResponder() {
    return true;
  }
  assignToMe() {
    $('#delegateMe').modal('show');
  }
  closeDelegateForm() {
    $('#delegateMe').modal('hide');
  }
  getDueDate() {
    const todaysdate = this.projectsService.getTodaysDate();
    let result = todaysdate;
    if (this.formdata && this.formdata.dueDate) {
      const duedate = this.formdata.dueDate.split('T')[0];
      if (new Date(duedate).getTime() > new Date(todaysdate).getTime())
        result = duedate;
    }
    return result;
  }

  assignToServiceCall() {
    let data = {
      "responseIds": [this.formdata.id],
      "dueDate": this.getDueDate(),
      "assignedToId": this.permissionService.userData.id,
      "comments": "assign to myself " + this.permissionService.userData.name
    }
    this.callDelegateService(data)
  }
  callDelegateService(data: any) {
    this.resetErrorAndSuccessMsg();
    this.formService.updateResponder(this.formdata.projectId, data).subscribe(
      response => {
        this.isError = false;
        //  this.handleDelegateForm();
        this.delegatedSuccessMsg = `The form '${this.formdata.title}' has been successfully assigned to yourself.`

        this._manageForm.setDelegateSuccessMsg(this.delegatedSuccessMsg);
        $('#delegateMe').modal('hide');
        this._router.navigate(['/reload/', this.formdata.id]);
      },
      error => { this.isError = true, this.errorMsg = error }
    )
  }

  getClassName(fieldSet) {
    if (this.currentFieldSet.components && fieldSet.legend === this.currentFieldSet.components[0].legend)
      return 'active';
    if (fieldSet.status === 'Completed')
      return 'completed';
    // if (fieldSet.status === 'Skipped')
    //   return 'error';
  }
  addRow(num: any) {
    let tableIndex = 0;
    for (let j in this.currentFieldSet.components[0].components) {
      if ((this.currentFieldSet.components[0].components[j].type == 'table' && ((this.currentFieldSet.components[0].components[j].properties && !this.currentFieldSet.components[0].components[j].properties.type) || !this.currentFieldSet.components[0].components[j].properties)) || (this.currentFieldSet.components[0].components[j].type == 'table' && (this.currentFieldSet.components[0].components[j].properties && (this.currentFieldSet.components[0].components[j].properties.type) && this.currentFieldSet.components[0].components[j].properties.type == 'dynamic'))) {
        if (num == tableIndex) {
          if (!(this.currentFieldSet.components[0].components[j].properties.initialRow)) {
            this.currentFieldSet.components[0].components[j].properties.initialRow = this.currentFieldSet.components[0].components[j].numRows;
          }
          this.currentFieldSet.components[0].components[j].numRows = parseInt(this.currentFieldSet.components[0].components[j].numRows) + 1;
          let components = [];
          for (let k in this.currentFieldSet.components[0].components[j].rows[1]) {
            let defaultColum = this.currentFieldSet.components[0].components[j].properties.default;
            // // //console.log(this.currentFieldSet.components[0].components[j].rows[1]);
            // let tempArray = Object.assign({}, this.currentFieldSet.components[0].components[0].rows[k][2].components);
            const myName = 'newRow' + this.currentFieldSet.components[0].components[j].numRows + k + this.currentFieldSetIndex + num + Math.floor(Math.random() * Math.floor(99999999));
            this.formedit = 0;
            let customClass = '';
            if (this.currentFieldSet.components[0].components[j].rows[1][k].components[0].customClass) {
              customClass = this.currentFieldSet.components[0].components[j].rows[1][k].components[0].customClass.split('highlight').join(' ');
            }
            if (!this.dependantRowIdMap[this.currentFieldSet.components[0].components[j].rows[1][k].components[0].key]) {
              this.dependantRowIdMap[this.currentFieldSet.components[0].components[j].rows[1][k].components[0].key] = [];
              this.dependantRowIdMap[this.currentFieldSet.components[0].components[j].rows[1][k].components[0].key][this.currentFieldSet.components[0].components[j].numCols] = myName;
            } else {
              this.dependantRowIdMap[this.currentFieldSet.components[0].components[j].rows[1][k].components[0].key][this.currentFieldSet.components[0].components[j].numCols] = myName;
            }
            let conditional = {};
            let calculateValue = '';
            let validateCustom = '';

            if (this.currentFieldSet.components[0].components[j].rows[1][k].components[0].calculateValue) {
              if (this.currentFieldSet.components[0].components[j].rows[1][k].components[0].properties && this.currentFieldSet.components[0].components[j].rows[1][k].components[0].properties.calculateValue) {
                let column = this.dependantRowIdMap[this.currentFieldSet.components[0].components[j].rows[1][k].components[0].properties.calculateValue][this.currentFieldSet.components[0].components[j].numCols]
                calculateValue = this.currentFieldSet.components[0].components[j].rows[1][k].components[0].calculateValue.split(this.currentFieldSet.components[0].components[j].rows[1][k].components[0].properties.calculateValue).join(column);
              }
            }
            if (this.currentFieldSet.components[0].components[j].rows[1][k].components[0].validate) {
              if (this.currentFieldSet.components[0].components[j].rows[1][k].components[0].properties && this.currentFieldSet.components[0].components[j].rows[1][k].components[0].properties.validateCustom) {
                let column = this.dependantRowIdMap[this.currentFieldSet.components[0].components[j].rows[1][k].components[0].properties.validateCustom][this.currentFieldSet.components[0].components[j].numCols]
                validateCustom = this.currentFieldSet.components[0].components[j].rows[1][k].components[0].validate.custom.split(this.currentFieldSet.components[0].components[j].rows[1][k].components[0].properties.validateCustom).join(column);
              }
            }
            // //console.log(this.currentFieldSet.components[0].components[j].rows[1][k].components[0].conditional);
            if (this.currentFieldSet.components[0].components[j].rows[1][k].components[0].conditional && this.currentFieldSet.components[0].components[j].rows[1][k].components[0].conditional.when != null) {
              conditional = {
                "show": this.currentFieldSet.components[0].components[j].rows[1][k].components[0].conditional.show,
                'when': this.dependantRowIdMap[this.currentFieldSet.components[0].components[j].rows[1][k].components[0].conditional.when][this.currentFieldSet.components[0].components[j].numCols],
                'eq': this.currentFieldSet.components[0].components[j].rows[1][k].components[0].conditional.eq
              }
            }
            let disabled = false;
            if (this.currentFieldSet.components[0].components[j].rows[1][k].components[0].disabled)
              disabled = this.currentFieldSet.components[0].components[j].rows[1][k].components[0].disabled;
            let description = '';
            if (k == '0') {
              description = description + '<i class="material-icons removetablerow tableremoverow' + num + '" title="remove" style="font - size: 25px;display:none;margin - left: 0px;cursor: pointer;"><a href="javascript:void(0)" style="color:red" target="_blank">remove_circle</a>  </i>'
              customClass = customClass + ' custom-add-comment';
            }
            if (k < defaultColum) {
              components.push({
                'components': [{
                  "label": 'new Row',
                  "hideLabel": true,
                  "tableView": false,
                  "key": myName,
                  "type": "textfield",
                  "input": true,
                  "defaultValue": '',
                  'description': description,
                  "customClass": customClass
                }]
              })
            } else {
              components.push({
                'components': [{
                  "label": this.currentFieldSet.components[0].components[j].rows[1][k].components[0].label,
                  "hideLabel": this.currentFieldSet.components[0].components[j].rows[1][k].components[0].hideLabel,
                  "tableView": this.currentFieldSet.components[0].components[j].rows[1][k].components[0].tableView,
                  "key": myName,
                  "type": this.currentFieldSet.components[0].components[j].rows[1][k].components[0].type,
                  "input": this.currentFieldSet.components[0].components[j].rows[1][k].components[0].input,
                  "data": this.currentFieldSet.components[0].components[j].rows[1][k].components[0].data ? this.currentFieldSet.components[0].components[j].rows[1][k].components[0].data : '',
                  "customClass": customClass,
                  "conditional": conditional,
                  "validate": {
                    "custom": validateCustom
                  },
                  'disabled': disabled,
                  "calculateValue": calculateValue,
                  'description': description,
                  "values": this.currentFieldSet.components[0].components[j].rows[1][k].components[0].values ? this.currentFieldSet.components[0].components[j].rows[1][k].components[0].values : '',
                  "defaultValue": this.currentFieldSet.components[0].components[j].rows[1][k].components[0].defaultValue ? this.currentFieldSet.components[0].components[j].rows[1][k].components[0].defaultValue : ''
                }]
              })
            }
            /*  tempArray.components.filter((data) => {
                data.key = 'mayank' + this.currentColumns+k;
                return true;
              });*/
          }

          this.currentFieldSet.components[0].components[j].rows.push(components)
          if (this.currentFieldSet.components[0].components[j].properties.tabletype == 'horizontal') {
            let tabIndextable = 0
            for (let k in this.currentFieldSet.components[0].components[j].rows) {
              for (let m in this.currentFieldSet.components[0].components[j].rows[k]) {
                let array = this.currentFieldSet.components[0].components[j].rows[k][m];

                this.currentFieldSet.components[0].components[j].rows[k][m].components[0].tabindex = tabIndextable;
                tabIndextable = tabIndextable + 1;
              }
            }
          }
          // //console.log(this.currentFieldSet.components[0].components[j].rows)
          this.refreshForm.emit({
            form: this.currentFieldSet
          });
          /*     // //console.log(this.currentFieldSet.components[0].components[j]);
                this.currentFieldSet.components[0].components[j].rows[k].push({
                  "components": [{
                    "label": this.currentFieldSet.components[0].components[j].rows[k][1].components[0].label,
                    "hideLabel": this.currentFieldSet.components[0].components[j].rows[k][1].components[0].hideLabel,
                    "tableView": this.currentFieldSet.components[0].components[j].rows[k][1].components[0].tableView,
                    "key": myName,
                    "type": this.currentFieldSet.components[0].components[j].rows[k][1].components[0].type,
                    "input": this.currentFieldSet.components[0].components[j].rows[k][1].components[0].input,
                    "defaultValue": myName
                  }]
                });*/
          // //console.log(this.currentFieldSet.components[0].components[j].rows[k]);
          this.dynamicTableFields(this.currentFieldSet.components[0].key, this.currentFieldSet.components[0].components[j]);
          this.formChange = true;
          this.updateKeysInTable();
        }
        tableIndex++;
      }
    }
  }
  addColunm(num: any) {
    let tableIndex = 0;
    let typeoftable = '';
    for (let j in this.currentFieldSet.components[0].components) {
      if ((this.currentFieldSet.components[0].components[j].type == 'table' && ((this.currentFieldSet.components[0].components[j].properties && !this.currentFieldSet.components[0].components[j].properties.type) || !this.currentFieldSet.components[0].components[j].properties)) || (this.currentFieldSet.components[0].components[j].type == 'table' && (this.currentFieldSet.components[0].components[j].properties && (this.currentFieldSet.components[0].components[j].properties.type) && this.currentFieldSet.components[0].components[j].properties.type == 'dynamic'))) {
        if (num == tableIndex) {
          if ((this.currentFieldSet.components[0].components[j].type == 'table' && (this.currentFieldSet.components[0].components[j].properties && (this.currentFieldSet.components[0].components[j].properties.type) && this.currentFieldSet.components[0].components[j].properties.type == 'dynamic'))) {
            typeoftable = 'dynamic';
          }
          if (!(this.currentFieldSet.components[0].components[j].numCols)) {

            this.currentFieldSet.components[0].components[j].numCols = this.currentFieldSet.components[0].components[j].rows[0].length;
          }
          if (!(this.currentFieldSet.components[0].components[j].properties.initialColumn)) {
            this.currentFieldSet.components[0].components[j].properties.initialColumn = this.currentFieldSet.components[0].components[j].numCols;
          }

          this.currentFieldSet.components[0].components[j].numCols = parseInt(this.currentFieldSet.components[0].components[j].numCols) + 1;
          for (let k in this.currentFieldSet.components[0].components[j].rows) {
            // let tempArray = Object.assign({}, this.currentFieldSet.components[0].components[0].rows[k][2].components);
            const myName = 'newColumn' + '_' + j + '_' + k + this.currentColumns[num].columnsCount + this.currentFieldSetIndex + Math.floor(Math.random() * Math.floor(99999999));
            this.formedit = 1;
            /*  tempArray.components.filter((data) => {

                data.key = 'mayank' + this.currentColumns+k;

                return true;

              });*/
            let label = '';
            let hlabel = '';
            let rowColumLength = this.currentFieldSet.components[0].components[j].properties.default;
            if (this.currentFieldSet.components[0].components[j].rows[k][rowColumLength].components[0]) {
              let description = '';
              if (this.currentFieldSet.components[0].components[j].rows[k][rowColumLength].components[0].description) {
                description = "<span class='addComment' id='" + myName + "' ><i class='material-icons' title='Add Comments' style='font - size: 25px; margin - left: 10px;cursor: pointer;'>comment </i></span>";
              }
              let customClass = '';
              if (this.currentFieldSet.components[0].components[j].rows[k][rowColumLength].components[0].customClass) {
                customClass = this.currentFieldSet.components[0].components[j].rows[k][rowColumLength].components[0].customClass.split('highlight').join(' ');
              }
              if (k == '0') {
                description = description + '<i class="material-icons removetablecolumn tableremove' + num + '" title="remove" style="font - size: 25px;display:none;margin - left: 0px;cursor: pointer;"><a href="javascript:void(0)" style="color:red" target="_blank">remove_circle</a>  </i>'
                customClass = customClass + ' custom-add-comment';
              }

              if (!this.dependantIdMap[this.currentFieldSet.components[0].components[j].rows[k][rowColumLength].components[0].key]) {
                this.dependantIdMap[this.currentFieldSet.components[0].components[j].rows[k][rowColumLength].components[0].key] = [];
                this.dependantIdMap[this.currentFieldSet.components[0].components[j].rows[k][rowColumLength].components[0].key][this.currentFieldSet.components[0].components[j].numCols] = myName;
              } else {
                this.dependantIdMap[this.currentFieldSet.components[0].components[j].rows[k][rowColumLength].components[0].key][this.currentFieldSet.components[0].components[j].numCols] = myName;
              }
              let conditional = {};
              let calculateValue = '';
              let validateCustom = '';
              let placeholder = '';
              if (this.currentFieldSet.components[0].components[j].rows[k][rowColumLength].components[0].placeholder)
                placeholder = this.currentFieldSet.components[0].components[j].rows[k][rowColumLength].components[0].placeholder
              if (this.currentFieldSet.components[0].components[j].rows[k][rowColumLength].components[0].calculateValue) {
                if (this.currentFieldSet.components[0].components[j].rows[k][rowColumLength].components[0].properties && this.currentFieldSet.components[0].components[j].rows[k][rowColumLength].components[0].properties.calculateValue) {
                  let column = this.dependantIdMap[this.currentFieldSet.components[0].components[j].rows[k][rowColumLength].components[0].properties.calculateValue][this.currentFieldSet.components[0].components[j].numCols]
                  calculateValue = this.currentFieldSet.components[0].components[j].rows[k][rowColumLength].components[0].calculateValue.split(this.currentFieldSet.components[0].components[j].rows[k][rowColumLength].components[0].properties.calculateValue).join(column);
                }
              }
              if (this.currentFieldSet.components[0].components[j].rows[k][rowColumLength].components[0].validate) {
                if (this.currentFieldSet.components[0].components[j].rows[k][rowColumLength].components[0].properties && this.currentFieldSet.components[0].components[j].rows[k][rowColumLength].components[0].properties.validateCustom) {
                  let column = this.dependantIdMap[this.currentFieldSet.components[0].components[j].rows[k][rowColumLength].components[0].properties.validateCustom][this.currentFieldSet.components[0].components[j].numCols]
                  validateCustom = this.currentFieldSet.components[0].components[j].rows[k][rowColumLength].components[0].validate.custom.split(this.currentFieldSet.components[0].components[j].rows[k][rowColumLength].components[0].properties.validateCustom).join(column);
                }
              }
              // //console.log(this.currentFieldSet.components[0].components[j].rows[k][rowColumLength].components[0].conditional);
              if (this.currentFieldSet.components[0].components[j].rows[k][rowColumLength].components[0].conditional && this.currentFieldSet.components[0].components[j].rows[k][rowColumLength].components[0].conditional.when != null) {
                conditional = {
                  "show": this.currentFieldSet.components[0].components[j].rows[k][rowColumLength].components[0].conditional.show,
                  'when': this.dependantIdMap[this.currentFieldSet.components[0].components[j].rows[k][rowColumLength].components[0].conditional.when][this.currentFieldSet.components[0].components[j].numCols],
                  'eq': this.currentFieldSet.components[0].components[j].rows[k][rowColumLength].components[0].conditional.eq
                }
              }
              let hidden = false;
              if (this.currentFieldSet.components[0].components[j].rows[k][rowColumLength].components[0].hidden)
                hidden = this.currentFieldSet.components[0].components[j].rows[k][rowColumLength].components[0].hidden
              this.currentFieldSet.components[0].components[j].rows[k].push({
                "components": [{
                  "label": label,
                  "hideLabel": hlabel,
                  "tableView": this.currentFieldSet.components[0].components[j].rows[k][rowColumLength].components[0].tableView,
                  "key": myName,
                  "type": this.currentFieldSet.components[0].components[j].rows[k][rowColumLength].components[0].type,
                  "input": this.currentFieldSet.components[0].components[j].rows[k][rowColumLength].components[0].input,
                  "hidden": hidden,
                  "description": description,
                  "customClass": customClass,
                  "conditional": conditional,
                  "validate": {
                    "custom": validateCustom
                  },
                  "placeholder": placeholder,
                  "calculateValue": calculateValue,
                  "data": this.currentFieldSet.components[0].components[j].rows[k][rowColumLength].components[0].data ? this.currentFieldSet.components[0].components[j].rows[k][rowColumLength].components[0].data : '',
                  "values": this.currentFieldSet.components[0].components[j].rows[k][rowColumLength].components[0].values ? this.currentFieldSet.components[0].components[j].rows[k][rowColumLength].components[0].values : '',
                  "defaultValue": this.currentFieldSet.components[0].components[j].rows[k][rowColumLength].components[0].defaultValue ? this.currentFieldSet.components[0].components[j].rows[k][rowColumLength].components[0].defaultValue : ''
                }]
              });
            } else {
              this.currentFieldSet.components[0].components[j].rows[k].push({
                "components": []
              });
            }
            // //console.log(this.currentFieldSet.components[0].components[j].rows[k]);
          }
          if (this.currentFieldSet.components[0].components[j].properties.tabletype == 'vertical') {
            let tabIndextable = 0
            for (let k in this.currentFieldSet.components[0].components[j].rows[0]) {
              for (let m in this.currentFieldSet.components[0].components[j].rows) {
                let array = this.currentFieldSet.components[0].components[j].rows[m][k];
                this.currentFieldSet.components[0].components[j].rows[m][k].components[0].tabindex = tabIndextable;
                tabIndextable = tabIndextable + 1;
              }
            }
          }
          this.dynamicTableFields(this.currentFieldSet.components[0].key, this.currentFieldSet.components[0].components[j]);
        }
        tableIndex++;
      }
    }
    // //console.log(this.currentColumns[num].columnsCount + 1);
    /*  for (let k in this.currentFieldSet.components[0].components[j].rows) {
        for (let k in this.currentFieldSet.components[0].components) {
          // //console.log("this.currentFieldSet.components[0].components[j].rows[k].length" + this.currentFieldSet.components[0].components[j].rows[k].length)
        }

      }*/
    let dynamicTable = 0;
    if (typeoftable = 'dynamic') {
      for (let i = 0; i < this.currentColumns.length; i++) {
        if (this.currentColumns[i].typeOfTheTable == 'dynamic') {
          if (dynamicTable == num) {
            this.currentColumns[i].columnsCount = this.currentColumns[i].columnsCount + 1;
            this.currentSlider = i;
          }
          dynamicTable = dynamicTable + 1;
        }
      }
    } else {
      this.currentColumns[num].columnsCount = this.currentColumns[num].columnsCount + 1;
      this.currentSlider = num;
    }

    this.refreshForm.emit({
      form: this.currentFieldSet
    });

    this.updateKeysInTable();
    this.formChange = true;
  }

  dynamicTableFields(key: any, table: any) {
    var tempss: any = [];
    if ((!table.properties || !table.properties.type) || (table.properties && table.properties.type && table.properties.type != 'staticwithkeys')) {
      for (let k in table.rows) {
        if (!this.tableFieldsJson[key]) {
          this.tableFieldsJson[key] = [];
        }

        if (!tempss[k]) {
          tempss[k] = [];
        }
        for (let m in table.rows[k]) {
          if (!tempss[k][m]) {
            tempss[k][m] = '';
          }
          for (let l in table.rows[k][m].components) {
            // // //console.log(table.rows[k][m]);

            // //console.log(table.rows[k][m].components[l].key);
            this.keysInTable[table.rows[k][m].components[l].key] = 'y';
            if (table.rows[k][m].components[l].type == 'htmlelement') {
              let content = '';
              if (table.rows[k][m].components[l].content) {
                let content2 = table.rows[k][m].components[l].content.split("<i");
                content = content2[0];
                content = content.toString();

                content = content.replace(/(<([^>]+)>)/ig, '');
                //   content = content.replace(/(<([^>]+)>)/ig, '');
              } else {
                content = '';
              }
              tempss[k][m] = content + '#!#!' + table.rows[k][m].components[l].type;
            }
            else {
              tempss[k][m] = table.rows[k][m].components[l].key + '#!#!' + table.rows[k][m].components[l].type;
            }

            //  tempss[table.rows[k][m].components[l].key] = this.answer.data[table.rows[k][m].components[l].key];
            // var key = table.rows[k][m].components[l].key;
          }
        }
      }

      if (this.tableFieldsJson[key]) {
        let keysss: any = table.key;
        this.tableFieldsJson[key][keysss] = [];
        this.tableFieldsJson[key][keysss].push({ 'data': tempss })
      }
    }
  }
  answerDataPopulation() {
    let serviceAnswer = JSON.parse(JSON.stringify(this.serviceAnswerJson));
    for (let pkey in this.preFilledJson) {
      if (!this.answer.data) {
        this.answer.data = {};
      }

      this.answer.data[pkey] = this.preFilledJson[pkey];
    }
    for (let pageKey in serviceAnswer) {
      for (let fieldKey in serviceAnswer[pageKey]) {
        if (!this.answer.data) {
          this.answer.data = {};
        }
        this.answer.data[fieldKey] = serviceAnswer[pageKey][fieldKey];
      }
    }
    for (let pkey in this.parentJson) {
      if (!this.answer.data) {
        this.answer.data = {};
      }
      this.answer.data[pkey] = this.parentJson[pkey];
    }
    for (let pkey in this.dynamicParentJson) {
      if (!this.answer.data) {
        this.answer.data = {};
      }
      this.answer.data[pkey] = this.dynamicParentJson[pkey];
    }

    for (let pkey in this.responseEncryptedFields) {
      if (!this.answer.data) {
        this.answer.data = {};
      }

      this.responseEncryptedFieldsData[this.responseEncryptedFields[pkey]['key']] = this.responseEncryptedFields[pkey]
      this.customComponentsService.setSensitiveData(this.responseEncryptedFieldsData);
    }
    /* for (let pageKey in this.serviceAnswerJson) {
       for (let fieldKey in this.serviceAnswerJson[pageKey]) {
         if (this.answer.data) {
         } else {
           this.answer.data = {};
         }
         if (this.answer.data[fieldKey] == undefined)
           this.answer.data[fieldKey] = this.serviceAnswerJson[pageKey][fieldKey];
       }
     }
     if (!this.answer2.data) {
       if (this.answer.data) {
         this.answer2.data = this.answer.data;
       }
     }
     */
    /*   if (this.answer.data) {
         for (let pkey in this.parentJson) {
           this.answer.data[pkey] = this.parentJson[pkey];
         }
       } else {*/

    //this.answer = { 'data': this.parentJson };
    //}
    if (!this.answer.data) {
      this.answer.data = {};
      this.answer.data['aa'] = 'sdsd';
    }
    this.currentFieldSet.components.push({
      "type": "button",
      "label": "Submit",
      "key": "submit",
      "disableOnInvalid": false,
      "input": true,
      "customClass": "btnHidden",
      "tableView": false,
      "validate": {
        "unique": false,
        "multiple": false
      }
    });

    this.pageConditional(this.answer.data, true);
    for (let j in this.currentFieldSet.components[0].components) {
      if ((this.currentFieldSet.components[0].components[j].type == 'table' && ((this.currentFieldSet.components[0].components[j].properties && !this.currentFieldSet.components[0].components[j].properties.type) || !this.currentFieldSet.components[0].components[j].properties))
        || (this.currentFieldSet.components[0].components[j].type == 'table' && (this.currentFieldSet.components[0].components[j].properties && (this.currentFieldSet.components[0].components[j].properties.type)))) {
        let isSlider: string = 'no';
        let hideRow: string = 'no';
        let typeOfTheTable = '';
        if ((this.currentFieldSet.components[0].components[j].properties && (this.currentFieldSet.components[0].components[j].properties.type) && this.currentFieldSet.components[0].components[j].properties.type == 'dynamic'))
          typeOfTheTable = 'dynamic';
        if (this.currentFieldSet.components[0].components[j].properties) {
          if (this.currentFieldSet.components[0].components[j].properties.isSlider && this.currentFieldSet.components[0].components[j].properties.isSlider == 'yes')
            isSlider = 'yes';
          if (this.currentFieldSet.components[0].components[j].properties.hideRow)
            hideRow = this.currentFieldSet.components[0].components[j].properties.hideRow;
        }

        this.currentColumns.push({ 'sliderStart': 1, 'sliderEnd': 5, 'hideRow': hideRow, 'typeOfTheTable': typeOfTheTable, 'columnsCount': this.currentFieldSet.components[0].components[j].rows[0].length, 'isSlider': isSlider });
        this.tables.push({ 'index': j });
        if (typeOfTheTable == 'dynamic') {
          this.dynamicTableFields(this.currentFieldSet.components[0].key, this.currentFieldSet.components[0].components[j]);
        }
        if (j != '0'){
          this.dynamicTableFields(this.currentFieldSet.components[0].key, this.currentFieldSet.components[0].components[j]);
        }
      }
    }
    this.updateKeysInTable();
    this.pdfAnswerDownload = { 'data': JSON.parse(JSON.stringify(this.answer.data)) }
    this.answer.data['ignore'] = new Date()
    if (this.currentFieldSetIndex == 0) {
      this.currentFieldSet2 = JSON.parse(JSON.stringify(this.currentFieldSet))
    }
    this.refreshForm.emit({
      submission: { data: this.answer.data },
      form: this.currentFieldSet,
    });
    this.customComponentsService.setData({ "data": this.answer.data })
  }
  setFieldSet(index, showSuccessDraft: boolean = false) {
    this.commentCat = 'Comment';
    this.dataChange = false;
    this.formChange = false;
    this.changeNewData = null;
    this.tables = [];
    this.currentColumns = [];

    this.currentSlider = 0;
    this.currentFieldSetIndex = index;
    this.currentFieldSet = { components: [] };

    this.showSuccessDraft = showSuccessDraft;
    this.resetErrorAndSuccessMsg();
    if (this.fieldSets[this.currentFieldSetIndex].show == false && this.showConditionalPages==false) {

      if (this.currentFieldSetIndex < this.fieldSets.length - 1) {
        this.setFieldSet(++this.currentFieldSetIndex);
        return;
      } else {
        let updatedIndex: any = 0;
        for (let k in this.fieldSets) {
          if (this.fieldSets[k].show != false) {
            updatedIndex = k;
          }
        }
        this.setFieldSet(updatedIndex);
        return;
      }
    }
    //  btnComponent[0].customClass ="btnHidden";
    //// //
    if (this.currentFieldSetIndex != 0) {
      this.isShowProjectDetail = false;
    } else {
      this.isShowProjectDetail = true;
    }
    //

    this.populateFiledSetData(this.sourceFormParams, false);
    // if (this.serviceAnswerJson[this.currentFieldSet.components[0].key])
    //    this.answer = { 'data': this.serviceAnswerJson[this.currentFieldSet.components[0].key] };
  }
  populateFiledSetData(sourceJson: any, isExternal: boolean, step: any = 0) {
    let completeFormJson = JSON.parse(JSON.stringify(this.fieldSets))
    let formJson = JSON.stringify(completeFormJson[this.currentFieldSetIndex])
    if (this.showConditionalPages) {
      formJson = JSON.parse(formJson.split("onditional").join("onditional2"))
    } else {
      formJson = this.fieldSets[this.currentFieldSetIndex];
    }

    this.currentFieldSet.components.push(formJson);
    this.answerDataPopulation();
  }

  fetchCustomProperty(component: any) {
    if (component.properties) {
      if (component.properties.custome_key) {
        let json = { 'key': component.key, 'custome_key': component.properties.custome_key }
        return json;
      }
    }
    return false;
  }

  hideLeftNav: boolean = false;

  toggleSideBar() {
    this.hideLeftNav = !this.hideLeftNav;
  }

  computeClazzForNavigation(): string {
    if (this.fieldSets && this.fieldSets.length > 1) {
      if (this.hideLeftNav) return "main-content-collapsed";
      else return "col-xl-10 col-md-9 col-sm-9 main-content-expand";
    } else {
      return "col-lg-12";
    }
  }

  keyAlreadyExits(key: any, pKey: any) {
    for (let pageKey in this.keyInformation) {
      for (let fieldKey in this.keyInformation[pageKey]) {
        // //console.log(fieldKey + '== ' + key)
        if (fieldKey == key && pKey != pageKey)
          return false;
      }
    }
    return true;
  }
  customKeyCalculation() {
    let customKeyArray: any = [];
    for (let l in this.fieldSets) {
      for (let j = 0; j < this.fieldSets[l].components.length; j++) {
        // //console.log('j' + j);
        let jComponent = this.fieldSets[l].components[j];
        if (jComponent.type == 'columns') {
          for (let k = 0; k < jComponent.columns.length; k++) {
            let kcomponent = jComponent.columns[k];
            for (let l = 0; l < kcomponent.components.length; l++) {
              if (this.fetchCustomProperty(kcomponent.components[l]) != false) {
                customKeyArray.push(this.fetchCustomProperty(kcomponent.components[l]));
              }
              //this.formJson.components[i].components[j].columns[k].components[l].customClass = this.cssClassName(this.formJson.components[i].components[j].columns[k].components[l].customClass, this.formJson.components[i].components[j].columns[k].components[l].type);
            }
          }
        } else {
          if (this.fetchCustomProperty(jComponent) != false) {
            customKeyArray.push(this.fetchCustomProperty(jComponent));
          }
        }
      }
    }
    for (let k in customKeyArray) {
      this.customeKeyArray[customKeyArray[k].key] = customKeyArray[k].custome_key;
    }
  }
  inGrid(key: any, submitData: any) {
    let iSFieldGrid: boolean = false;
    let gridId = this.KeysInPageComponents[this.currentFieldSet.components[0].key].filter((data) => {
      iSFieldGrid = false;
      if (data.type == "datagrid") {
        if (submitData && submitData[data.key]) {
          submitData[data.key].filter((data2) => {
            for (let k in data2) {
              if (k == key) {
                iSFieldGrid = true;
              }
            }
          });
        }
      }
      return iSFieldGrid;
    });

    // //console.log(gridId);
    //this.KeysInPageComponents[this.currentFieldSet.components[0].key]
    return (gridId.length > 0 || (this.gridId[this.currentFieldSet.components[0].key] && this.gridId[this.currentFieldSet.components[0].key][key]));
  }
  navigateToConfirmation() {
    // Need to comment below line when we enable PDF on Load
    if(! this.pdfAllowedToDownload)
      this.isPdfDownloaded = true;

    if (this.isPdfDownloaded && this.redirectionNeeded) {
      if (this.isApproverFlow) {
        this._router.navigate(["response/list"]);
      }
      else if (this.redirectToPage) {
        this._router.navigate(["../confirmation/" + this.formdata.id], { relativeTo: this.route });
        //  this.redirectToPage = isUnauthenticated;
      }
      else {
        this._router.navigate(['response/confirmation/' + this.formdata.id]);
      }
    }
  }
  navigate(event: any) {
    this.isPdfDownloaded = true;
    this.navigateToConfirmation();
  }
  saveAndSubmitResponse(isUnauthenticated, nextPage: boolean = true) {
    let getData = this._manageForm.getResponseData();
    if (!this.isShowPage && this.isSubmitClicked) {
      if (this.isAllFieldSetsCompleted()) {
        this.redirectToPage = isUnauthenticated;
        this.redirectionNeeded = true;
        this.isApproverFlow = false;
        this.navigateToConfirmation();
      }
      else {
        this.showSuccess = false;
        this.isshowDelegateMessage = false;
        this.showSuccessDraft = false;
        this.formIncomplete = true;
      }
    }
    else if (!this.isShowPage) {
      this.formIncomplete = false;
      this.isshowDelegateMessage = false;
      this.showSuccessDraft = false;
      this.showSuccess = true;
    }
    else if (this.currentFieldSetIndex < this.fieldSets.length - 1 && nextPage) {
      this.setFieldSet(++this.currentFieldSetIndex);
    }
  }
  onSubmit(evt: any, draftMode: boolean = false) {
    this.eventDataCopy = JSON.parse(JSON.stringify(evt.data))
    this.resetErrorAndSuccessMsg();

    for (let k in this.sensitiveInformtion) {
      if (evt.data[k] && evt.data[k] != '') {
        let str = evt.data[k];
        let arrayMap: any[] = [];
        arrayMap['SSN'] = { "key": "SSN_Internal", "name": "Social Security Numbers" };
        arrayMap['CARD'] = { "key": "Card_Internal", "name": "Credit Card Numbers" };
        arrayMap['UsTaxId'] = { "key": "SSN_Internal", "name": "US Tax Id" };
        arrayMap['NonUsTaxId'] = { "key": "GenericText_Internal", "name": "Non-US Tax Id" };
        arrayMap['PHI'] = { "key": "GenericText_Internal", "name": "Personal Health Information" };
        arrayMap['FBAN'] = { "key": "GenericText_Internal", "name": "Financial/Banking Account Number" };
        arrayMap['DL'] = { "key": "GenericText_Internal", "name": "Driver's Licenses" };
        arrayMap['NID'] = { "key": "GenericText_Internal", "name": "National IDs" };
        arrayMap['PASSPORT'] = { "key": "GenericText_Internal", "name": "Passport Number" };
        arrayMap['OTHER'] = { "key": "GenericText_Internal", "name": "OTHER" };
        let element = arrayMap[this.keyInformationCollection[k].typeOfSensitiveInformation];

        if (this.customComponentsService.responseEncryptedFieldsDataInSession.includes(k) && this.responseEncryptedFieldsData[k]) {
          if (element.key == "SSN_Internal" && !(/^[0-9]{9}$/).test(evt.data[k])) {
            this.errorMsg = "The SSN should only contain 9-digit numeric value"
            this.isError = true;
            return;
          }
          if (element.key == "Card_Internal" && !(/[0-9]{12}$/).test(evt.data[k])) {
            this.errorMsg = "The card number should contain at least 12-digit numeric value"
            this.isError = true;
            return;
          }
        }
        else if (this.responseEncryptedFieldsData[k]) {
        }
        else if (this.keyInformationCollection[k].typeOfSensitiveInformation != 'No') {
          if (element.key == "SSN_Internal" && !(/^[0-9]{9}$/).test(evt.data[k])) {
            this.errorMsg = "The SSN should only contain 9-digit numeric value"
            this.isError = true;
            return;
          }
          if (element.key == "Card_Internal" && !(/[0-9]{12}$/).test(evt.data[k])) {
            this.errorMsg = "The card number should contain at least 12-digit numeric value"
            this.isError = true;
            return;
          }
        }
      }
    }

     let responseEncryptedFields = [];
    for (let k in this.sensitiveInformtion) {
      if (evt.data[k] && evt.data[k] != '') {
        let str = evt.data[k];
        const last = str.substring(str.length - 4);
        const mask = "****";
        let arrayMap: any[] = [];
        arrayMap['SSN'] = { "key": "SSN_Internal", "name": "Social Security Numbers" };
        arrayMap['CARD'] = { "key": "Card_Internal", "name": "Credit Card Numbers" };
        arrayMap['UsTaxId'] = { "key": "SSN_Internal", "name": "US Tax Id" };
        arrayMap['NonUsTaxId'] = { "key": "GenericText_Internal", "name": "Non-US Tax Id" };
        arrayMap['PHI'] = { "key": "GenericText_Internal", "name": "Personal Health Information" };
        arrayMap['FBAN'] = { "key": "GenericText_Internal", "name": "Financial/Banking Account Number" };
        arrayMap['DL'] = { "key": "GenericText_Internal", "name": "Driver's Licenses" };
        arrayMap['NID'] = { "key": "GenericText_Internal", "name": "National IDs" };
        arrayMap['PASSPORT'] = { "key": "GenericText_Internal", "name": "Passport Number" };
        arrayMap['OTHER'] = { "key": "GenericText_Internal", "name": "OTHER" };
        let element = arrayMap[this.keyInformationCollection[k].typeOfSensitiveInformation];

        if (this.customComponentsService.responseEncryptedFieldsDataInSession.includes(k) && this.responseEncryptedFieldsData[k]) {


          this.responseEncryptedFieldsData[k].edit = true;
          this.responseEncryptedFieldsData[k].value = evt.data[k];
          responseEncryptedFields.push(JSON.parse(JSON.stringify(this.responseEncryptedFieldsData[k])))
        }
        else if (this.responseEncryptedFieldsData[k]) {
          responseEncryptedFields.push(JSON.parse(JSON.stringify(this.responseEncryptedFieldsData[k])))
        }
        else if (this.keyInformationCollection[k].typeOfSensitiveInformation != 'No') {

          this.responseEncryptedFieldsData[k] = { "id": "0", "responseId": this.formdata.id, "key": k, "value": evt.data[k], "isPageTemplateResponseId": "false", "cryptIdName": element.key, "selectedResponseDataElement": element.name };
          responseEncryptedFields.push(JSON.parse(JSON.stringify(this.responseEncryptedFieldsData[k])))
        }
        evt.data[k] = mask + last;
      }
    }

    this.customComponentsService.saveData$.next('-1');
    //   this.answerJson[this.currentFieldSetIndex] = { 'data': evt.data };
    //  this.answerJson[this.currentFieldSetIndex] = { 'data': evt.data };
    this.formJson.components.filter(component => component.type !== "button").forEach(element => {
      if (element.legend == this.currentFieldSet.components[0].legend) {
        //    element.fieldData.data = submittedData;
        if (!draftMode) {
          element.status = 'Completed';
        } else {
          element.status = 'skipped';
        }
      }
    });

    this.answerJson = this.formJson.components.filter((data) => {
      if (data.status == 'Completed') {
        return true;
      }
    });
    this.completedForm = this.answerJson.length;
    if (!draftMode) {
      this.formJson.components.filter(component => component.type !== "button").forEach(element => {
        if (element.legend == this.currentFieldSet.components[0].legend) {
          let formJson = JSON.parse(JSON.stringify(this.currentFieldSet).split("onditional2").join("onditional"))
          element.components = formJson.components[0].components;
        }
      });
    }
    this.answer = evt
    this.answer2 = evt.data;
    this.pdfanswer = evt;
    this.responseEncryptedFields = responseEncryptedFields;
    this.serviceAnswerJson[this.currentFieldSet.components[0].key] = {};
    for (let k in this.KeysInPageComponents[this.currentFieldSet.components[0].key]) {
      let key = this.KeysInPageComponents[this.currentFieldSet.components[0].key][k].key;
      this.serviceAnswerJson[this.currentFieldSet.components[0].key][key] = evt.data[key]
    }
    if (!draftMode) {
      this.statusCompleted[this.currentFieldSet.components[0].key] = 'completed';
    } else {
      this.statusCompleted[this.currentFieldSet.components[0].key] = 'skipped';
    }
    this.keyInformation[this.currentFieldSet.components[0].key] = {};

    for (let l in this.serviceAnswerJson[this.currentFieldSet.components[0].key]) {
      if (l !== "submit") {
        if (!(this.keysInTable[l] && this.keysInTable[l] == 'y') && !this.inGrid(l, evt.data)) {
          if (this.customeKeyArray[l]) {
            if (this.keyAlreadyExits(this.customeKeyArray[l], this.currentFieldSet.components[0].key)) {
              if (this.keyInformationCollection[l].type == 'file') {
                let fileUrl = '';
                if (this.serviceAnswerJson[this.currentFieldSet.components[0].key][l] && this.serviceAnswerJson[this.currentFieldSet.components[0].key][l][0] && this.serviceAnswerJson[this.currentFieldSet.components[0].key][l][0].url)
                  fileUrl = this.serviceAnswerJson[this.currentFieldSet.components[0].key][l][0].url
                this.keyInformation[this.currentFieldSet.components[0].key][this.customeKeyArray[l]] = fileUrl;
              } else if (this.keyInformationCollection[l].type == 'datetime') {
                this.keyInformation[this.currentFieldSet.components[0].key][this.customeKeyArray[l]] = this.dateValue(this.serviceAnswerJson[this.currentFieldSet.components[0].key][l], this.keyInformationCollection[l].widget.format);
              } else {
                this.keyInformation[this.currentFieldSet.components[0].key][this.customeKeyArray[l]] = (this.serviceAnswerJson[this.currentFieldSet.components[0].key][l]);
              }
            }
          }
          else {
            if (this.keyAlreadyExits(l, this.currentFieldSet.components[0].key)) {
              if (this.keyInformationCollection[l].type == 'file') {
                let fileUrl = '';
                if (this.serviceAnswerJson[this.currentFieldSet.components[0].key][l] && this.serviceAnswerJson[this.currentFieldSet.components[0].key][l][0] && this.serviceAnswerJson[this.currentFieldSet.components[0].key][l][0].url)
                  fileUrl = this.serviceAnswerJson[this.currentFieldSet.components[0].key][l][0].url
                this.keyInformation[this.currentFieldSet.components[0].key][l] = fileUrl;
              } else if (this.keyInformationCollection[l].type == 'datetime') {
                this.keyInformation[this.currentFieldSet.components[0].key][l] = this.dateValue(this.serviceAnswerJson[this.currentFieldSet.components[0].key][l], this.keyInformationCollection[l].widget.format);
              } else {
                this.keyInformation[this.currentFieldSet.components[0].key][l] = (this.serviceAnswerJson[this.currentFieldSet.components[0].key][l]);
              }
            }
          }
        }
      }
    }

    this.keysToSubmitTable = {};
    if (this.tableFieldsJson[this.currentFieldSet.components[0].key]) {
      for (let tables in this.tableFieldsJson[this.currentFieldSet.components[0].key]) {

        this.keysToSubmitTable[tables] = {};
        for (let tableElement in this.tableFieldsJson[this.currentFieldSet.components[0].key][tables]) {
          for (let keysElement in this.tableFieldsJson[this.currentFieldSet.components[0].key][tables][0].data) {
            for (let h in this.tableFieldsJson[this.currentFieldSet.components[0].key][tables][0].data[keysElement]) {
              let element = this.tableFieldsJson[this.currentFieldSet.components[0].key][tables][0].data[keysElement][h].split('#!#!');

              if (this.keysToSubmitTable[tables][keysElement]) { }
              else {
                this.keysToSubmitTable[tables][keysElement] = {};
              } if (element[1] == 'htmlelement') {
                // //console.log(tableElement);
                this.keysToSubmitTable[tables][keysElement][h] = element[0]
              } else {
                if (!isUndefined(evt.data[element[0]])) {
                  if (this.keyInformationCollection[element[0]].type == 'file') {
                    let fileUrl = '';
                    if (evt.data[element[0]][0] && evt.data[element[0]][0].url)
                      fileUrl = (evt.data[element[0]][0].url)

                    this.keysToSubmitTable[tables][keysElement][h] = fileUrl;
                  } else if (this.keyInformationCollection[element[0]].type == 'datetime') {
                    this.keysToSubmitTable[tables][keysElement][h] = this.dateValue(evt.data[element[0]], this.keyInformationCollection[element[0]].widget.format);
                  }
                  else {
                    this.keysToSubmitTable[tables][keysElement][h] = (evt.data[element[0]]);
                  }
                }
                else {
                  this.keysToSubmitTable[tables][keysElement][h] = '';
                }
              }
            }
          }
        }
      }
      this.keyInformation[this.currentFieldSet.components[0].key]['table'] = this.keysToSubmitTable;
    }

    for (let k in this.gridId[this.currentFieldSet.components[0].key]) {
      var component = Utils.getComponent(this.currentFieldSet.components, k, true);
      let validKeys: any = [];
      this.keysToSubmitTable[k] = {};
      this.keysToSubmitTable[k][0] = {};
      if (component) {
        for (let a in component.components) {
          if (this.labelForKey && this.labelForKey[component.components[a].key]) {
            validKeys.push({ 'key': component.components[a].key, "type": component.components[a].type, "component": component.components[a] });
            this.keysToSubmitTable[k][0][a] = this.labelForKey[component.components[a].key];
          }
        }
      }

      if (evt.data[k]) {
        for (let i = 0; i < evt.data[k].length; i++) {
          let m = 0;
          if (!this.keysToSubmitTable[k][i + 1]) {
            this.keysToSubmitTable[k][i + 1] = {};
          }
          for (let j in validKeys) {
            let keyName = validKeys[j].key;
            let typeName = validKeys[j].type;
            let component = validKeys[j].component;
            if (typeName == 'file') {
              let fileUrl = '';
              if (evt.data[k][i][keyName] && evt.data[k][i][keyName][0] && evt.data[k][i][keyName][0].url)
                fileUrl = (evt.data[k][i][keyName][0].url)

              this.keysToSubmitTable[k][i + 1][j] = fileUrl;
            } else if (typeName == 'datetime') {
              this.keysToSubmitTable[k][i + 1][j] = this.dateValue(evt.data[k][i][keyName], component.widget.format);
            }
            else {
              if (evt.data[k][i][keyName] == null)
                this.keysToSubmitTable[k][i + 1][j] = '';
              else
                this.keysToSubmitTable[k][i + 1][j] = (evt.data[k][i][keyName]);
            }

            m = m + 1
          }
        }
      }

      this.keyInformation[this.currentFieldSet.components[0].key]['table'] = this.keysToSubmitTable;
    }

    this.answer2 = evt.data;
    // this.serviceAnswerJson = evt.data;
    this.updateData(true);
    for (let k in this.fieldSets) {
      if (this.fieldSets[k].tempshow) {
        if (this.fieldSets[k].show != false) {
          if (this.fieldSets[k].key == this.currentFieldSet.components[0].key) {
            if (draftMode)
              this.pageStatus[this.currentFieldSet.components[0].key] = { 'status': 'inprogress', 'title': this.fieldSets[k].title, 'key': this.fieldSets[k].key }
            else
              this.pageStatus[this.currentFieldSet.components[0].key] = { 'status': 'completed', 'title': this.fieldSets[k].title, 'key': this.fieldSets[k].key }
          }
          else if (this.pageStatus && this.pageStatus[this.currentFieldSet.components[0].key]) {
            if (this.pageStatus[this.fieldSets[k].key] != null)
              this.pageStatus[this.fieldSets[k].key] = { 'status': this.pageStatus[this.fieldSets[k].key].status, 'title': this.fieldSets[k].title, 'key': this.fieldSets[k].key }
            else
              this.pageStatus[this.fieldSets[k].key] = { 'status': 'Not Started', 'title': this.fieldSets[k].title, 'key': this.fieldSets[k].key }
          }
          else {
            this.pageStatus[this.fieldSets[k].key] = { 'status': 'Not Started', 'title': this.fieldSets[k].title, 'key': this.fieldSets[k].key }
          }
        }
      }
    }
    //sd
    this.formJsonCopy = JSON.parse(JSON.stringify(this.formJson))
    //this.exportPDF();
    let percentage: number = (this.completedForm / this.pageCount) * 100;

    let body = {
      "answerJSON": JSON.stringify({ 'answer': this.serviceAnswerJson, 'answer2': evt.data, 'keyinformation': this.keyInformation, 'status': this.statusCompleted, 'pageStatus': this.pageStatus }),
      "id": this.formdata.id,
      "title": this.formdata.title,
      "description": this.formdata.formDes,
      "rowVersion": this.formdata.rowVersion,
      "dueDate": this.formdata.dueDate,
      "percentageComplete": (percentage || 0),
      "responseEncryptedFields": responseEncryptedFields
    };

    if (this.formChange) {
      body["formJSON"] = JSON.stringify(this.formJson);
    }

    const getStatusId = this.getStatusId();
    body["responseStatusId"] = getStatusId;
    const surveyId = this.masterService.getGuid();
    if (surveyId) {
      body["surveyId"] = surveyId;
      body["respondentEmailAddress"] = this.surveyService.respondentEmail;
    }

    if (!draftMode && (getStatusId == this.getStatus('completed') || getStatusId == this.getStatus('under review'))) {
      this.isPdfDownloaded = false;
      this.pdfAnswerDownload = JSON.parse(JSON.stringify(this.answer))
    if(this.pdfAllowedToDownload) {
      this.exportPDF2(true)
      this.addPdfGenerated.triggerPDF(this._manageForm.getResponseData());
    }

      // this.addPdfGenerated.initiatePDFLoad(evt);
    }
    if ((getStatusId == this.getStatus('completed') || getStatusId == this.getStatus('under review')) && this.selectClientComponentKeys.length > 0) {
      let clientBody = { 'clientId': this.answer.data[this.selectClientComponentKeys[0]].id }
      if (clientBody['clientId']) {
        this.formService.changeclient(this.formdata.projectId, clientBody.clientId).subscribe(
          response => {
          },
          error => { this.isError = true, this.errorMsg = error }
        );
      }
    }

    if ((getStatusId == this.getStatus('completed') || getStatusId == this.getStatus('under review')) && this.selectApproverComponentKeys.length > 0) {
      let approverList = this.changeApprover();
      body["responseStatusId"] = this.getStatus('completed');
      if (approverList.length == 0)
        body["responseStatusId"] = this.getStatus('completed');

        this.changeApprover$.next({ body, approverList, draftMode});
    } else {
      this.callServiceToSubmitAnswer(body, draftMode)
    }
  }
  callServiceToSubmitAnswer(body: any, draftMode: any) {
    if (this.isUnauthenticated || !this.readonly) {
      this.submitAnswer$.next({ body, draftMode });
    } else {
      this.isNextPage();
      this.nextForm()
    }
  }
  saveContinueForm(submitClicked: boolean) {
    this.isSubmitClicked = submitClicked;
    // $(window).scrollTop(0);
    this.showSuccess = false;
    this.formIncomplete = false;
    this.showSuccessDraft = false;
    this.isshowDelegateMessage = false;

    // for skip or incomplete pages : page level error message
    if (this.fieldSets.length > 1 && this.isSubmitClicked && this.isFieldSetsPending()) {
      this.formIncomplete = true;
      return;
    }
    // on submit, when form is complete: Confirmation Popup.
    if (!this.isResponseModalShow && submitClicked) {
      $('#submitFormResponse').modal('show');
      this.isResponseModalShow = true;
      return;
    }
    this.isResponseModalShow = false;
    this.formioForm.formio.submit(true);
    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      let error_div = $('.has-error')
      $('.has-message')[0].scrollIntoView(true);
    }, 100);
  }

  skipForm() {
    $(window).scrollTop(0);
    this.isNextPage();
    if (this.currentFieldSet.components[0].status !== "Completed") {
      this.currentFieldSet.components[0].status = "Skipped";
    }
    this.setFieldSet(++this.currentFieldSetIndex);
  }
  nextForm() {
    $(window).scrollTop(0);
    // this.serviceAnswerJson[this.currentFieldSet.components[0].key] = {};
    this.setFieldSet(++this.currentFieldSetIndex);
  }
  previousForm() {
    $(window).scrollTop(0);
    this.currentFieldSetIndex = this.currentFieldSetIndex - 1;
    if (this.fieldSets[this.currentFieldSetIndex].show == false) {
      this.previousForm();
      return;
    }

    this.setFieldSet(this.currentFieldSetIndex);
  }
  goBack() {
    if (this.isPreview) {
      this.location.back();
    }
    else if (this.route.snapshot.params["edit"] === "history") {
      this.masterService.isCancelledClickedHisotry(true);
      this.location.back();
    } else if (this.isIntake) {
      this._router.navigate(['/submit-request']);
    } else if (this._router.url.indexOf('admin') !== -1) {
      this._router.navigate(['/admin/form/list']);

    } else {
      this.masterService.isCancelledClicked(true);
      this.location.back();
      // this._router.navigate(['/response/list']);
    }
  }

  /********************* Approval WorkFlow BEGIN ***********************/
  isAllFieldSetsCompleted() {
    let visibleSteps = this.formJson.components.filter(item => (item.show != false));
    let completedFieldSets = this.formJson.components.filter(item => item.status && item.status.toLowerCase() === "completed");

    return (visibleSteps.length === completedFieldSets.length);
  }
  changeApprover() {
    const approverList = []; //Approver List from Select User Component
    let data = this.answer.data
    this.selectApproverComponentKeys.forEach(componentKey => {
      let component = this.keyInformationCollection[componentKey];
      let level = 1
      let show: boolean = true;
      if (component.properties && component.properties.hasOwnProperty('level') && (component.properties.level != "0" || component.properties.level != "")) {
        level = component.properties.level;
      }

      if (component.conditional && component.conditional != 'No' && component.conditional.show != null) {
        show = !component.conditional.show;
        if ((data && data[component.conditional.when] && data[component.conditional.when].toString() == component.conditional.eq.toString()) || ((isUndefined(this.keyInformationCollection[component.conditional.when]) || (this.keyInformationCollection[component.conditional.when] && (this.keyInformationCollection[component.conditional.when].type == 'checkbox' || this.keyInformationCollection[component.conditional.when].type == 'selectboxes'))) && data && data[component.conditional.when] && data[component.conditional.when][component.conditional.eq]) || (data && !data[component.conditional.when] && !component.conditional.eq)) {
          show = component.conditional.show;
        }
      } else if (component.customConditional && component.customConditional != 'No') {
        var a = '';
        if (data) {
          a = "var data=" + JSON.stringify(data) + ';' + component.customConditional;
        }
        show = true;
        var b = eval(a);
      }
      if (show) {
        if (component.type == 'select') {
          // return false;
          if (this.answer.data[componentKey] && this.answer.data[componentKey].id) {
            approverList.push({
              "email": this.answer.data[componentKey].email,
              "level": level
            })
          }
        }
        else if (component.type == 'selectboxes') {
          for (let i in this.answer.data[componentKey]) {
            if (this.answer.data[componentKey][i] && REGEX_PATTERNS.Email.test(i.toLowerCase()))
              approverList.push({
                "email": i,
                "level": level
              })
          }
        } else if (component.type == 'datagrid') {
          for (let i in this.answer.data[componentKey]) {
            for (let k in this.answer.data[componentKey][i]) {
              if (k.indexOf('approver') != -1) {
                let email = this.answer.data[componentKey][i][k].email;
                if (email && REGEX_PATTERNS.Email.test(email.toLowerCase())) {
                  approverList.push({
                    "email": email,
                    "level": level
                  })
                }
              }
            }
          }
        }
        else {
          // return false;
          if (this.answer.data[componentKey]) {
            approverList.push({
              "email": this.answer.data[componentKey],
              "level": level
            })
          }
        }
      }
    });
    return approverList;
  }
  getStatusId() {
    let statusId;
    if (!this.readonly && this.formdata && this.formdata.responseStatusId) {
      let formStatusId = this.formdata.responseStatusId;

      if (formStatusId == this.getStatus("not started") || formStatusId == this.getStatus("in progress") || formStatusId == this.getStatus("overdue")) {
        if (this.isSubmitClicked && this.isAllFieldSetsCompleted()) {
          if (this.isApproverSet()) {
            statusId = this.getStatus('under review');
          }
          else {
            statusId = this.getStatus('completed');
          }
        }
        else {
          if (formStatusId == this.getStatus("overdue")) {
            statusId = this.getStatus('overdue');
          }
          else {
            statusId = this.getStatus('in progress');
          }
        }
      }
      else if (formStatusId == this.getStatus("under review")) {
        statusId = this.getStatus('under review');
      }
      else if (formStatusId == this.getStatus("completed")) {
        statusId = this.getStatus('completed');
      }
    }
    return statusId;
  }
  getAllUsers() {
    let usersByOrganization;
    let usersByClient
    let userData = this.userService.getUserListData();
    let approverApiArr = [this.formService.getUsersAll()];
    //if (this.userService.isClient && userData.clientOrgId !== null)
    //  approverApiArr.push(this.clientsService.getClientDetailsById(userData.clientOrgId, "respondents"));

    forkJoin(approverApiArr).subscribe(userData => {
      usersByOrganization = userData[0];
      /*  usersByClient = userData[1];*/
      const allUsers = usersByOrganization;
      this.completeApproverList = allUsers.sort((a, b) => a.name > b.name ? 1 : -1);
      this.approverList();
    },
      (error) => { this.isError = true; this.errorMsg = error; });
  }

  assignContent() {
    this.submitLabel = this.content['formContainerSubmitButton'];
    this.cancelLabel = this.content['dataTableCancelAction'];
    this.nextLabel = this.content['formContainerNextButton'];
    this.continueLabel = this.content['formContainerContinueButton'];
    this.confirmLabel = this.content['formContainerConfirmButton'];
    this.submitModalTitle = this.content['formContainerSubmitFormResponseTitle'];
    this.submitModalSubitle = this.content['formContainerSubmitFormResponseSubtitle'];
    this.submitModalMessage = this.content['formContainerSubmitFormResponseMessage'];
    this.backIntakeList = this.content['formContainerBackIntakeList'];
  }

  isApprover() {
    let userData = this.userService.getUserListData();
    return (userData &&
      (userData.id === this.formdata.currentApproverId ||
        this.formdata.approvers.some(a => a.approverId === userData.id && a.level === this.formdata.currentLevel
          && !a.isReviewComplete)));
  }
  approverList() {
    console.log(this.completeApproverList);
    let formApproverList = [];
    let approverLevelDetails = [];
    approverLevelDetails[0] = [];
    for (let k in this.formdata.respondents) {
      if (this.formdata.respondents[k].assignedTo != null)
        approverLevelDetails[0].push(this.formdata.respondents[k].assignedTo.name);
      else
        approverLevelDetails[0].push(this.formdata.respondents[k].email);
    }
    for (let k in this.formdata.approvers) {
      //  this.formdata.currentLevel = 2;
      let userData = this.projectsService.fetchDetailsForUserById(this.completeApproverList, this.formdata.approvers[k].approverId);

      if (this.formdata.approvers[k].level < this.formdata.currentLevel) {
        if (!approverLevelDetails[this.formdata.approvers[k].level]) {
          approverLevelDetails[this.formdata.approvers[k].level] = [];

        }
        approverLevelDetails[this.formdata.approvers[k].level].push(userData.name)
        // formApproverList.push({ 'userData': userData})
      }
    }
    for (let j in approverLevelDetails) {
      let levelName = '';
      if (approverLevelDetails[j] != '') {
        if (j == '0') {
          levelName = 'Form Respondent / Intake Submitter';
        } else {
          levelName = 'Approver Level ' + j
        }
        formApproverList.push({ 'id': j, 'level': levelName, 'name': approverLevelDetails[j].join(',') })
      }
    }
    this.sentBackApprover = formApproverList;
  }
  isApproverSet() {
    return (this.formdata && this.formdata.currentApproverId);
  }

  getStatus(value: string) {
    const resolvedData: IResponseStatusResloved = this.route.snapshot.data['resolveData'] || {};
    if (!resolvedData.responseStatus) {
      resolvedData.responseStatus = this.masterService.getResponseStatusList();
    }
    let status = this.isUnauthenticated ? this.masterService.getResponseStatusList().filter(item => item.title.trim().toLowerCase() === value) : resolvedData.responseStatus.filter(item => item.title.trim().toLowerCase() === value);
    if (!this.readonly)
      return status[0].id.toString();
    else
      return '';
  }

  isApproverAndUnderReview() {
    let userData = this.userService.getUserListData();
    return (!this.isHistory
      && !this.formdata?.isReadOnly
      && this.formdata.responseStatusId == this.getStatus("under review")
      && this.formdata.approvers.some(a => a.approverId === userData.id && a.level === this.formdata.currentLevel)
    );
  }

  openApprovalModal(value: boolean) {
    this.isError = false;
    this.isApproved = value;
    this.initApprovalCommentsForm();
    $('#approvalComments').modal('show');
  }
  openRejectModal(isHold = false) {
    this.isError = false;
    this.isRejected = !isHold;
    this.initRejectedCommentsForm();
    $('#rejectedComments').modal('show');
  }
  initRejectedCommentsForm() {
    let validatorArray = [Validators.maxLength(this._maxLengths.Approval.Comments)]

    validatorArray.push(Validators.required);
    //Comments are mandatory when response is rejected
    this.approvalForm = new FormGroup({
      comments: new FormControl('', validatorArray),
      sentBackLevel: new FormControl('0', validatorArray)
    });
    this.isSubmitted = false;
  }
  initApprovalCommentsForm() {
    let validatorArray = [Validators.maxLength(this._maxLengths.Approval.Comments)]
    if (!this.isApproved)
      validatorArray.push(Validators.required);
    //Comments are mandatory when response is rejected
    this.approvalForm = new FormGroup({
      comments: new FormControl('', validatorArray),
      sentBackLevel: new FormControl('0', validatorArray)
    });
    this.isSubmitted = false;
  }

  navigateConfirmation() {
    if (this.isPdfDownloaded && this.redirectionNeeded) {
      if (this.redirectToPage) {
        this._router.navigate(["../confirmation"], { relativeTo: this.route });
        //  this.redirectToPage = isUnauthenticated;
      }
      else {
        this._router.navigate(['response/confirmation' + this.formdata.id]);
      }
    }
  }

  submitApprovalForm(isrejectedFlow: any = '') {
    this.resetErrorAndSuccessMsg();

    this.isSubmitted = true;
    let approverSentBackLevel = 0;
    if (isrejectedFlow == '') {

      let levelValue = this.approvalForm.get('sentBackLevel').value;
      if (levelValue !== '' && Number(levelValue)) {
        approverSentBackLevel = Number(levelValue);
      }
    }
    if (this.approvalForm.valid) {
      let body = {
        "responseId": this.formdata.id,
        "isApproved": this.isApproved,
        "comments": this.approvalForm.get('comments').value,

      };
      if (this.approvalForm.get('comments').value?.trim() != '') {
        this.commentValue = this.approvalForm.get('comments').value
        let eventName = ''
        if (isrejectedFlow == 'rejected')
          eventName = 'Rejected';
        else if (isrejectedFlow == 'hold')
          eventName = 'Hold';
        else if (this.isApproved)
          eventName = 'Approved';
        else
          eventName = 'Sent Back';
      }
      this.isPdfDownloaded = false;
      if(this.pdfAllowedToDownload) {
          this.exportPDF2(true)
            this.addPdfGenerated.triggerPDF(this._manageForm.getResponseData());
      }

      let subscriber;
      if (isrejectedFlow == 'hold') {
        let body = {
          "status": 'hold',
          "comments": this.approvalForm.get('comments').value
        };
        subscriber = this.formService.holdResponse(body, this.formdata.id);
      }
      else if (isrejectedFlow == 'rejected') {
        let body = {
          "status": 'reject',
          "comments": this.approvalForm.get('comments').value
        };
        subscriber = this.formService.rejectedResponse(body, this.formdata.id);
      }
      else {
        if (approverSentBackLevel == 0) {
          subscriber = this.formService.approveResponse(body);
        }
        else {
          let data2 = this.formdata.approvers
          for (let k in data2) {
            if (approverSentBackLevel <= data2[k].level) {
              data2[k].isReviewComplete = false;
              if (this.approvalForm.get('comments').value?.trim() != '') {
                this.commentValue = this.approvalForm.get('comments').value
              }
            }
          }
          let data = {
            "approvers": data2,
            "comments": this.approvalForm.get('comments').value
          }

          subscriber = this.formService.sendBackToApprover(this.formdata.id, data);
        }
      }

      subscriber.subscribe(
        () => {
          this.isError = false;
          $('#approvalComments').modal('hide');
          let successMessage = `The form '${this.formdata.title}' has been  `;
          if (isrejectedFlow == 'hold') {
            successMessage += 'put on hold.';
          }
          else if (isrejectedFlow == 'rejected') {
            successMessage += 'rejected.';
          }
          else if (this.isApproved)
            successMessage += 'successfully approved.';
          else
            successMessage += 'successfully sent back.';

          this._manageForm.approvalMsg = successMessage;
          this.isApproverFlow = true;
          this.redirectionNeeded = true;
          $('#rejectedComments').modal('hide');
          this.navigateToConfirmation();
        },
        error => { this.isError = true; this.errorMsg = error; }
      );

    }
  }
  isFieldSetsPending() {
    let result = false;

    let pendingFieldSets = this.formJson.components
      .filter(item => ((isNullOrUndefined(item.status) || (!item.status || (item.status && (item.status == ''))) || (item.status && item.status.toLowerCase() != "completed")) && (isNullOrUndefined(item.tempshow) || item.tempshow != false)))
      .map(item => item.key);
    const index = pendingFieldSets.indexOf(this.currentFieldSet.components[0].key);
    if (index > -1) {
      pendingFieldSets.splice(index, 1);
    }
    if (pendingFieldSets.length > 0) {
      result = true;
    }
    let visibleSteps = this.formJson.components
      .filter(item => {
        if (item.key != this.currentFieldSet.components[0].key && (isNullOrUndefined(item.status) || (!item.status || (item.status && (item.status == ''))) || (item.status && item.status.toLowerCase() != "completed")) && (isNullOrUndefined(item.show) || item.tempshow != false)) {
          return true;
        }
      }).map(item => item.title);
    if (result) {
      this.missingMessage = "Page " + visibleSteps.join(", ") + " is not yet marked complete. To submit the form, each page must be marked complete by clicking the \"Continue\" button.";
    }
    return result;
  }

  doSubmitButton() {
    let show = false;
    if (this.formdata && this.formdata.responseStatusId &&
      (this.formdata.responseStatusId == this.getStatus("not started") ||
        this.formdata.responseStatusId == this.getStatus("in progress") ||
        this.formdata.responseStatusId == this.getStatus("overdue"))
      && !this.isShowPage && !this.readonly) {
      show = true;
    }
    return show;
  }

  formBtnsHandler() {
    switch (this.callBackEvent.name) {
      case 'skip':
        this.skipForm();
        break;
      case 'previous':
        this.previousForm();
        break;
      case 'next':
        this.nextForm();
        break;
      case 'approve':
        this.openApprovalModal(true);
        break;
      case 'reject':
        this.openRejectModal();
        break;
      case 'hold':
        this.openRejectModal(true);
        break;
      case 'sendBack':
        this.openApprovalModal(false);
        break;
      case 'fieldSetChange':
        this.setFieldSet(this.callBackEvent.data);
        break;
    }
  }

  checkDataLoss(callBack, callBackData = null) {
    let dataChange: boolean = false;
    this.callBackEvent = { 'name': callBack, 'data': callBackData };
    if (this.changeNewData && !this.readonly && this.dataChange) {
      $('#dataLoss').modal('show');
    }
    else {
      this.formBtnsHandler();
    }
  }

  closeDataLossModal() {
    $('#dataLoss').modal('hide');
  }
  ngOnDestroy() {
    this._manageForm.isPreview = false;
    this._pdfGenerateService.generatePdf$.next('-1');
    this.formService.triggerSave$.unsubscribe();
    this._manageForm.isPreview = false;

    this._pdfGenerateService.generatePdf$.next('-1');
    this.formService.triggerSave$.unsubscribe();
    this.customComponentsService.setData({});
    this.customComponentsService.saveData$.next('-1');
    this.customComponentsService.setDataReadOnly(false);
    this.customComponentsService.setResponseId('');
    this.customComponentsService.setRespondentEmailAddress('');
    this.customComponentsService.setSensitiveData([]);
    this.customComponentsService.responseEncryptedFieldsDataInSession = [];
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  /*  Comment Changes Start */
  updateCommentHistory(responseId) {
    this.resetErrorAndSuccessMsg();
    if (this.isUnauthenticated) {
      let surveyId = this.masterService.getGuid();
      this.surveyService.getSurveyForCommentHistory(surveyId).subscribe(
        (data) => {
          this.commentHistoryList = (data?.response?.responseComments || []);
          this.setBadgeCount();
        }
      );
    }
    else {
      this.formService.getResponseJson(responseId).subscribe(
        (data) => {
          this.commentHistoryList = data.responseComments;
          console.log('*--------------*--------------*------------*')
          console.log(this.commentHistoryList)
          console.log('*--------------*--------------*------------*')
          this.setBadgeCount();
        }
      );
    }
  }
  setBadgeCount() {
    if (this.commentHistoryList.length > 0) {
      this.commentLength = this.commentHistoryList.length;
      this.showCommentBadge = true;
      this.commentBadgeCount = this.commentHistoryList.length;
      let options: string[] = this.commentHistoryList.filter(el => el.pageName != null && el.pageName.trim() != '').map(item => item.pageName);
      this.commentHistFilterOptions = [...new Set(options)];
    }
  }
  openAddCommentModal() {
    $('#addcomment').modal('show');
    this.commentValue = '';
    this.commentHistSelectedPgName = '';
    this.commentCat = 'Comment';
    this.commentCnt = 0;
  }

  // PBI # 169191 changes start
  getCommentClass(cnt) {
    if (cnt > 5) {
      return 'table-responsive mb-0';
    }
  }

  onCommentFileChange(event) {
    this.isCommentError = false;
      this.commentErrorMsg = "";
    let fileCtrl = this.commentAnswer.get("commentCtrl");
    if (fileCtrl.untouched) {
      fileCtrl.markAsTouched();  //to trigger required field validation
    }

    const target: DataTransfer = <DataTransfer>event.target;
    const fileList: FileList = target.files;
    if (fileList.length === 0) {
      this.filename = '';
      fileCtrl.setValue(null);
      return;
    }

    const validSize = FileValidators.ValidateFileSize(fileCtrl);
    if (validSize?.invalidSize) {
      this.filename = '';
      fileCtrl.setValue(null);
      this.isCommentError = true;
      this.commentErrorMsg = "File size exceeds maximum size limit i.e. 50 MB";
      this.commentFileInput.nativeElement.value = "";
      return;
    }

    this.commentFile = target.files;
    this.commentFileName = this.commentFile.item(0).name;
    const file = fileList.item(0);
    this.validCommentFileName = FileValidators.ValidateFileName(file.name);
    if (this.validCommentFileName){
      this.validCommentFileExtension = !FileValidators.validateExtensionFile(file).invalidExt;
    }
    else{
      this.isCommentError = true;
      this.commentErrorMsg = REGEX_VALIDATION_MESSAGE.FILENAME_VALIDATION_MESSAGE;
      this.filename = file.name;
      return;
    }

    if (this.validCommentFileExtension){
      const vct = FileValidators.ValidateContentTypeFile();
      this.validCommentFileType = !vct(file).invalidType;
    }

    this.filename = file.name;
    fileCtrl.setValue(file);
  }

  downloadAttachment(event) {
    window.open(event.data, "_blank");
  }

  commentActions(event) {
    switch (event.action) {
      case "replace":
        this.commentId = event.data;
        this.commentResponseId = this.formdata.id;
        //$('#replaceAttachment').modal('show');
        $('#replaceAttachment').show();
        $("#replaceAttachment").addClass('show');
        $('body').addClass('modal-open');
        break;
      case "delete":
        this.eventData = event.data;
        $('#deleteWarningModal').show();
        $("#deleteWarningModal").addClass('show');
        $('body').addClass('modal-open');
        break;
      case "remove":
        this.eventData = event.data;
        $('#removeAttachmentWarningModal').show();
        $("#removeAttachmentWarningModal").addClass('show');
        $('body').addClass('modal-open');
        break;
      default:
        break;
    }
  }

  deleteComment(id) {
    this.formService.deleteResponseComment(id, this.formdata.id)
      .subscribe(
        () => {
          this.updateCommentHistory(this.formdata.id);
        },
        error => {
          this.isCommentError = true;
          this.commentErrorMsg = error;
        }
      );
  }

  removeAttachment(id) {
    let body = {
      "responseId": this.formdata.id,
      "comment": this.commentValue,
      "pageName": (this.fieldSets[this.currentFieldSetIndex].title || ''),
      "category": this.commentCat?.trim() || '',
      "file": ""
    };

    this.formService.updateResponseComment(body, id, this.formdata.id)
      .subscribe(
        () => {
          this.updateCommentHistory(this.formdata.id);
        },
        error => {
          this.isCommentError = true;
          this.commentErrorMsg = error;
        }
      );
  }

  updateAttachmentSuccess(event) {
    this.updateCommentHistory(this.formdata.id);
    // $("#replaceAttachment").modal('hide');
    $("#replaceAttachment").hide();
    $('.modal-backdrop').remove();
    $('body').addClass('modal-open');
    $("#replaceAttachment").removeClass('show');
  }

  cancelCommentHandle() {
    this.commentValue = '';
    this.commentFile = '';
    this.filename = '';
    this.commentFileName = '';
    this.isCommentError = false;
    this.commentErrorMsg = "";
    this.isCommentSubmit = false;
    this.commentFileInput.nativeElement.value = "";
  }
  // PBI # 169191 changes end

  submitCommentHandle(pageName: any = 'No', cat: any = '') {
    if(!this.validators.description_comment.test(this.commentValue)) return;
    if(this.filename != '' && !this.validators.FileName.test(this.filename)) return;
    this.resetErrorAndSuccessMsg();
    if (this.commentValue?.trim() != '') {
      this.isCommentSubmit = true;
      if (cat == '') {
        cat = this.commentCat?.trim()
      }

      let uploadedFile: any;
      if (!this.commentFileName) {
        uploadedFile = "";
      }
      else {
        uploadedFile = this.commentFile.item(0);
      }

      let body = {
        "responseId": this.formdata.id,
        "comment": this.commentValue,
        "pageName": (this.fieldSets[this.currentFieldSetIndex].title || ''),
        "category": cat,
        "file": uploadedFile
      };

      if (pageName != 'No') {
        body.pageName = pageName;
        body.category = "Comment";
      }

      this.formService.postResponseComment(body, this.formdata.id).subscribe(
        (data) => {
          if (pageName == 'No') {
            this.updateCommentHistory(this.formdata.id);
          }
          this.commentCat = 'Comment';
          this.commentValue = '';
          this.commentFile = '';
          this.filename = '';
          this.commentFileName = '';
          this.isCommentError = false;
          this.commentErrorMsg = "";
          this.isCommentSubmit = false;
          this.commentFileInput.nativeElement.value = "";
          this.commentCnt = 0;
        },
        (error) => {
          this.commentValue = '';
          this.commentFile = '';
          this.filename = '';
          this.commentFileName = '';
          this.isCommentError = true;
          this.commentErrorMsg = error;
          this.isCommentSubmit = false;
          this.commentFileInput.nativeElement.value = "";
          this.commentCnt = 0;
          // $('#addcomment').modal('hide')
        }
      );
    }
    else {
      this.isCommentError = true;
      this.commentErrorMsg = "Please enter the comment to save.";
    }
  }

  /*  Comment Changes End */
  uploadJsonAnswerData() {
    //
    if (this.uploadAnswer.valid) {
      const reader: FileReader = new FileReader();
      const fileToRead = this.uploadAnswer.controls['fileCtrl'].value;
      reader.onload = (e: any) => {
        /* read workbook */

        const bstr: any = (e.target.result);

        let bstr3 = JSON.parse(bstr);
        this.answer2 = { 'data': {} };
        for (let i in bstr3) {
          if (this.keyInformationCollection[i] && this.keyInformationCollection[i].disabled) {
            delete bstr3[i]
          }
        }
        this.answer2.data = bstr3;
        this.serviceAnswerPopulate(true);
        let fileCtrl = this.uploadAnswer.get("fileCtrl");
        fileCtrl.setValue(null);
        this.filename = '';
        $('#fileUpload').val(null)
        $('#uploadAnswer').modal('hide');
      }
      reader.readAsText(fileToRead);
    }
  }
  cancelAnswerData() {
    $('#fileUpload').val(null)
    $('#uploadAnswer').modal('hide');
  }
  onFileChange(event) {
    let fileCtrl = this.uploadAnswer.get("fileCtrl");
    if (fileCtrl.untouched) fileCtrl.markAsTouched(); //to trigger required field validation
    const target: DataTransfer = <DataTransfer>event.target;
    let fileList: FileList = target.files;
    let maxFileSize = MAX_LENGTHS.File.Size;
    if(fileList.item(0).size <= (maxFileSize * 1024 * 1024)){
      if (fileList.length > 0) {
        const file = fileList.item(0);
        this.filename = file.name;
        this.validFileName= FileValidators.ValidateFileName( file.name);
        fileCtrl.setValue(file);
      }
    }else{
      this.isError = true;
      this.errorMsg = "File size exceeds maximum size limit i.e. 50 MB";
      // this.inputFile.nativeElement.value = "";
    }
  }

  resetErrorAndSuccessMsg() {
    // Flags: Conditional Property
    this.showSuccess = false;
    this.isshowDelegateMessage = false;
    this.showSuccessDraft = false;
    this.formIncomplete = false;
    this.isError = false;

    // Variables: Messages Property
    this.showDelegateMessage = "";
    this.draftModeMessage = "";
    this.errorMsg = "";
  }

  addRemoveFavorites() {
    if (!this.isFavorite) {
      this.favoritesService.saveFavoriteResponse(this.formdata.id).subscribe(data => {
        this.isFavorite = true;
        this.isResponseFavorites();
      });
    } else {
      this.favoritesService.removeFavoriteResponse(this.formdata.id).subscribe(data => {
        this.isFavorite = false;
        this.isResponseFavorites();
      });
    }
  }
  showFav() {
    if (this._router.url.indexOf('admin') !== -1 || this.isHistory || this._router.url.indexOf('intake') !== -1) {
      return false;
    }
    let k = !this.isUnauthenticated;
    return k;
  }
  isResponseFavorites() {
    this.favoritesService.isResponsesFavorites(this.formdata.id).subscribe(data => {
      this.isFavorite = data;
    });
  }

  commentCount() {
    this.commentCnt = this.commentValue.length;
  }

  showConditional() {
    this.showConditionalPages = !this.showConditionalPages;
    this.setFieldSet(this.currentFieldSetIndex);
  }

  changeResponseApprover(body: any, approverList: any, draftMode: boolean): any {
    return this.formService.changeResponseApprover(this.formdata.id, approverList).pipe(
      map((_) => {
        this.callServiceToSubmitAnswer(body, draftMode);
      }),
      catchError((error) => {
        this.isError = true;
        this.errorMsg = error;
        return EMPTY;
      }),
      takeUntil(this.destroy$)
      );
  }

  submitAnswer(body: any, draftMode: boolean): any {
    let subcriber;
    if (this.isUnauthenticated) {
      subcriber = this.surveyService.submitSurvey(body);
    } else if (!this.readonly) {
      subcriber = this.formService.submitAnswer(body);
    }
    return subcriber.pipe(
      map((data: any) => {
        this.formdata.rowVersion = data.rowVersion;
        if (data.responseEncryptedFields) {
          this.responseEncryptedFields = data.responseEncryptedFields;
        }
        if (!draftMode) {
          this.saveAndSubmitResponse(this.isUnauthenticated);
        } else {
          this.setFieldSet(this.currentFieldSetIndex, true);
          if (this.uploadedAnswerJson) {
            this.uploadedAnswerJson = false;
            this.draftModeMessage = 'Your response was successfully imported and saved. To submit the form, you must provide input to all the mandatory fields.';
          } else {
            this.draftModeMessage = 'Your response was successfully saved. To submit the form, you must provide input to all the mandatory fields.';
          }
          this.showSuccessDraft = true;
        }
      }),
      catchError((err) => {
        if(this.isUnauthenticated) {
          let isExpired = this.surveyService.getExpiredSurvey();
          if (isExpired === "expired") {
            this._router.navigate(["../expired"], { relativeTo: this.route });
          } else {
            this.isError = true;
            this.errorMsg = err;
          }
        } else if (!this.readonly) {
          this.isError = true;
          this.errorMsg = err;
          this.answer.data = JSON.parse(JSON.stringify(this.eventDataCopy));
          this.answerDataPopulation();
        }
        return EMPTY;
      }),
      takeUntil(this.destroy$)
    );
  }
}

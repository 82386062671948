<table aria-describedby="tblDesc" class="f-12 table table-hover table-label">
  <thead>
    <tr role="row">
      <th scope="col" role="columnheader" *ngFor="let column of headerColumns; let i = index;">
        <span *ngIf="!applySorting(keys[i])" href="javascript:void(0);">
          {{column}}
        </span>
        <a (click)="sort(keys[i])" *ngIf="applySorting(keys[i])" href="javascript:void(0);">
          {{column}}
          <i class="material-icons msort14" aria-hidden="true" *ngIf="isAsc && filterColumn == keys[i]">arrow_upward</i>
          <i class="material-icons msort14" aria-hidden="true" *ngIf="!isAsc && filterColumn == keys[i]">arrow_downward</i>
        </a>
        <i id="targetDateIcon" *ngIf="column == 'Target Delivery Date'" title="The currently agreed upon date of delivery based upon revision of the Original Delivery Date and may be revised based on changes to the project execution plan" 
            class="fa fa-question-circle question-icon text-muted" ref="tooltip" data-toggle="tooltip" data-animation="false"></i>
        <i id="OriginalDeliveryDate" *ngIf="column == 'Original Delivery Date'" title="The first committed date for delivery based on the project execution plan." 
            class="fa fa-question-circle question-icon text-muted" ref="tooltip" data-toggle="tooltip" data-animation="false"></i>
      </th>
      <th scope="col" role="columnheader" *ngIf="showActionButton"></th>
    </tr>
  </thead>
  <tbody  class="cdk-drop-list data-table-body cdk-drop-list-disabled" id="cdk-drop-list-0">
    <ng-container *ngFor="let row of getRows(); let i = index;">
      <tr role="row" class="cdk-drag cdk-drag-disabled" [ngClass]="highlightRow(row,'isRisk')">
      <td role="cell" *ngFor="let key of keys; let j = index;" >
        <div *ngIf="key !== 'dateChangeByStakeholder' && key !== 'clarityProjectNumber' && key !== 'plannedCommittedDeliveryDate'">
          <label for="myCheckboxZero" data-placement="top" *ngIf="!isStatusColumn(key) && !isDate(key)" title="{{row[key]}}">{{row[key]}} </label>
          <label for="myCheckboxZero" data-placement="top" class="badge-table" *ngIf="isStatusColumn(key) && !isDate(key)" >
              <span *ngIf="statusClass(row,key)!='blank'" class="badge-table-status {{statusClass(row,key)}}">
                {{includeSymbol(row,'isRisk')}}
              </span>
              <span *ngIf="statusClass(row,key)=='blank' && !isStatusKey(key) && isRiskColumn(key)" class="badge-table-status gray"></span>
              {{statusDocument(row[key])}}
          </label>
          <div style="text-align: center;" >
            <label [style.fontWeight]="row[key]?.trim() ? 'normal' : 'bold'"  for="myCheckboxZero" *ngIf="isDate(key) &&!isStatusColumn(key) && row[key]!='01/01/0001' && row[key]!='12/31/0001' && row[key]!='0001-01-01T00:00:00Z'" data-placement="top" title="{{ row[key] && row[key] !== '— — —' ? (row[key] | date: content['dateFormat']) : '— — —' }}">{{ row[key] && row[key] !== '— — —' ? (row[key] | date: content['dateFormat']) : '— — —' }}</label>
          </div>
        </div>
        <div class="ml-1" *ngIf="key === 'dateChangeByStakeholder'">
          <div title="Client {{row.dateChangeByStakeholderClient}}" class="container-stakeholder d-flex justify-content-start ">
            <div class="left-side"> <label>Client </label></div>
            <div class="right-side" [ngClass]="highlightRowDateChange(row,'isRisk')"> <label>{{row.dateChangeByStakeholderClient}}</label></div>
          </div>
          <div title="Fiserv {{row.dateChangeByStakeholderFiserv}}" class="container-stakeholder d-flex justify-content-start mt-1">
            <div class="left-side"> <label>Fiserv </label></div>
            <div class="right-side" [ngClass]="highlightRowDateChange(row,'isRisk')"> <label>{{row.dateChangeByStakeholderFiserv}}</label></div>
          </div>
        </div>
        <div *ngIf="key === 'clarityProjectNumber'">
          <label [style.fontWeight]="row[key]?.trim() ? 'normal' : 'bold'" for="myCheckboxZero" data-placement="top" *ngIf="!isStatusColumn(key) && !isDate(key)" title="{{row[key]}}">{{ row[key]?.trim() || '— — —' }}</label>
        </div>
        <div style="text-align: center;" *ngIf="key === 'plannedCommittedDeliveryDate'">
          <label  [style.fontWeight]="row[key]?.trim() ? 'normal' : 'bold'" for="myCheckboxZero" data-placement="top" *ngIf="!isStatusColumn(key) && !isDate(key)" title="{{row[key]}}">{{ row[key]?.trim() || '— — —' }}</label>
        </div>
      </td>
      <!-- <td role="cell"><div class="linear-loader-container"><div class="linear-loader"><div class="indicator" style="width:100%;"></div></div><span>100%</span></div></td>
   -->
      <td *ngIf="showActionButton">       
        <button (click)="onClick(row, 'view')" class="link" [attr.title]="'Show detail for ' + row.commitmentTitle">
          <div class="material-icons" aria-hidden="true">
            chevron_right
          </div>
        </button>
      </td>
    </tr>
        <tr *ngIf="row[extraRowFieldName]">
        <th style="border-top:none !important; color: #00000099; font-size: 14px; font-weight: 600;">Note: </th>
        <td style="border-top:none;" [attr.colspan]="headerColumns.length - 1">
          <pre style="white-space: pre-wrap;word-wrap: break-word; color: #212529; font-weight: 400; font: normal normal normal 14px/14px Segoe UI;">{{row[extraRowFieldName]}}</pre>         
        </td>
      </tr>
        </ng-container>
  </tbody>
</table>
<div role="status" *ngIf="!ngShow" aria-label="Please wait, do not refresh the page" class="ccdrms-loader" style="position:relative;margin-top:20%"></div>

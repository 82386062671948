import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthenticationService } from "../SharedModule/services/authentication.service";
import { Injectable } from "@angular/core";

@Injectable()
export class AuthInterceptorSerivce implements HttpInterceptor {

  constructor(private authenticationService: AuthenticationService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = sessionStorage.getItem("surveyToken");
    if (token) {
      const authHeader = `Bearer ${token}`;
      const headers = req.headers.set("Authorization", authHeader);
      let reqClone = req.clone({ headers });
      return next.handle(reqClone);
    }

    const isLoggedIn = this.authenticationService.isLoggedIn();
    if (!isLoggedIn) {
      return next.handle(req);
    }
    
    const { accessToken } = this.authenticationService.getLogin();
    const headers = req.headers.set("Authorization", accessToken);
    const requestClone = req.clone({ headers });
    return next.handle(requestClone);
  }
}

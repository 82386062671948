<div data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="Unmap popup"
    class="modal fade bd-example-modal-md" style="display: none;" id="unmapModal_one" aria-hidden="true"
    [ngClass]="{'zIndex':(zIndexMode===true)}">
    <div role="document" class="modal-dialog modal-md mt-120" style="max-width: 700px;">
        <div class="modal-content">
            <div class="modal-header">
                <h4 id="myLargeModalLabel" class="modal-title">Unmap</h4>
                <button type="button" data-dismiss="modal" aria-label="Close popup" class="close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <p>Are you sure want to unmap the <span *ngIf="projectName">'{{projectName}}'</span> commitment ?</p>
            </div>
            <div class="modal-footer fdl-2">
                <button type="button" data-dismiss="modal" class="btn fdl-btn btn-secondary-light"
                    [ngClass]="{'blueThemeMode':(blueThemeMode)}">Cancel</button>
                <button type="button" data-dismiss="modal" class="btn fdl-btn btn-primary-orange ml-2"
                    [ngClass]="{'buttonColor':(blueThemeMode)}" (click)="onUnmapClicked()"
                    [disabled]="responseId === 0 || projectId === '' || pageTemplateResponseDetailsId === 0">Ok</button>
            </div>
        </div>
    </div>
</div>
<div class="left-nav" role="navigation">
  <div id="self-service-sidebar-header" class="left-nav-icon cursor-default" aria-label="menu" tabindex="0">
      <span class="toggle-heading">{{content['selfServiceHeader']}}</span>
  </div>
  <ul>
    <li>
      <a routerLink="/self-service/home" href="javascript:void(0);" routerLinkActive="active">
        <span>{{content['selfServiceSidebarHomeTitle']}}</span>
      </a>
    </li>

    <li id="self-service-request-extracts-menu" *ngFor="let feature of selfServiceFeatures" class="horizontal-dropdown-menu">
      <a href="javascript:void(0);" routerLinkActive="active" *ngIf="feature.featureCount > 0 && feature.name !== 'Other Requests'">
        <span>{{feature.name}}</span>
        <i class="material-icons caret-custom">expand_more</i>
      </a>
      <div class="SiteNav fadeSiteNav"> 
        <div aria-hidden="true" class="SiteHeaderArrow"></div>
        <div class="SiteNavWrapper">
          <ul class="siteNavList_list">
            <li class="siteNavItem-isArrowHidden" *ngFor="let subfeature of feature.features;let indexOfelement=index;">              
              <a *ngIf="subfeature.selfServiceSubFeatureURL && subfeature.selfServiceSubFeatureURL.includes('https:') && subfeature.name && subfeature.viewAccess" href="{{subfeature.selfServiceSubFeatureURL}}" 
                  target="_blank" href="javascript:void(0);" class="siteNavItem_link">
                <span class="siteNavItem_labelContainer">
                  <span class="siteNavItem_labelContainer">{{subfeature.name}}</span>
                </span>
              </a>
              <a *ngIf="subfeature.selfServiceSubFeatureURL && !subfeature.selfServiceSubFeatureURL.includes('https:') && subfeature.name && subfeature.viewAccess" href="{{subfeature.selfServiceSubFeatureURL}}" href="javascript:void(0);" class="siteNavItem_link">
                <span class="siteNavItem_labelContainer">
                  <span class="siteNavItem_labelContainer">{{subfeature.name}}</span>
                </span>
              </a>
              <a routerLink="/self-service/pageTemplate/{{subfeature.pageTemplateId}}/responses" href="javascript:void(0);" class="siteNavItem_link" *ngIf="subfeature.pageTemplateTitle && 4 > indexOfelement && subfeature.viewAccess">
                <span class="siteNavItem_labelContainer">
                  <span class="siteNavItem_labelContainer">{{subfeature.pageTemplateTitle}}</span>
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </li>

    <!-- <li id="self-service-request-extracts-menu" class="horizontal-dropdown-menu">
      <a href="javascript:void(0);" routerLinkActive="active" *ngIf="showRequestExtractsLink || showformExtractionTemplateLink">
        <span>Forms & Responses</span>
        <i class="material-icons caret-custom">expand_more</i>
      </a>
      <div class="SiteNav fadeSiteNav">
        <div aria-hidden="true" class="SiteHeaderArrow"></div>
        <div class="SiteNavWrapper">
          <ul class="siteNavList_list">
            <li class="siteNavItem-isArrowHidden" *ngIf="showRequestExtractsLink">
              <a routerLink="/self-service/requestExtracts/extractionRequest" href="javascript:void(0);" class="siteNavItem_link">
                <span class="siteNavItem_labelContainer">
                  <span class="siteNavItem_labelContainer">Request Form Response Extract</span>
                </span>
              </a>
            </li>
            <li class="siteNavItem-isArrowHidden" *ngIf="showformExtractionTemplateLink">
              <a routerLink="/self-service/formExtracts/formExtractionTemplate" href="javascript:void(0);" class="siteNavItem_link">
                <span class="siteNavItem_labelContainer">
                  <span class="siteNavItem_labelContainer">Upload Form Extraction Template</span>
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </li>
    <li>
      <a routerLink="/self-service/emailTemplate" href="javascript:void(0);" routerLinkActive="active" *ngIf="showFormLink">
        <span>Email Templates</span>
      </a>
    </li>
    <li id="self-service-email-templates-menu" class="horizontal-dropdown-menu">
      <a routerLink="/self-service/emailTemplate" href="javascript:void(0);" routerLinkActive="active" *ngIf="showFormLink">
        <span>Notifications</span>
        <i class="material-icons caret-custom">expand_more</i>
      </a>
      <div class="SiteNav fadeSiteNav">
        <div aria-hidden="true" class="SiteHeaderArrow"></div>
        <div class="SiteNavWrapper">
          <ul class="siteNavList_list">
            <li class="siteNavItem-isArrowHidden" *ngIf="showRequestExtractsLink">
              <a routerLink="/self-service/emailTemplate/create" href="javascript:void(0);" class="siteNavItem_link">
                <span class="siteNavItem_labelContainer">
                  <span class="siteNavItem_labelContainer">Create Email Template</span>
                </span>
              </a>
            </li>
            <li class="siteNavItem-isArrowHidden" *ngIf="showformExtractionTemplateLink">
              <a routerLink="/self-service/emailTemplate/list" href="javascript:void(0);" class="siteNavItem_link">
                <span class="siteNavItem_labelContainer">
                  <span class="siteNavItem_labelContainer">Manage Email Template</span>
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </li>
    <li>
      <a routerLink="/self-service/fileUploads" href="javascript:void(0);" routerLinkActive="active" *ngIf="showManageAssetLink">
        <span>File Uploads</span>
      </a>
    </li>
    <li>
      <a routerLink="/self-service/report/create" href="javascript:void(0);" routerLinkActive="active" *ngIf="showReportLink">
        <span>Power BI Reports</span>
      </a>
    </li>
    <li id="self-service-workflows-menu" class="horizontal-dropdown-menu">
      <a href="javascript:void(0);" routerLinkActive="active" *ngIf="showWorkflowLink">
        <span>Workflows</span>
        <i class="material-icons caret-custom">expand_more</i>
      </a>
      <div class="SiteNav fadeSiteNav">
        <div aria-hidden="true" class="SiteHeaderArrow"></div>
        <div class="SiteNavWrapper">
          <ul class="siteNavList_list">
            <li class="siteNavItem-isArrowHidden">
              <a routerLink="/self-service/workflow" href="javascript:void(0);" class="siteNavItem_link">
                <span class="siteNavItem_labelContainer">
                  <span class="siteNavItem_labelContainer"> Workflow</span>
                </span>
              </a>
            </li>
            <li class="siteNavItem-isArrowHidden">
              <a routerLink="/self-service/workflowExecution" href="javascript:void(0);" class="siteNavItem_link">
                <span class="siteNavItem_labelContainer">
                  <span class="siteNavItem_labelContainer"> Workflow Execution</span>
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </li>
    <li class="horizontal-dropdown-menu">
      <a href="javascript:void(0);" routerLinkActive="active" *ngIf="showHelpCategoryLink || showHelpArticleLink">
        <span>Resource Center</span>
        <i class="material-icons caret-custom">expand_more</i>
      </a>
      <div class="SiteNav fadeSiteNav">
        <div aria-hidden="true" class="SiteHeaderArrow"></div>
        <div class="SiteNavWrapper">
          <ul class="siteNavList_list">
            <li class="siteNavItem-isArrowHidden" *ngIf="showHelpCategoryLink">
              <a routerLink="/help/category" href="javascript:void(0);" class="siteNavItem_link">
                <span class="siteNavItem_labelContainer">
                  <span class="siteNavItem_labelContainer">Resource Center</span>
                </span>
              </a>
            </li>
            <li class="siteNavItem-isArrowHidden" *ngIf="showHelpCategoryLink">
              <a routerLink="/self-service/helpCategory/create" href="javascript:void(0);" class="siteNavItem_link">
                <span class="siteNavItem_labelContainer">
                  <span class="siteNavItem_labelContainer">Create Resource Category</span>
                </span>
              </a>
            </li>
            <li class="siteNavItem-isArrowHidden" *ngIf="showHelpCategoryLink">
              <a routerLink="/self-service/helpCategory" href="javascript:void(0);" class="siteNavItem_link">
                <span class="siteNavItem_labelContainer">
                  <span class="siteNavItem_labelContainer">Manage Resource Category</span>
                </span>
              </a>
            </li>
            <li class="siteNavItem-isArrowHidden" *ngIf="showHelpArticleLink">
              <a routerLink="/self-service/helpTopic/create" href="javascript:void(0);" class="siteNavItem_link">
                <span class="siteNavItem_labelContainer">
                  <span class="siteNavItem_labelContainer">Create Resource Article</span>
                </span>
              </a>
            </li>
            <li class="siteNavItem-isArrowHidden" *ngIf="showHelpArticleLink">
              <a routerLink="/self-service/helpTopic" href="javascript:void(0);" class="siteNavItem_link">
                <span class="siteNavItem_labelContainer">
                  <span class="siteNavItem_labelContainer">Manage Resource Article</span>
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </li> -->
  </ul>
</div>  

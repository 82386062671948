import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FormioModule } from 'angular-formio';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { CommonModule } from '@angular/common';
import { AppComponent } from './app.component';
import { SpbuilderComponent } from './FormBuilder/spbuilder/spbuilder.component';
import { SprenderComponent } from './FormBuilder/sprender/sprender.component';
import { ViewComponent } from './formui/view/view.component';
import { FormsCompareComponent } from './formui/compare/compare.component';
import { ExcelComponent } from './FormBuilder/excel/excel.component';
import { ExceldetailsComponent } from './FormBuilder/exceldetails/exceldetails.component';
import { SpbuilderExcelComponent } from './FormBuilder/spbuilder-excel/spbuilder-excel.component';
import { TextboxComponent } from './formui/text-box/text-box.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormContainerComponent } from './formui/form-container/form-container.component';
import { HeaderComponent } from './formui/header/header.component';
import { ProgressBarComponent } from './formui/progress-bar/progress-bar.component';
import { SidebarComponent } from './formui/sidebar/sidebar.component';
import { BackendMainComponent } from './FormBuilder/backend-main/backend-main.component';
import { ProjectsModule } from './Projects/projects.module';
import { AdminModule } from './admin/admin.module';
import { DashboardModule } from "./dashboard/dashboard.module";
import { SharedModule } from './SharedModule/shared.module';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { CoreModule } from './core/core.module';
import { FormResponsesComponent } from './form-responses/form-responses.component';
import { LoaderInterceptor } from './loader/loader.interceptor';
import { environment } from 'src/environments/environment';
import { InterceptorService } from './services/interceptor.service';
import { LoggingService } from './services/logging.service';
import { FdlTypeaheadModule } from 'fdl-ngx11-bootstrap4';
import { FdlDatePickerModule } from 'fdl-ngx11-bootstrap4';
import { ErrorService } from './services/error.service';
import { ResponsesMainComponent } from './responses-main/responses-main.component'; 
import { SurveyComponent } from './formui/survey/survey.component';
import { SurveyVerificationComponent } from './formui/survey/survey-verification/survey-verification.component';
import { SurveyRespondComponent } from './formui/survey/survey-respond/survey-respond.component';
import { SurveyConfirmationComponent } from './formui/survey/survey-confirmation/survey-confirmation.component';
import { SurveyExpiredComponent } from './formui/survey/survey-expired/survey-expired.component';
import { ResponsesHistoryComponent } from './responses-history/responses-history.component';
import { ReloadComponent } from './formui/reload/reload.component';
import { SubmitRequestComponent } from './submit-request/submit-request.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { SurveyGenerateOtpComponent } from './formui/survey/survey-generate-otp/survey-generate-otp.component';
import { RouteEventsService } from './services/routerEvents.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FavoritesService } from './services/favorites.service';
import { ResponseTimelineComponent } from "./response-timeline/response-timeline.component";
import { FdlDataGridModule } from 'fdl-ngx11-bootstrap4';
import { NoticeBoardService } from './services/noticeboard.service';
import { HelpModule } from './help/help.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SelfServiceModule } from './self-service/self-service.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { ResponseCompareComponent } from './response-compare/response-compare.component';
import { ProjectWorkflowModule } from './project-workflow/project-workflow.module'
import { CustomComponentsModule } from './custom-components/custom-components.module';
import { DocumentsModule } from './Documents/documents.module';
import { QuickSearchModule } from './quick-search/quick-search.module';
import { UserNotificationModule } from './user-notification/user-notification.module';
import { HeaderCctComponent } from './formui/header-cct/header-cct.component';
import { AuthenticationService } from './SharedModule/services/authentication.service';
import { AuthInterceptorSerivce } from './services/auth.interceptor.service';
import { ThemeSwitcherComponent } from './formui/theme-switcher/theme-switcher.component';

@NgModule({
  declarations: [
    AppComponent,
    SpbuilderComponent,
    SprenderComponent,
    ViewComponent,
    FormsCompareComponent,
    ExcelComponent,
    ExceldetailsComponent,
    SpbuilderExcelComponent,
    TextboxComponent,
    FormContainerComponent,
    HeaderComponent,
    ThemeSwitcherComponent,
    ProgressBarComponent,
    SidebarComponent,
    BackendMainComponent,
    FormResponsesComponent,
    ResponsesMainComponent,
    SurveyComponent,
    SurveyGenerateOtpComponent,
    SurveyVerificationComponent,
    SurveyRespondComponent,
    SurveyConfirmationComponent,
    SurveyExpiredComponent,
    ResponsesHistoryComponent,
    ReloadComponent,
    SubmitRequestComponent,
    ResponseTimelineComponent,
    ResponseCompareComponent,
    HeaderCctComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormioModule,
    FormsModule,
    ReactiveFormsModule,
    OverlayModule,
    HttpClientModule,
    ProjectsModule,
    DocumentsModule,
    AdminModule,
    DashboardModule,
    SharedModule,
    CoreModule,
    FdlTypeaheadModule,
    FdlDatePickerModule,
    NgIdleKeepaliveModule.forRoot(),
    BrowserAnimationsModule,
    FdlDataGridModule,
    HelpModule,
    CustomComponentsModule,
    QuickSearchModule,
    UserNotificationModule,
    LoggerModule.forRoot({ level: !environment.production ? NgxLoggerLevel.LOG : NgxLoggerLevel.OFF }),
    SelfServiceModule,
    NgSelectModule,
    DragDropModule,
    ProjectWorkflowModule,
    CommonModule
    //serverLogLevel: environment.production ? NgxLoggerLevel.ERROR : NgxLoggerLevel.OFF
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorSerivce,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: ErrorService
    },
    LoggingService,
    RouteEventsService,
    FavoritesService,
    NoticeBoardService,
    AuthenticationService
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
  //ngDoBootstrap(app: ApplicationRef) {

  //  const isUnauthenticated = this.location.path().indexOf("survey") > -1;
  //  const isLoginRedirect = this.location.path().indexOf("auth") > -1;
  //  console.info("Location Path:" + this.location.path());
  //  //if (isLoginRedirect) {
  //  //  const elem = document.createElement("app-redirect");
  //  //  document.body.appendChild(elem);
  //  //  app.bootstrap(MsalRedirectComponent);
  //  //}
  //  if (isUnauthenticated) {
  //    const elem = document.createElement("app-survey");
  //    document.body.classList.add('otp');
  //    document.body.appendChild(elem);
  //    app.bootstrap(SurveyComponent);
  //  } else {
  //    //const elem = document.createElement("app-root");
  //    //document.body.appendChild(elem);
  //    //app.bootstrap(AppComponent);
  //    components.forEach((componentDef: Type<{}>) => {
  //      const factory = this.resolver.resolveComponentFactory(componentDef);
  //      if (document.querySelector(factory.selector)) {
  //        app.bootstrap(factory);
  //      }
  //    });
  //  }
  //}

}

import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UserService } from "../services/user.service";
import { ROLES } from "../core/constants";
import { environment } from 'src/environments/environment';

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html"
})
export class DashboardComponent implements OnInit {
  userDetails: any;
  private isCCT = environment.isCCT;

  constructor(private userService: UserService, private router: Router
    ) {
    this.userDetails = this.userService.getUserListData();
  }

  ngOnInit(): void {
    if ((this.userDetails.roles.some(r => r.roleTitle === 'Client Admin') && this.userDetails.roles.some(r => r.roleTitle === ROLES.Client_Executive) && this.userDetails.roles.length === 2) ||
      this.userDetails.roles.some(r => r.roleTitle === 'Client Admin') && this.userDetails.roles.length === 1) {
      this.router.navigate(["commitmentdashboard"]);
    } else {
       if (this.isCCT)
        this.router.navigate(["commitmentdashboard"]);
       else
        this.router.navigate(["dashboard/mydashboard"]);
    }
  }
}

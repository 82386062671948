import { Router } from "@angular/router";
import { environment } from 'src/environments/environment';

export class DashboardCommonLogic {
  public isCCT = environment.isCCT;

  constructor(private router: Router) {}

  public calculateClasses(status): string {
    let typeOfBadge = "";

    switch (status) {
      case "Not Started":
        typeOfBadge = "badge-blue";
        break;
      case "In Progress":
        typeOfBadge = "badge-yellow";
        break;
      case "Completed":
        typeOfBadge = "badge-green";
        break;
      case "Under Review":
        typeOfBadge = "badge-purple";
        break;
      case "On Hold":
        typeOfBadge = "badge-hold";
        break;
      case "Rejected":
        typeOfBadge = "badge-rejected";
        break;
      case "Overdue":
        typeOfBadge = "badge-red";
        break;
      default:
        typeOfBadge = "badge-blue";
        break;
      }
    return typeOfBadge;
  }

  public dashBoardBtnTxtOnStat(status): string {
    let btnTxt: string = "Get Started";
    switch (status) {
      case "Overdue":
      case "In Progress":
      case "Completed":
      case "Under Review":
      case "On Hold":
      case "Rejected":
        btnTxt = "View";
        break;
    }
    return btnTxt;
  }

  public filterOnStatusBlock(status: string, projListCopy: any): any {
    let filteredObj = { counters: {}, projects: {} };
    let projectListCopy = JSON.parse(JSON.stringify(projListCopy));
    if (status.indexOf("Total") === -1) {
      filteredObj.projects = projectListCopy.projects.filter(
        proj => proj.statusTitle === status
      );
    } else {
      filteredObj = JSON.parse(JSON.stringify(projListCopy));
    }
  }

  public activateRoute(view: string): void {
    // if (view === "project") {
    //   this.router.navigate(["dashboard/project"]);
    // } else {
    //   this.router.navigate(["dashboard/form"]);
    // }
    if (this.isCCT)
      this.router.navigate(["commitmentdashboard"]);      
    else
      this.router.navigate(["dashboard/mydashboard"]);
  }

  public isEquivalent(a: any, b: any): boolean {
    // Create arrays of property names
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length != bProps.length) {
      return false;
    }

    for (var i = 0; i < aProps.length; i++) {
      var propName = aProps[i];

      // If values of same property are not equal,
      // objects are not equivalent
      if (a[propName] !== b[propName]) {
        return false;
      }
    }

    // If we made it this far, objects
    // are considered equivalent
    return true;
  }

  public isValueOrKeyFound(baseArr, targetVal, filterValues): any {
    const valueFound = baseArr.some(el => el.value === filterValues[targetVal]);
    const keyFound = baseArr.some(el => el.key === targetVal);
    return {
      valueFound: valueFound,
      keyFound: keyFound
    };
  }
}

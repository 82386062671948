import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/SharedModule/services/authentication.service';

@Component({
  selector: 'app-survey-expired',
  templateUrl: './survey-expired.component.html'
})
export class SurveyExpiredComponent implements OnInit {

  constructor(private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    this.authenticationService.removeLogin();
  }

}

<div class="justify-content-between d-flex">
  <div class="headline-container mb-1">
    <p *ngIf="pagerObject">Total {{pagerObject.totalCount | number}} items</p>
  </div>
  <div class="d-flex">
    <div class="action-container fdl-2" style="display: flex; align-items: self-start;">
      <div class="nav-link">
        <a *ngIf="pageTemplateNameTitle == 'Commitments Intake'" href="javascript:void(0);"
           (click)="navigateToDataDict(commitmentIntakeDictUrl)">Data Dictionary</a>
      </div>
    </div>

    <div class="action-container fdl-2">
      <button (click)="onSave()" [disabled]="!enableSaveButton" class="btn fdl-btn btn-primary-orange mr-2"
              id="actionUpdateCommitmentsDwDialog" type="button">
        Save
      </button>
    </div>
    <div class="action-container fdl-2">
    </div>
    <div class="refresh-icon-link mt-1">
      <a id="refreshTask" title="Refresh List" class="">
        <i aria-hidden="true" class="fa fa-refresh" (click)="refresh()"></i>
      </a>
    </div>

    <div class="dropdown downloadDropdownMenuButton mt-0">
      <button type="button" title="Download Extract" id="downloadDropdownMenuButton" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="true" class="btn btn fdl-btn btn-primary-dark btn-addcomment">
        <span class="material-icons material-icons-wrapper"> get_app </span>
      </button>
      <div class="dropdown-menu" aria-labelledby="downloadDropdownMenuButton" x-placement="bottom-start">
        <a class="dropdown-item" (click)="exportExcel()">Export List</a>
      </div>
    </div>
    <div class="refresh-icon-link mt-1">
      <a href="javascript:void(0);" data-toggle="modal" class="clickable" (click)="showColumnSettings()">
        <span class="material-icons">settings</span>
      </a>
    </div>
  </div>
</div>
<p class="mt-3" *ngIf="pageTemplate">
  {{pageTemplate.description}}
</p>
<div class="table-responsive overflow-auto pt-2">
  <table id="parent" aria-describedby="tblDesc" class="f-12 table table-hover main-nested-table table-label"
         *ngIf="data && data.length > 0">

    <thead class="parent-thead">
      <tr role="item" class="table-topbar" *ngIf="showTrackAll">
        <th [attr.colspan]="2">
          <div class="checkbox">
            <label for="{{'trackAllCheckbox' + pagerObject.currentPage}}" class="track-all">Track All</label>
            <input type="checkbox" class="track-all" *ngIf="shouldDisableAllTrackCheckbox()" disabled="disabled" />
            <input type="checkbox" class="track-all" (change)="trackAll()" id="{{'trackAllCheckbox' + pagerObject.currentPage}}"
                   *ngIf="!shouldDisableAllTrackCheckbox()" [(ngModel)]="isAllTracked" />
            <label class="label-checkbox" for="{{'trackAllCheckbox' + pagerObject.currentPage}}"></label>
          </div>
        </th>
        <th [attr.colspan]="customFormFieldListJson.length + (displayColumns ? displayColumns.length : 0) + 2">
        </th>
      </tr>
      <tr role="item">
        <th>
        </th>

        <!-- Dynamic columns -->
        <th *ngFor="let column of customFormFieldListJson">
          <a (click)="sort(column.formfieldapikey, 'dynamicColumn')"
             *ngIf="applySorting(column.formfieldapikey, 'dynamicColumn')" href="javascript:void(0);">
            {{column.formfieldlabel}}
            <i class="material-icons msort14" aria-hidden="true"
               *ngIf="isAsc && filterColumn === column.formfieldapikey">arrow_upward</i>
            <i class="material-icons msort14" aria-hidden="true"
               *ngIf="!isAsc && filterColumn === column.formfieldapikey">arrow_downward</i>
          </a>
          <span *ngIf="!applySorting(column.formfieldapikey, 'dynamicColumn')">
            {{column.formfieldlabel}}
          </span>
        </th>
        <th> 
          <a (click)="sort('datasource', 'dynamicColumn')" *ngIf="applySorting('datasource', 'dynamicColumn')"
             href="javascript:void(0);">
            Data Source
            <i class="material-icons msort14" aria-hidden="true"
               *ngIf="isAsc && filterColumn === 'datasource'">arrow_upward</i>
            <i class="material-icons msort14" aria-hidden="true"
               *ngIf="!isAsc && filterColumn === 'datasource'">arrow_downward</i>
          </a>
          <span *ngIf="!applySorting('datasource', 'dynamicColumn')">
            Data Source
          </span>
        </th>

        <!-- columns setting -->
        <th role="columnheader" scope="col" *ngFor="let column of displayColumns; let i = index;">
          <span>
            <a (click)="sort(column, 'columnSettings')" *ngIf="applySorting(column, 'columnSettings')"
               href="javascript:void(0);">
              {{column}}
              <i class="material-icons msort14" aria-hidden="true"
                 *ngIf="isAsc && displayColumnsSortIcon(column)">arrow_upward</i>
              <i class="material-icons msort14" aria-hidden="true"
                 *ngIf="!isAsc && displayColumnsSortIcon(column)">arrow_downward</i>
            </a>
            <span *ngIf="!applySorting(column, 'columnSettings')">
              {{column}}
            </span>
          </span>
        </th>
        <th style="background: white; position: sticky; right: 0;" role="columnheader" scope="col">Actions</th>
      </tr>
    </thead>
    <tbody class="parent-tbody" *ngFor="let item of data; let i = index">
      <tr role="row" [ngClass]="{'has-changed' : item.hasTrackChanged }" class="tr-parent grey collapsed">
        <td role="cell">
          <div>
            <a id="{{'arrow'+ i}}" href="{{'#tableParentAccordion'+ i}}"
               class="material-icons td-parent-accordion collapsed" data-toggle="collapse"
               aria-expanded="false"></a>
          </div>

        </td>


        <!-- Dynamic columns -->
        <td *ngFor="let col of customFormFieldListJson; let colIndex = index">
          <div [ngStyle]="{'margin-top': col.formfieldapikey == 'isRisk' ? '7px' : null }" class="checkbox"
               [attr.title]="col.formfieldapikey == 'isTracked' && item.dataSource == 'ecrm' ? 'eCRM opportunities are for reference only and disabled from tracking on the dashboard.  Should there be reason to track an eCRM opportunity with a client, please contact CommitmentTrackerSupport@fiserv.com for assistance.' : null"
               *ngIf="col.formfieldtype == 'checkbox'">
            <i class="material-icons info"
               *ngIf="col.formfieldapikey === 'isTracked' && item.customeFormFieldListJsonAnswer?.mappedCommitmentDetails"
               title="{{showTitleDataForMappedCommitmentDetails(item)}}">info</i>

            <!-- DO NOT REMOVE . This is the old version, commented to reference in the future if we have any issue -->
            <!-- <input id="{{'dataCheckbox'+ i + colIndex}}"
      [disabled]="col.formfieldapikey == 'isTracked' && (item.customeFormFieldListJsonAnswer?.mappedCommitmentDetails || item?.customeFormFieldListJsonAnswer?.statusIntake == 'Canceled' || item?.customeFormFieldListJsonAnswer?.statusIntake == 'Fulfillment - Canceled')"
      *ngIf="!(item?.customeFormFieldListJsonAnswer?.drmsReservedIsReadOnlyResponse
          || item?.customeFormFieldListJsonAnswer?.drmsReservedIsReadOnlyResponse == 'true'
          || item['accessLevel'] == 'Read') && col.formfieldtype == 'checkbox' && col.isEditable && !item.isLinked && !col.dependentChildField"
      value="true" type="checkbox"
      [(ngModel)]="item.customeFormFieldListJsonAnswer[col.formfieldapikey]"
      (change)="onFieldChanged(item, col.formfieldapikey)">

  <input id="{{'dataCheckbox'+ i + colIndex}}" *ngIf="(item?.customeFormFieldListJsonAnswer?.drmsReservedIsReadOnlyResponse
          || item?.customeFormFieldListJsonAnswer?.drmsReservedIsReadOnlyResponse == 'true'
          || item['accessLevel'] == 'Read' || !col.isEditable || item.isLinked
          || item?.customeFormFieldListJsonAnswer?.statusIntake == 'Canceled' || item?.customeFormFieldListJsonAnswer?.statusIntake == 'Fulfillment - Canceled') && col.formfieldtype == 'checkbox' && !col.dependentChildField
          || !hasRolesCanTrackOrMarkAsRiskCommitment()" [disabled]="true" type="checkbox"
      [(ngModel)]="item.customeFormFieldListJsonAnswer[col.formfieldapikey]"
      (change)="onFieldChanged(item, col.formfieldapikey)">

  <input id="{{'dataCheckbox'+ i + colIndex}}"
      [disabled]="(col.formfieldapikey == 'isTracked' && item.customeFormFieldListJsonAnswer?.mappedCommitmentDetails) ||
      col.formfieldapikey == 'isRisk' && (item.customeFormFieldListJsonAnswer?.mappedCommitmentDetails || item?.customeFormFieldListJsonAnswer?.statusIntake == 'Canceled' || item?.customeFormFieldListJsonAnswer?.statusIntake == 'Fulfillment - Canceled')"
      *ngIf="!(item?.customeFormFieldListJsonAnswer?.drmsReservedIsReadOnlyResponse
          || item?.customeFormFieldListJsonAnswer?.drmsReservedIsReadOnlyResponse == 'true'
          || item['accessLevel'] == 'Read') && col.formfieldtype == 'checkbox' && col.isEditable && !item.isLinked && col.dependentChildField != ''"
      value="true" type="checkbox"
      [(ngModel)]="item.customeFormFieldListJsonAnswer[col.formfieldapikey]"
      (change)="onFieldChanged(item, col.formfieldapikey, col.dependentChildField)">

  <input id="{{'dataCheckbox'+ i + colIndex}}" *ngIf="(item?.customeFormFieldListJsonAnswer?.drmsReservedIsReadOnlyResponse
          || item?.customeFormFieldListJsonAnswer?.drmsReservedIsReadOnlyResponse == 'true'
          || item['accessLevel'] == 'Read' || !col.isEditable || item.isLinked
          || item?.customeFormFieldListJsonAnswer?.statusIntake == 'Canceled' || item?.customeFormFieldListJsonAnswer?.statusIntake == 'Fulfillment - Canceled') && col.formfieldtype == 'checkbox' && col.dependentChildField != ''
          || !hasRolesCanTrackOrMarkAsRiskCommitment()" [disabled]="true" type="checkbox"
      [(ngModel)]="item.customeFormFieldListJsonAnswer[col.formfieldapikey]"
      (change)="onFieldChanged(item, col.formfieldapikey, col.dependentChildField)"> -->
            <!-- END old version -->
            <!-- START New version (PBI 324651) -->
            <!-- For IsTrack. DO NOT HAVE dependentChildField -->
            <input id="{{'dataCheckbox'+ i + colIndex}}"
                   *ngIf="col.formfieldapikey == 'isTracked' && !col.dependentChildField && item.isCustomClientCommitment && !disabledCustomCommitment"
                   [disabled]="shouldDisableIsTrackCheckbox(item, col)" data-toggle="modal" (click)="onAddUsersClicked(item)"
                   data-target="#addCommitmentUsers"
                   value="true" type="checkbox"
                   [(ngModel)]="item.customeFormFieldListJsonAnswer[col.formfieldapikey]"
                   (change)="onFieldChanged(item, col.formfieldapikey)">

            <input id="{{'dataCheckbox'+ i + colIndex}}"
                   *ngIf="col.formfieldapikey == 'isTracked' && !col.dependentChildField && item.isCustomClientCommitment && disabledCustomCommitment"
                   [disabled]=true value="true" type="checkbox"
                   [(ngModel)]="item.customeFormFieldListJsonAnswer[col.formfieldapikey]"
                   (change)="onFieldChanged(item, col.formfieldapikey)">

            <input id="{{'dataCheckbox'+ i + colIndex}}"
                   *ngIf="col.formfieldapikey == 'isTracked' && !col.dependentChildField && !item.isCustomClientCommitment && !disabledNonCustomCommitment"
                   [disabled]="shouldDisableIsTrackCheckbox(item, col)"
                   value="true" type="checkbox"
                   [(ngModel)]="item.customeFormFieldListJsonAnswer[col.formfieldapikey]"
                   (change)="onFieldChanged(item, col.formfieldapikey)">

            <input id="{{'dataCheckbox'+ i + colIndex}}"
                   *ngIf="col.formfieldapikey == 'isTracked' && !col.dependentChildField && !item.isCustomClientCommitment && disabledNonCustomCommitment"
                   [disabled]= true
                   value="true" type="checkbox"
                   [(ngModel)]="item.customeFormFieldListJsonAnswer[col.formfieldapikey]"
                   (change)="onFieldChanged(item, col.formfieldapikey)">

            <!-- For IsRisk -->
            <input id="{{'dataCheckbox'+ i + colIndex}}"
                   *ngIf="col.formfieldapikey == 'isRisk' && col.dependentChildField"
                   [disabled]="shouldDisableIsRiskCheckbox(item, col)"
                   value="true" type="checkbox"
                   [(ngModel)]="item.customeFormFieldListJsonAnswer[col.formfieldapikey]"
                   (change)="onFieldChanged(item, col.formfieldapikey, col.dependentChildField)">

            <!-- END New version (PBI 324651) -->

            <label for="{{'dataCheckbox'+ i + colIndex}}"></label>
          </div>

          <input [ngStyle]="{'color': col.isHighlight && item?.customeFormFieldListJsonAnswer?.drmsReserveditemHighlightColor != '' ? item?.customeFormFieldListJsonAnswer?.drmsReserveditemHighlightColor : '',
                                'border': (item.customeFormFieldListJsonAnswer[col.dependentParentField] || item.customeFormFieldListJsonAnswer[col.dependentParentField] == 'true')
                                && (item.customeFormFieldListJsonAnswer[col.formfieldapikey] == '' || item.customeFormFieldListJsonAnswer[col.formfieldapikey] == undefined || item.customeFormFieldListJsonAnswer[col.formfieldapikey] == null)
                                && col.dependentParentField != undefined && col.dependentParentField != '' && col.isMandatory ? 'red 1px solid' : ''}"
                 class="data-input form-control"
                 title="{{item.customeFormFieldListJsonAnswer[col.formfieldapikey]}}"
                 *ngIf="!(item?.customeFormFieldListJsonAnswer?.drmsReservedIsReadOnlyResponse || item?.customeFormFieldListJsonAnswer?.drmsReservedIsReadOnlyResponse == 'true' || item['accessLevel'] == 'Read') && col.formfieldtype == 'textbox' && col.isEditable && !item.isLinked"
                 type="text" [(ngModel)]="item.customeFormFieldListJsonAnswer[col.formfieldapikey]"
                 (change)="formFieldChanged(item, col.formfieldapikey)">

          <textarea [ngStyle]="{'color': col.isHighlight && item?.customeFormFieldListJsonAnswer?.drmsReserveditemHighlightColor != '' ? item?.customeFormFieldListJsonAnswer?.drmsReserveditemHighlightColor : '',
                                  'border': (item.customeFormFieldListJsonAnswer[col.dependentParentField] || item.customeFormFieldListJsonAnswer[col.dependentParentField] == 'true')
                                  && (item.customeFormFieldListJsonAnswer[col.formfieldapikey] == '' || item.customeFormFieldListJsonAnswer[col.formfieldapikey] == undefined || item.customeFormFieldListJsonAnswer[col.formfieldapikey] == null)
                                  && col.dependentParentField != undefined && col.dependentParentField != '' && col.isMandatory ? 'red 1px solid' : ''
                                }" class="data-input form-control"
                    title="{{item.customeFormFieldListJsonAnswer[col.formfieldapikey]}}"
                    *ngIf="!(item?.customeFormFieldListJsonAnswer?.drmsReservedIsReadOnlyResponse || item?.customeFormFieldListJsonAnswer?.drmsReservedIsReadOnlyResponse == 'true' || item['accessLevel'] == 'Read') && col.formfieldtype == 'textarea' && col.isEditable && !item.isLinked"
                    [(ngModel)]="item.customeFormFieldListJsonAnswer[col.formfieldapikey]"
                    (change)="formFieldChanged(item, col.formfieldapikey)">
                    </textarea>

          <div class="fdl-select" *ngIf="col.formfieldtype == 'select' && col.isEditable && !item.isLinked"
               [ngClass]="{'has-error' : (item.customeFormFieldListJsonAnswer[col.dependentParentField] || item.customeFormFieldListJsonAnswer[col.dependentParentField] == 'true')
                          && (item.customeFormFieldListJsonAnswer[col.formfieldapikey] == '' || item.customeFormFieldListJsonAnswer[col.formfieldapikey] == undefined || item.customeFormFieldListJsonAnswer[col.formfieldapikey] == null)
                          && col.dependentParentField != undefined && col.dependentParentField != '' && col.isMandatory
                      }">
            <select *ngIf="col.dependentParentField != undefined && col.dependentParentField != ''"
                    [disabled]="shouldDisableDuetoReadOnlyRoles(item, col) || col.dependentParentField != undefined && col.dependentParentField != '' && !(item.customeFormFieldListJsonAnswer[col.dependentParentField] || item.customeFormFieldListJsonAnswer[col.dependentParentField] == 'true')"
                    [ngStyle]="{'color': col.isHighlight && item?.customeFormFieldListJsonAnswer?.drmsReserveditemHighlightColor != '' ? item?.customeFormFieldListJsonAnswer?.drmsReserveditemHighlightColor : ''}"
                    class="data-input form-control" type="text"
                    [(ngModel)]='item.customeFormFieldListJsonAnswer[col.formfieldapikey]'
                    title="{{item.customeFormFieldListJsonAnswer[col.formfieldapikey]}}"
                    (change)="formFieldChanged(item, col.formfieldapikey)">

              <option *ngFor="let selectedItem of col.value" [value]="selectedItem.value">
                {{ transformDropdownValue(selectedItem.value) }}
              </option>
            </select>
            <select *ngIf="!(col.dependentParentField != undefined && col.dependentParentField != '')"
                    [disabled]="shouldDisableDuetoReadOnlyRoles(item, col)"
                    [ngStyle]="{'color': col.isHighlight && item?.customeFormFieldListJsonAnswer?.drmsReserveditemHighlightColor != '' ? item?.customeFormFieldListJsonAnswer?.drmsReserveditemHighlightColor : ''}"
                    class="data-input form-control" type="text"
                    [(ngModel)]='item.customeFormFieldListJsonAnswer[col.formfieldapikey]'
                    title="{{item.customeFormFieldListJsonAnswer[col.formfieldapikey]}}"
                    (change)="formFieldChanged(item, col.formfieldapikey)">
              <option value=""> </option>
              <option *ngFor="let selectedItem of col.value" [value]="selectedItem.value">
                {{ transformDropdownValue(selectedItem.value) }}
              </option>
            </select>

            <!-- Eg: For Risk reason description -->
            <input [ngStyle]="{'border': item.customeFormFieldListJsonAnswer[col.formfieldapikey] == 'other' && (item.customeFormFieldListJsonAnswer[col.otherOptionFieldApiKey] == undefined || item.customeFormFieldListJsonAnswer[col.otherOptionFieldApiKey] == '')? 'red 1px solid' : ''}"
                   class="data-input form-control"
                   *ngIf="item.customeFormFieldListJsonAnswer[col.formfieldapikey] == 'other' && col.showOtherOptionField"
                   [readonly]="shouldDisableDuetoReadOnlyRoles(item, col)" type="text"
                   [(ngModel)]="item.customeFormFieldListJsonAnswer[col.otherOptionFieldApiKey]"
                   title="{{item.customeFormFieldListJsonAnswer[col.otherOptionFieldApiKey]}}"
                   (change)="formFieldChanged(item, col.otherOptionFieldApiKey)">
          </div>

          <label *ngIf="col.formfieldtype === 'date' && col.formfieldapikey !== 'plannedCommitteDeliveryDate'"
                 title="{{item.customeFormFieldListJsonAnswer[col.formfieldapikey] | date:'MM/dd/yyyy'}}">
            {{item.customeFormFieldListJsonAnswer[col.formfieldapikey] | date:'MM/dd/yyyy'}}
          </label>
          <label *ngIf="col.formfieldtype === 'date' && col.formfieldapikey === 'plannedCommitteDeliveryDate'"
                 title="{{plannedCommitteDeliveryDateGenerator(item.customeFormFieldListJsonAnswer)}}">
            {{ plannedCommitteDeliveryDateGenerator(item.customeFormFieldListJsonAnswer)}}
          </label>

          <!-- Explain for condition isDisableFormFieldApiKey() in ngIf
               Because we show the risk reason dropdown above in disable mode for Read only users so we don't need to show the label below for risk reason -->
          <label [ngStyle]="{'color': col.isHighlight && item?.customeFormFieldListJsonAnswer?.drmsReservedRowHighlightColor != '' ? item?.customeFormFieldListJsonAnswer?.drmsReservedRowHighlightColor : ''}"
                 title="{{item.customeFormFieldListJsonAnswer[col.formfieldapikey]}}"
                 *ngIf="col.formfieldapikey === 'statusIntake' && !isDisableFormFieldApiKey(col.formfieldapikey) && (item?.customeFormFieldListJsonAnswer?.drmsReservedIsReadOnlyResponse || item?.customeFormFieldListJsonAnswer?.drmsReservedIsReadOnlyResponse == 'true' || item['accessLevel'] == 'Read' || !col.isEditable || item.isLinked) && col.formfieldtype != 'checkbox' && col.formfieldtype !== 'date'">
            {{convertTextForStatus(item.customeFormFieldListJsonAnswer[col.formfieldapikey])}}
          </label>
          <label [ngStyle]="{'color': col.isHighlight && item?.customeFormFieldListJsonAnswer?.drmsReservedRowHighlightColor != '' ? item?.customeFormFieldListJsonAnswer?.drmsReservedRowHighlightColor : ''}"
                 title="{{item.customeFormFieldListJsonAnswer[col.formfieldapikey]}}"
                 *ngIf="col.formfieldapikey !== 'statusIntake' && !isDisableFormFieldApiKey(col.formfieldapikey) && (item?.customeFormFieldListJsonAnswer?.drmsReservedIsReadOnlyResponse || item?.customeFormFieldListJsonAnswer?.drmsReservedIsReadOnlyResponse == 'true' || item['accessLevel'] == 'Read' || !col.isEditable || item.isLinked) && col.formfieldtype != 'checkbox' && col.formfieldtype !== 'date'">
            {{RemoveUnnecessaryCharacters(col.formfieldapikey, item.customeFormFieldListJsonAnswer[col.formfieldapikey])}}
          </label>
          <label [ngStyle]="{'color': col.isHighlight && item?.customeFormFieldListJsonAnswer?.drmsReservedRowHighlightColor != '' ? item?.customeFormFieldListJsonAnswer?.drmsReservedRowHighlightColor : ''}"
                 *ngIf="!isDisableFormFieldApiKey(col.formfieldapikey) && (item?.customeFormFieldListJsonAnswer?.drmsReservedIsReadOnlyResponse || item?.customeFormFieldListJsonAnswer?.drmsReservedIsReadOnlyResponse == 'true' || item['accessLevel'] == 'Read' || !col.isEditable || item.isLinked) && col.formfieldtype != 'checkbox' && item.customeFormFieldListJsonAnswer[col.formfieldapikey] == 'other' && col.showOtherOptionField"
                 title="{{item.customeFormFieldListJsonAnswer[col.otherOptionFieldApiKey]}}">
            {{item.customeFormFieldListJsonAnswer[col.otherOptionFieldApiKey]}}
          </label>
        </td>

        <td>
          <label> {{transformDataSourceString(item.dataSource)}} </label>
        </td>

        <!-- columns setting -->
        <td role="cell" *ngFor="let key of keys;">

          <label for="myCheckboxZero"
                 *ngIf="!isStatus(key) && !isFlag(key) && isDate(key) && !isDateOnly(key) && !isText(key) && item[key]!='01/01/0001' && item[key]!='0001-01-01T00:00:00Z' && item[key]!='12/31/0001'"
                 data-toggle="tooltip" data-placement="top"
                 title="{{item[key] | date: content['dateHourFormat']}}">
            {{
item[key] | date:
                        content['dateHourFormat']
            }}
          </label>
          <label for="myCheckboxZero"
                 *ngIf="!isStatus(key) && !isFlag(key) && !isDate(key) && !isText(key) && item[key]!='01/01/0001' && item[key]!='12/31/0001' && item[key]!='0001-01-01T00:00:00Z'  && isDateOnly(key)"
                 data-toggle="tooltip" data-placement="top"
                 title="{{item[key] | date: content['dateFormat']}}">
            {{
item[key] | date:
                        content['dateFormat']
            }}
          </label>
          <label class="badge-table" for="myCheckboxZero" *ngIf="isStatus(key)" data-toggle="tooltip"
                 data-placement="top" title="{{item[key]}}">
            <span [ngClass]="getStatusCSSClass(item[key])"></span> {{item[key]}}
          </label>

          <label *ngIf="!isText(key) && !isStatus(key) && !isFlag(key) && !isDate(key) && !isDateOnly(key) &&  item[key]!='01/01/0001' && item[key]!='12/31/0001' && item[key]!='0001-01-01T00:00:00Z'">
            {{displayColumnData(item, key)}}
          </label>
        </td>

        <!-- Actions three dot -->
        <td (click)="addZIndex(i)" [attr.id]="'actions-' + i" class="actions" role="cell">
          <div (click)="addZIndex(i)" class="kebab-menu dropleft">
            <a href="javascript:void(0);" (click)="addZIndex(i)" data-toggle="dropdown" id="headerSwitcher"
               aria-expanded="false">
              <span ariaReadOnly="true" class="material-icons"> more_vert </span>
            </a>
            <div x-placement="left-start"
                 class="dropdown-menu dropdown-menu-dark top-15 header-dropdown-switcher">
              <div class="dropdown-list-option">
                <a *ngIf="canView(item)" class="dropdown-item" (click)="onViewClicked(item.id)">View</a>
                <a *ngIf="item.isCustomClientCommitment && hasRoleCanEditCommitment() && showEditUserAssignment(item)" class="dropdown-item" data-toggle="modal"
                   data-target="#editCommitmentUsers" (click)="onEditUsersClicked(item)">Edit Custom User Assignment</a>
                <a *ngIf="canViewEdit(item)" class="dropdown-item" (click)="onViewClicked(item.id)">
                  View
                  &amp; Edit
                </a>
                <a *ngIf="canEdit()" class="dropdown-item" (click)="onEditClicked(item.id)">Edit</a>
                <a *ngIf="canDelegate(item)" class="dropdown-item" data-toggle="modal"
                   data-target="#delegateCommitment" (click)="openDelegateModal(item.id)">Delegate</a>
                <a *ngIf="canMapProject(item)" class="dropdown-item"
                   (click)="openMapFulfillment(item, i, true, '')">
                  Map Project
                </a>
                <a *ngIf="canDeleteCommitment(item)" (click)="onDeleteModalClicked(item.id)"
                   data-toggle="modal" class="dropdown-item" data-target="#deleteModal_one">Delete</a>
              </div>
            </div>
          </div>
        </td>

      </tr>
      <tr id="{{'tableParentAccordion' + i}}" class="tr-child grey collapse">
        <td colspan="30" class="td-child">

          <!-- Mapped Opportunity -->
          <div class="table-child-container accordion collapse-view white">
            <div class="nested-table-header">
              <a class="table-child-heading" data-toggle="collapse"
                 href="{{'#tableChildOpportunityAccordion'+ i}}" aria-expanded="true">
                <span>Mapped Opportunity</span>
              </a>
            </div>

            <div class="filter-wrapper collapse show" id="{{'tableChildOpportunityAccordion'+ i}}">
              <div *ngIf="item.customeFormFieldListJsonAnswer.opportunityId">
                <div class="filter-tag filter-action action"
                     (click)="openMapFulfillment(item, i, false, 'ecrm')">
                  <div class="tag actionDwDialog">
                    <div class="data-wrapper">
                      <span class="label">Opportunity ID</span>
                      <span class="value">{{item.customeFormFieldListJsonAnswer.opportunityId}}</span>
                    </div>
                    <div class="data-wrapper">
                      <span class="label">Opportunity System</span>
                      <span *ngIf="item.customeFormFieldListJsonAnswer.datasource === 'ecrm'"
                            class="value">{{item.customeFormFieldListJsonAnswer.opportunitySystem}}</span>
                      <span *ngIf="item.customeFormFieldListJsonAnswer.datasource !== 'ecrm'"
                            class="value">ecrm</span>
                    </div>
                    <a *ngIf="canUnmapMappedOpportunity(item)" data-toggle="modal"
                       data-target="#unmapModal_one" (click)="onOpenUnmapModal()"
                       aria-label="Close" class="tag-close">
                      <i tabindex="0" aria-hidden="true"
                         class="material-icons">cancel</i>
                    </a>
                  </div>
                </div>
              </div>
              <div *ngIf="!item.customeFormFieldListJsonAnswer.opportunityId"
                   class="two-col-nested-child">
                <div class="filter-wrapper collapse show">
                  <div class="filter-tag not-avil-message ">
                    <div class="tag">
                      <div class="data-wrapper">
                        <span class="label">
                          {{ item.opportunitySuggestionsIsCalled &&
                             (!item.opportunitySuggestions ||
                               item.opportunitySuggestions.length === 0) ? 'Not Available.' : ''
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="canGetOpportunitySuggestion(item)" class="col-md-12 mt-3 get_suggestion">
                <a (click)="getOpportunitySuggestions(item)">
                  <span>
                    <i class="material-icons f-30">add_circle_outline</i> Get Opportunity
                    Suggestions
                  </span>
                </a>
              </div>
            </div>
          </div>

          <!-- Mapped Fulfillment -->
          <div class="table-child-container accordion collapse-view grey">
            <div class="nested-table-header">
              <a class="table-child-heading" data-toggle="collapse"
                 href="{{'#tableChildMappedFulfillmentAccordion'+ i}}" aria-expanded="true">
                <span>Mapped Project</span>
              </a>
            </div>
            <div class="filter-wrapper collapse show" id="{{'tableChildMappedFulfillmentAccordion'+ i}}">
              <div *ngIf="item.customeFormFieldListJsonAnswer.hdnFulfillmentDetails" class="row">
                <div class="col-md-12">
                  <div class="filter-tag filter-action action"
                       (click)="openMapFulfillment(item, i, false, '')">
                    <div class="tag actionDwDialog">
                      <div class="data-wrapper">
                        <span class="label">Project Number</span>
                        <span class="value">
                          {{getProjectIdFromHdnFulfillmentDetails(item)}}
                        </span>
                      </div>
                      <a *ngIf="canUnmapMappedProject(item)" data-toggle="modal"
                         data-target="#unmapModal_one" (click)="onOpenUnmapModal()"
                         aria-label="Close" class="tag-close">
                        <i tabindex="0" aria-hidden="true"
                           class="material-icons">cancel</i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="!item.customeFormFieldListJsonAnswer.hdnFulfillmentDetails"
                   class="two-col-nested-child">
                <div class="filter-wrapper collapse show">
                  <div class="filter-tag not-avil-message ">
                    <div class="tag">
                      <div class="data-wrapper">
                        <span class="label">
                          {{item.fulfillmentSuggestionsIsCalled &&
                            (!item.fulfillmentSuggestions ||
                              item.fulfillmentSuggestions.length === 0) ? 'Not Available.' : ''
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 mt-3 get_suggestion" *ngIf="canGetProjectSuggestion(item)">
                <a (click)="getFulfillmentSuggestions(item)">
                  <span>
                    <i class="material-icons f-30">add_circle_outline</i> Get
                    Project Suggestions
                  </span>
                </a>
              </div>
            </div>
          </div>

          <!-- Matching Suggestions -->
          <div *ngIf="currentIndex === item.id && (item.fulfillmentSuggestionsIsCalled || item.opportunitySuggestionsIsCalled)"
               class="table-child-container accordion collapse-view white">
            <div>
              <div class="nested-table-header">
                <a class="table-child-heading" data-toggle="collapse"
                   href="{{'#tableChildSuggestionsAccordion'+ i}}" aria-expanded="true">
                  <span *ngIf="getFulfillmentSuggestionsClicked">Matching Project Suggestions</span>
                  <span *ngIf="getOpportunitySuggestionsClicked">
                    Matching Opportunity
                    Suggestions
                  </span>
                </a>
              </div>
            </div>

            <div id="matchingSuggestionsDiv">
              <table class="f-12 table child-nested-table table-label collapse show"
                     id="{{'tableChildSuggestionsAccordion'+ i}}">

                <!-- For Fulfillment Suggestions -->
                <tbody *ngIf="item.fulfillmentSuggestions && item.fulfillmentSuggestions.length > 0 && getFulfillmentSuggestionsClicked"
                       class="child-tbody">
                  <tr>
                    <td colspan="4">
                      <div class="link-action">
                        <a (click)="removeSuggestions(item)">
                          <i class="material-icons remove_suggestion_icon">delete</i>
                          <span>Remove Suggestions</span>
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr style="background: #FAFAFA; position: sticky; top: 0; z-index: 3;">
                    <th><label style="margin-left: 38px;">Project Title</label></th>
                    <th><label>Project Number</label></th>
                    <th><label>Product</label></th>
                    <th><label></label></th>
                  </tr>
                  <tr style="z-index: 1;"
                      *ngFor="let suggestion of item.fulfillmentSuggestions; let i = index">
                    <td>
                      <div class="d-flex justify-content-start">
                        <a class="link" title="View details"
                           (click)="openMapFulfillment(item, i, true, 'clarity', suggestion)">
                          <div class="material-icons grey mt-1">chevron_right</div>
                        </a>
                        <div class="map_div_icon"></div>
                        <label>{{suggestion.title}}</label>
                      </div>
                    </td>
                    <td><label>{{suggestion.projectNumber}}</label></td>
                    <td><label>{{suggestion.products}}</label></td>
                    <td><label></label></td>
                  </tr>
                </tbody>

                <!-- For Opportunity Suggestions -->
                <tbody *ngIf="item.opportunitySuggestions && item.opportunitySuggestions.length > 0 && getOpportunitySuggestionsClicked"
                       class="child-tbody">
                  <tr>
                    <td style="border: none;">
                      <div class="link-action">
                        <a (click)="removeSuggestions(item)">
                          <i class="material-icons remove_suggestion_icon">delete</i>
                          <span>Remove Suggestions</span>
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th><label style="margin-left: 38px;">Opportunity</label></th>
                    <th><label>ID</label></th>
                    <th><label>Product</label></th>
                    <th><label></label></th>
                  </tr>
                  <tr *ngFor="let suggestion of item.opportunitySuggestions; let i = index">
                    <td>
                      <div class="d-flex justify-content-start">
                        <a class="link" title="View details"
                           (click)="openMapFulfillment(item, i, true, 'ecrm', suggestion)">
                          <div class="material-icons grey mt-1">chevron_right</div>
                        </a>
                        <div class="map_div_icon"></div>
                        <label>{{suggestion.title}}</label>
                      </div>
                    </td>

                    <td><label>{{suggestion.opportunityId}}</label></td>
                    <td><label>{{suggestion.products}}</label></td>
                    <td><label></label></td>
                  </tr>
                </tbody>

                <!-- No results -->
                <tbody *ngIf="((!item.opportunitySuggestions || item.opportunitySuggestions.length === 0) && getOpportunitySuggestionsClicked)
                                    || ((!item.fulfillmentSuggestions || item.fulfillmentSuggestions.length === 0) && getFulfillmentSuggestionsClicked)"
                       class="child-tbody">
                  <tr>
                    <td colspan="4">
                      <div class="filter-wrapper collapse show">
                        <div class="filter-tag not-avil-message">
                          <div class="tag border-color-red">
                            <div class="data-wrapper">
                              <span class="label">
                                Not
                                Available.
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </td>
      </tr>
    </tbody>
    <div style="width: max-content;" *ngIf="!data || data.length === 0" class="headline-container mb-1 text-center">
      <p>No data available</p>
    </div>
  </table>
</div>
<div *ngIf="pagerObject">
  <app-pagination *ngIf="pagerObject && pagerObject.totalPages > 1 " [pagerObject]="pagerObject"
                  (pagingChanged)="changePage($event)"></app-pagination>
</div>

<app-commitments-update-modal *ngIf="showUpdateCommitmentsIntakeDrawer" [updatedCommitments]="commimentsTobeUpdated"
                              (closeModal)="closeUpdateCommitmentModal()" (updateCommitment)="submitRequest()"></app-commitments-update-modal>

<app-commitments-delegate *ngIf="showDelegateModal" (delegateCompleted)="successDelegateClick($event)"
                          [pageTemplateResponseId]="responseId" [pageTemplate]="pageTemplate"></app-commitments-delegate>

<app-commitments-assignusers *ngIf="showClientUserModal" (updateCommitment)="saveUsersClick()" (closeModal)="closeAddCommitmentUsersModal()"
                             [updatedCommitments]="commimentsTobeUpdated"  [commitmentDetails]="selectedForUserAssignement" [pageTemplateResponseId]="responseId" [pageTemplate]="pageTemplate"></app-commitments-assignusers>

<app-commitments-edit-assignusers *ngIf="showClientUserEditModal" (closeModal)="editCommitmentUsersModal()" (submitModal)="editCommitmentUsersModal()"
                             [commitmentDetails]="selectedForeditUserAssignement"></app-commitments-edit-assignusers>


<!-- <app-commitment-copy *ngIf="showCopyModal" [selectedForCopy]="selectedForCopy"
    (submit)="onComponentSubmited()"></app-commitment-copy> -->

<app-map-fulfilment [pageTemplate]="pageTemplate" *ngIf="showMapFulfilmentModal" [projectDataSource]="projectDataSource"
                    [isCalledForMap]="isCalledForMap" [responseId]="responseId" [projectId]="projectId" [title]="fulfillmentTitle"
                    [canMapOrUnmap]="canMapOrUnmapOnMapModal" [commitmentMapType]="commitmentMapType" [isReadOnlyMode]="isReadOnlyMode"
                    [currentCommimentDataSource]="currentCommimentDataSource"
                    [hasDefaultEcrmOpportunityId]="hasDefaultEcrmOpportunityId" (submit)="onMapFulfilmentComponentSubmited($event)"
                    (cancel)="closeMapFulfilmentDrawer()"></app-map-fulfilment>

<app-commitments-intake-delete-modal [id]="idForDelete"
                                     (delete)="onComponentSubmited($event)"></app-commitments-intake-delete-modal>

<app-unmap-fulfillment-modal [responseId]="responseId" [projectName]="titleForUnmap" [zIndexMode]="zIndexMode"
                             [pageTemplateResponseDetailsId]="pageTemplateResponseDetailsId" [commitmentMapType]="commitmentMapType"
                             [projectId]="projectId" (unmap)="unmap($event)"></app-unmap-fulfillment-modal>

<!-- start of column settings changes-->
<div *ngIf="showColumnSettingsDrawer">
  <div class="modal full-width fade position-right no-backdrop show" id="columnSettings" tabindex="-1" role="dialog"
       aria-labelledby="myModalLabel" style="display: block;" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header white-modal-header">
          <h4 class="modal-title right-arrow-image"><span>Column Settings</span></h4>
          <button type="button" class="close" data-dismiss="modal" tabindex="0" autofocus=""
                  aria-label="Close popup" (click)="cancelPreference()">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body pt-0">
          <div class="row tag-line-wrapper">
            <div class="col-md-12">
              <div class="tag-line">
                <p>
                  Add or remove columns.
                </p>
                <p class="mb-0">
                  Please note that you can add up to {{maxColumnCount}} columns at a time.
                </p>
              </div>
            </div>
          </div>
          <div class="alert alert-dismissible alert-danger" *ngIf="columnSettingErrorMsg">
            <button type="button" class="close" data-dismiss="alert" aria-label="close"
                    (click)="columnSettingErrorMsg=''">
              ×
            </button>
            <i class="material-icons">warning</i>
            <p [innerHtml]="columnSettingErrorMsg"></p>
          </div>
          <!-- Column Field starts -->
          <div class="row column-field-wrapper"
               *ngFor="let columnPref of columnPreferences; index as counter;">
            <!-- Refer https://dev.azure.com/Fiserv-FTS/Impl360/_workitems/edit/226535/ -->
            <div class="col-lg-11 form-field-container">
              <div class="filter-form-control">
                <ng-select [(ngModel)]="columnPref.id" class="ng-select">
                  <ng-option *ngFor="let column of prefColumnOptions"
                             [value]="column.id">{{column.title}}</ng-option>
                </ng-select>
              </div>
            </div>
            <div class="col-lg-1 cross-icon-container">
              <button type="button" data-dismiss="modal" tabindex="0" autofocus=""
                      aria-label="Close popup" class="close" (click)="removeColumn(counter)">
                <span aria-hidden="true">×</span>
              </button>
            </div>
          </div>
          <div class="row column-field-wrapper" *ngIf="showAddColumnOptions">
            <div class="col-lg-12 ">
              <a class="add-col-link" (click)="addColumn()">
                <span class="material-icons">add</span>
                <span>Add a Column</span>
              </a>
            </div>
          </div>
        </div>
        <div class="modal-footer fdl-2">
          <button class="btn fdl-btn btn-secondary-light" (click)="cancelPreference()">Cancel</button>
          <button class="btn btn-primary-orange m-0" (click)="savePreference()">Save</button>
        </div>
      </div>
    </div>
  </div>
  <!-- end of column settings changes-->
</div>

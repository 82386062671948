<div data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="Delete Request"
    class="modal fade bd-example-modal-md" style="display: none;" id="deleteModal_one" aria-hidden="true">
    <div role="document" class="modal-dialog modal-md mt-120" style="max-width: 700px;">
        <div class="modal-content">
            <div class="modal-header">
                <h4 id="myLargeModalLabel" class="modal-title">Delete Request</h4>
                <button type="button" data-dismiss="modal" aria-label="Close popup" class="close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <p>Are you sure you want to delete request ?</p>
            </div>
            <div class="modal-footer fdl-2">
                <button type="button" data-dismiss="modal" class="btn fdl-btn btn-secondary-light"
                    [ngClass]="{'blueThemeMode':(blueThemeMode)}">Cancel</button>
                <button type="button" data-dismiss="modal" class="btn fdl-btn btn-primary-orange ml-2"
                    [ngClass]="{'buttonColor':(blueThemeMode)}" (click)="onDeleteClicked()">Ok</button>
            </div>
        </div>
    </div>
</div>
<!-- <img src="https://fdl.apps.fog.onefiserv.net/assets/img/up_arrow.png" *ngIf="isShowScrollToTop" (click)="gotoTop()" id="ScrollTop">
<img src="https://fdl.apps.fog.onefiserv.net/assets/img/up_arrow.png" *ngIf="isShowScrollToTop && isCommitmentDashboardPage" (click)="gotoTop()" class="CCTScrollTop" > -->
<img *ngIf="scrollElement !== null" alt="up arrow" title="Scroll To Top" src="assets/images/up_arrow.png"
  (click)="scrollTop()" [attr.style]="bodyStyle()" id="scrollTop" [ngClass]="{'commitmentdashboard' : (scrollElement.tagName === 'BODY'),
              'hideArrow' : ((scrollElement.tagName !== 'BODY' && scrollElement.scrollTop <= 10) || 
              (scrollElement.tagName === 'BODY' && myDocument.documentElement.scrollTop <= 10)),
              'oneArrow' : ((scrollElement.tagName !== 'BODY' && scrollElement.scrollTop >= (scrollElement.scrollHeight - scrollElement.offsetHeight - 20))||
              (scrollElement.tagName === 'BODY' &&  myWindow.innerHeight + myWindow.scrollY >= myDocument.body.offsetHeight)),
              'position' : (scrollElement.tagName === 'BODY' && myDocument.documentElement.scrollTop > 10)}">
<img title="Scroll To Bottom" *ngIf="scrollElement !== null" alt="down arrow" src="assets/images/down_arrow.png"
  (click)="scrollBottom()" id="scrollBottom"
  [ngClass]="{'commitmentdashboard' : (scrollElement.tagName === 'BODY'),
              'hideArrow' : ((scrollElement.tagName !== 'BODY' && scrollElement.scrollTop >= (scrollElement.scrollHeight - scrollElement.offsetHeight - 20))||
              (scrollElement.tagName === 'BODY' && myWindow.innerHeight + myWindow.scrollY >= myDocument.body.offsetHeight)),
              'oneArrowTop' : ((scrollElement.tagName !== 'BODY' && scrollElement.scrollTop <= 10) || 
              (scrollElement.tagName === 'BODY' && myDocument.documentElement.scrollTop <= 10)),
              'position' : (scrollElement.tagName === 'BODY' &&  myWindow.innerHeight + myWindow.scrollY < myDocument.body.offsetHeight)}">
<!-- Quick Links Wrapper Starts-->

<!-- <div class="quick-links-wrapper" *ngIf="clientCommitmentForm">
  <div class="icon" id="quickLinksAction" (click)="quickLinks($event)"><span id="quickLinksIcon" class="material-icon collapsed"></span>
  </div>
  <div class="container">
    <div class="title">
      <h3>Quick Links</h3>
    </div>
    <div class="body d-none" id="quickLinkID">
      <ul class="list">
        <li *ngIf="dashboardId > 0">
          <a (click)="navigateToDashboard()" href="javascript:void(0);">Commitment Dashboard </a>
        </li>
        <li *ngIf="clientCommitmentId > 0">
          <a (click)="navigateToPageResponse(clientCommitmentId)" href="javascript:void(0);">Commitment Intake</a>
        </li>
        <li *ngIf="eCRMFeedId > 0">
          <a (click)="navigateToPageResponse(eCRMFeedId)" href="javascript:void(0);">eCRM Feed</a>
        </li>
        <li *ngIf="clarityFeedId > 0">
          <a (click)="navigateToPageResponse(clarityFeedId)" href="javascript:void(0);">Clarity Feed</a>
        </li>
      </ul>
    </div>
  </div>
</div> -->

<!-- Quick Links Wrapper Ends-->
<footer class="footer" *ngIf="!isCommitmentDashboardPage">
  <div class="row align-items-center justify-content-space-between">
    <div class="col-md-6  ">©{{currentYear}} Fiserv, Inc. or its affiliates. | FISERV CONFIDENTIAL.</div>
    <div class="col-md-6 text-right"><img alt="Fiserv" src="assets/images/logo-fiserv-white.png"></div>
    <!-- <div class="col-md-6 text-right"><a href="#">Privacy </a> | <a href="#"> Terms and Conditions</a></div> -->
  </div>
</footer>

<div id="commitmentdisclaimer" class="disclaimer-wrapper" *ngIf="isCommitmentDashboardPage">
  <div class="disclamer-text">
    <div>
      <div class="footer-label" [ngClass]="{'blueThemeMode':(blueThemeMode)}">Disclaimer:</div>
      By accessing or viewing the information on or from this application, Client agrees that the information is
      Fiserv’s
      confidential information, it is being provided solely to facilitate discussions between Fiserv and Client,
      and it does not and will not alter any written agreement or create any new agreement between the parties.
    </div>
  </div>
  <!-- <button type="button" id="disclaimerBtn" class="btn " title="Dismiss Disclaimer"  (click)="dismissDisclaimer()">
    <i class="material-icons">close</i>
  </button> -->
</div>
<footer class="footer" *ngIf="isCommitmentDashboardPage">
  <div class="row align-items-center justify-content-space-between">
    <div class="col-md-6 ">©{{currentYear}} Fiserv, Inc. or its affiliates. | FISERV CONFIDENTIAL.</div>
    <div class="col-md-6 text-right"><img alt="Fiserv" src="assets/images/logo-fiserv-white.png"></div>
    <!-- <div class="col-md-6 text-right"><a href="#">Privacy </a> | <a href="#"> Terms and Conditions</a></div> -->
  </div>
</footer>

import { ElementRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { Component, EventEmitter, forwardRef, Input, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-type-ahead',
  templateUrl: './type-ahead.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TypeAheadComponent),
      multi: true
    }
  ]
})
export class TypeAheadComponent implements ControlValueAccessor {
  @Input() options: any;
  @Input() placeholder: string;
  @Input() optionField: string = "title";
  @Input() type: string = '';
  @Input() label: string;
  @Output() selectDone: any = new EventEmitter(); 
  @Output() clearText: any = new EventEmitter();
  @ViewChild('search') searchElement: ElementRef;
  titleArray: any = [];
  val = "";
  clearClick: boolean = false;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    // console.log(changes)
    if (changes.options && changes.options.currentValue && changes.options.currentValue.length > 0) {
      this.calCulateOptions();
    } else {
      this.titleArray = [];
    }
  }

  onChange: any = () => { };
  onTouch: any = () => { };

  set value(val) {
    if (val !== null && val !== undefined && this.val !== val) {
      this.val = val
      this.onChange(val)
      this.onTouch(val)
    }
    else if (this.clearClick) {
      this.clearClick = true;
      this.val = val
      this.onChange(val)
      this.onTouch(val)
    }
  }

  onSelect() {    
    if (this.selectDone) {
      this.selectDone.emit();
    }
  }

  clearVal() {
    this.clearClick = true;
    this.val = '';
    this.value = '';
    this.writeValue('');
    if (this.clearText) {
      this.clearText.emit();
      if (this.type == '') {
        this.titleArray = [];      
      }      
    }   
    this.searchElement.nativeElement.focus();//.click();        
  }

  calCulateOptions() {
    this.titleArray = [];
    for (let k in this.options) {
      this.titleArray.push(this.options[k][this.optionField]);
    }
  }

  writeValue(value: any) {
    this.value = value
  }

  registerOnChange(fn: any) {
    this.onChange = fn
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn
  }

  keydown(event: KeyboardEvent) {
    //if ((event.key === 'Delete' || event.key === 'Backspace')) {
    //  this.titleArray = [];
    //}
  }
}
